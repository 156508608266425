<template>
  <button
    class="group text-sm flex-shrink-0 rounded flex gap-1 p-2 items-center transition-colors duration-150 font-medium"
    :class="{
      'text-gray-700': !active,
      'text-primary': active || (checkActualRoute && $router.currentRoute.path == to),
      'opacity-60 cursor-not-allowed': disabled,
      'hover:text-primary': !disabled,
      'w-full': fullWidth,
      'bg-gray-300 bg-opacity-30 rounded-lg': selected,
      'border-b pb-3 mb-2': bordered,
    }"
    :disabled="disabled"
    @click="goToOrRun"
  >
    <span
      v-if="hasIcon"
      class="rounded-xl w-7 flex-shrink-0"
      :class="{
        'text-primary': active,
        'text-gray-500 opacity-60': !active,
        'group-hover:text-primary group-hover:opacity-90': !disabled,
      }"
    >
      <slot name="svg">
        <component :is="'fw-icon-' + icon" class="w-6 h-6" />
      </slot>
    </span>
    <span class="group-opacity-100 text-left flex-1 flex-shrink-0" :class="[`text-${size}`]">
      <v-clamp autosize :max-lines="1">{{ label }}</v-clamp>
    </span>
    <span
      v-if="counter"
      class="text-xs px-1.5 py-0.5 rounded-full flex-shrink-0 min-w-7 text-center font-bold"
      :class="{
        'bg-primary text-white': counterType === 'primary',
        'bg-gray-500 bg-opacity-20 text-gray-500': counterType === 'light',
        'bg-gray-500 bg-opacity-10 text-gray-500': counterType === 'xlight',
      }"
    >
      {{ counter }}
    </span>
    <span v-if="$slots.suffix" class="flex-shrink-0"><slot name="suffix"></slot></span>
  </button>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'sm',
    },
    icon: {
      type: String,
      default: null,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    checkActualRoute: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    counter: {
      //String or number
      type: [String, Number],
      default: null,
    },
    counterType: {
      type: String,
      default: 'primary',
    },
  },

  computed: {
    hasIcon() {
      return this.icon || this.$slots.svg
    },
  },

  methods: {
    goToOrRun() {
      if (this.to) this.$router.push(this.to)
    },
  },
}
</script>
