<template>
  <div class="relative rounded-xl bg-white">
    <div v-if="!showTransferMessageForm">
      <div class="flex mx-5 mt-5 mb-2">
        <div class="flex-1">
          <div class="text-lg font-bold">{{ title }}</div>
          <div class="text-sm">{{ instructions }}</div>
        </div>
        <b-dropdown
          v-if="selectionTypes.length > 0"
          scrollable
          max-height="500"
          aria-role="list"
          position="is-bottom-left"
        >
          <fw-button slot="trigger" type="dropdown" size="sm" class="flex gap-1 items-center">
            {{ selectionTypes[currentSelectionTypeIndex].text }}
            <fw-icon-chevron-down class="w-4 h-4" />
          </fw-button>
          <b-dropdown-item
            :key="'criterium_label'"
            aria-role="menu-item"
            :focusable="false"
            class="font-semibold"
            paddingless
            custom
          >
            <fw-label class="ml-4">{{ $t('selectBy') }}:</fw-label>
          </b-dropdown-item>
          <b-dropdown-item
            v-for="(seltype, a) in selectionTypes"
            :key="'seltype_selector_' + a"
            aria-role="menu-item"
            :focusable="true"
            class="px-4 py-2 font-semibold flex justify-start gap-3 items-center"
            @click.native="changeSelectionType(a)"
          >
            <div
              class="border-2 border-gray-200 h-4 w-4 rounded-full -mt-0.5"
              :class="{
                'bg-primary bg-opacity-90': currentSelectionTypeIndex === a,
                'bg-gray-200': currentSelectionTypeIndex !== a,
              }"
            ></div>
            <div>{{ selectionTypes[a].text }}</div>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <slot name="previous"></slot>

      <PeopleList
        :endpoint="endpoint"
        :show-person-attributes="showPersonAttributes"
        :searchable="searchable"
        :users-list="usersList"
        :no-padding="true"
        :selectable="multiselect"
        :clickable="!multiselect"
        :not-alowed-users="notAlowedUsers"
        :id-key="idKey"
        :invite-people="invitePeople"
        :query-filters="queryFilters"
        @selected="selectedEvent"
        @clicked="clicked"
        @save-invite="saveInviteEvent"
      >
        <template #after>
          <slot name="after-list"></slot>
        </template>
      </PeopleList>
    </div>

    <slot v-else name="transfer"></slot>

    <div v-if="multiselect || showConfirmation" class="flex items-center justify-end px-5 pb-4 pt-1 gap-5">
      <fw-button type="link-muted" @click.native="closeModal">
        {{ cancelButtonText }}
      </fw-button>
      <fw-button
        v-if="allowTransferMessage && !showTransferMessageForm"
        type="black"
        @click.native="askForTransferMessage"
      >
        {{ $t('button.next') }}
      </fw-button>
      <fw-button
        v-else
        :type="!confirmDisabled ? 'primary' : 'disabled'"
        :disabled="confirmDisabled"
        @click.native="saveSelection"
      >
        {{ confirmButtonText }}
      </fw-button>
    </div>
  </div>
</template>

<script>
import PeopleList from '../lists/PeopleList'

export default {
  name: 'ChoosePeopleModal',
  components: { PeopleList },
  props: {
    idKey: {
      type: String,
      default: 'key',
    },
    showModal: {
      default: false,
      type: Boolean,
    },
    multiselect: {
      default: false,
      type: Boolean,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    invitePeople: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
      default: function() {
        return this.$t('chooseUser')
      },
    },
    instructions: {
      type: String,
      default: function() {
        return this.$t('selectUser')
      },
    },
    notAlowedUsers: {
      //not alowed user keys
      type: Array,
      default: function() {
        return []
      },
    },
    queryFilters: {
      type: Array,
      default: function() {
        return []
      },
    },
    usersList: {
      // defined list of users to show
      type: Array,
      default: function() {
        return []
      },
    },
    showConfirmation: {
      type: Boolean,
      default: false,
    },
    cancelButtonText: {
      type: String,
      default: function() {
        return this.$t('button.cancel')
      },
    },
    confirmButtonText: {
      type: String,
      default: function() {
        return this.$t('button.saveSelection')
      },
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    allowTransferMessage: {
      type: Boolean,
      default: false,
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    selectionTypes: {
      type: Array,
      default: function() {
        return []
      },
    },
    currentSelectionTypeIndex: {
      type: Number,
      default: 0,
    },
    showPersonAttributes: {
      type: Array,
      default: function() {
        return []
      },
    },
    endpoint: {
      type: Function,
    },
  },
  data() {
    return {
      selectedList: [],
      showTransferMessageForm: false,
    }
  },
  methods: {
    changeSelectionType(a) {
      this.$emit('changeSelectionType', a)
    },
    selectedEvent(selectedList) {
      console.log('Clicked selectedList', selectedList)
      if (Array.isArray(selectedList)) {
        this.selectedList = selectedList
        this.$emit('selected-updated', selectedList)
      } else {
        this.$emit('selected', [selectedList])
        this.$emit('close')
      }
    },
    clicked(selected) {
      console.log('Clicked selected', selected)
      this.$emit('selected', selected)
      if (this.autoClose) {
        this.$emit('close')
      }
    },
    closeModal() {
      this.$emit('close')
    },
    saveSelection() {
      if (this.$listeners && this.$listeners.confirm) {
        this.$emit('confirm')
      } else {
        this.$emit('selected', this.selectedList)
        this.$emit('confirm')
        this.$emit('close')
      }
    },
    askForTransferMessage() {
      this.showTransferMessageForm = true
    },
    saveInviteEvent(user, roles, type) {
      console.log('Choose people modal save-invite', user, roles, type)
      this.$emit('save-invite', user, roles, type)
    },
  },
}
</script>

<i18n>
{
  "en": {
    "selectBy": "Select people by",
    "chooseUser": "Choose a user",
    "selectUser": "Select the user you want",
    "button": {
      "next": "Next",
      "cancel": "Cancel",
      "saveSelection": "Save Selection"
    }
  },
  "pt": {
    "selectBy": "Selecionar pessoas por",
    "chooseUser": "Escolha um utilizador",
    "selectUser": "Seleccione o utilizador que pretende",
    "button": {
      "next": "Próximo",
      "cancel": "Cancelar",
      "saveSelection": "Guardar seleção"
    }
  }
}
</i18n>
