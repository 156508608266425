var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SidebarBase',{scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('div',{ref:"sidebarspace",staticClass:"h-full flex flex-col"},[_c('ButtonSidebar',{attrs:{"label":"Inbox","icon":"inbox","active":_vm.type == 'inbox' || _vm.type == 'unread',"counter":_vm.stats ? _vm.stats.unread : '0'},nativeOn:{"click":function($event){return _vm.$emit('change-type', 'inbox')}}}),_c('div',{ref:"scrollinbox",staticClass:"overflow-y-auto transition-all no-scrollbar",class:{
          'mb-3': _vm.type == 'inbox' || _vm.type == 'unread',
          'mb-0': _vm.type != 'inbox' && _vm.type != 'unread',
        },style:({ height: `${_vm.inboxSubtreeHeight}px` })},_vm._l((_vm.contextOptions),function(context,c){return _c('div',{key:c,staticClass:"pl-4"},[(context.stats.total > 0)?_c('ButtonSidebar',{attrs:{"label":_vm.appLabels[context.name] ? _vm.appLabels[context.name] : context.name,"active":context.name == _vm.app,"size":"sm","counter":context.stats.unread,"counter-type":"xlight"},nativeOn:{"click":function($event){return _vm.$emit('change-app', context.name)}}}):_vm._e()],1)}),0),_c('ButtonSidebar',{attrs:{"label":"Arquivadas","icon":"archive","active":_vm.type == 'archived',"counter":_vm.stats ? _vm.stats.archived : '0',"counter-type":"xlight"},nativeOn:{"click":function($event){return _vm.$emit('change-type', 'archived')}}}),_c('div',{ref:"scrollarchived",staticClass:"overflow-y-auto transition-all no-scrollbar",class:{
          'mb-3': _vm.type == 'archived',
          'mb-0': _vm.type != 'archived',
        },style:({ height: `${_vm.archivedSubtreeHeight}px` })},_vm._l((_vm.contextOptions),function(context,c){return _c('div',{key:c,staticClass:"pl-8"},[(context.stats.archived > 0)?_c('ButtonSidebar',{attrs:{"label":_vm.appLabels[context.name] ? _vm.appLabels[context.name] : context.name,"active":context.name == _vm.app,"size":"sm","counter":context.stats.archived,"counter-type":"xlight"},nativeOn:{"click":function($event){return _vm.$emit('change-app', context.name)}}}):_vm._e()],1)}),0),(false)?_c('div',{staticClass:"ml-1 my-3"},[_c('fw-label',{staticClass:"ml-2",attrs:{"size":"xs"}},[_vm._v("Por tipo")]),_c('div',[_c('ButtonSidebar',{attrs:{"label":"Pessoal","size":"sm","counter":"99","counter-type":"light"}}),_c('ButtonSidebar',{attrs:{"label":"Gestão","size":"sm","counter":"99","counter-type":"light"}})],1)],1):_vm._e()],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }