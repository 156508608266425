import Api from '@/fw-modules/fw-core-vue/api/Api'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlTasks
    return api
  },

  /**
   * User
   */
  async getSupportTypes(params) {
    const response = await this.base().get(`/v1/types`, { params })
    return response.data
  },

  async getSupportType(typeKey, params) {
    const response = await this.base().get(`/v1/types/${typeKey}`, { params })
    return response.data
  },

  async getUserSupportTasks(params) {
    const response = await this.base().get(`/v1/tasks`, { params })
    return response.data
  },

  async createSupportTask(payload) {
    const response = await this.base().post(`/v1/tasks`, payload)
    return response.data
  },

  // Can be used without auth, instead of taskId use task actionToken
  async getUserSupportTask(taskId, params) {
    const response = await this.base().get(`/v1/tasks/${taskId}`, { params })
    return response.data
  },

  async getUserSupportTaskMessages(taskId, params) {
    const response = await this.base().get(`/v1/tasks/${taskId}/messages`, { params })
    return response.data
  },

  async addUserSupportTaskMessages(taskId, payload) {
    const response = await this.base().post(`/v1/tasks/${taskId}/messages`, payload)
    return response.data
  },

  // Can be used without auth, instead of taskId use task actionToken
  async addUserSupportTaskRating(taskId, payload) {
    const response = await this.base().post(`/v1/tasks/${taskId}/ratings`, payload)
    return response.data
  },

  // Can be used without auth, instead of taskId use task actionToken
  async updateUserSupportTaskRating(taskId, ratingKey, payload) {
    const response = await this.base().post(`/v1/tasks/${taskId}/ratings/${ratingKey}`, payload)
    return response.data
  },

  async generateUserSupportTaskToken(taskId, payload) {
    const response = await this.base().post(`/v1/tasks/${taskId}/token`, payload)
    return response.data
  },
  /**
   * General
   */

  async getActivityLogs(params) {
    if (params.spaceKey) return await this.getSpaceActivity(params.spaceKey, params)
    return await this.getQueueActivity(params.spaceKey, params.queueKey, params)
  },

  async getNotifications(params) {
    if (params.spaceKey) return await this.getSpaceNotifications(params.spaceKey, params)
    return await this.getQueueNotifications(params.spaceKey, params.queueKey, params)
  },

  async getNotification(key, params) {
    if (params.spaceKey) return await this.getSpaceNotification(params.spaceKey, key, params)
    return await this.getQueueNotification(params.spaceKey, params.queueKey, key, params)
  },

  /**
   * Spaces
   */
  async getSpaces(params) {
    const response = await this.base().get(`/v1/spaces`, { params })
    return response.data
  },

  async createSpace(payload) {
    const response = await this.base().post(`/v1/spaces`, payload)
    return response.data
  },

  async getSpace(spaceKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}`, { params })
    return response.data
  },

  async deleteSpace(spaceKey) {
    const response = await this.base().delete(`/v1/space/${spaceKey}`)
    return response.data
  },

  async updateSpace(spaceKey, payload) {
    const response = await this.base().post(`/v1/space/${spaceKey}`, payload)
    return response.data
  },

  async getSpaceActivity(spaceKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/activity`, { params })
    return response.data
  },

  async getSpaceNotifications(spaceKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/notifications`, { params })
    return response.data
  },

  async getSpaceNotification(spaceKey, notificationkey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/notifications/${notificationkey}`, { params })
    return response.data
  },

  async getSpaceTypes(spaceKey, params) {
    const response = await this.base().get(`/v1/spaces/${spaceKey}/types`, { params })
    return response.data
  },

  async createSpaceTypes(spaceKey, queueKey, payload) {
    const response = await this.base().post(`/v1/space/${spaceKey}/types`, {
      queue_key: queueKey,
      ...payload,
    })
    return response.data
  },

  async updateSpaceTypes(spaceKey, typeKey, payload) {
    const response = await this.base().post(`/v1/space/${spaceKey}/types/${typeKey}`, payload)
    return response.data
  },

  async deleteSpaceType(spaceKey, typeKey) {
    const response = await this.base().delete(`/v1/space/${spaceKey}/types/${typeKey}`)
    return response.data
  },

  async spaceUsersSearch(spaceKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/search`, { params })
    return response.data
  },

  async searchSpaces(spaceKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/search-spaces`, { params })
    return response.data
  },

  async getSpaceAllowedMoves(spaceKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/moves-allowed`, { params })
    return response.data
  },

  async addSpaceAllowedMoves(spaceKey, spaceKeys) {
    const response = await this.base().post(`/v1/space/${spaceKey}/moves-allowed`, spaceKeys)
    return response.data
  },

  async deleteSpaceAllowedMoves(spaceKey, items) {
    const response = await this.base().delete(`/v1/space/${spaceKey}/moves-allowed`, { data: items })
    return response.data
  },

  async getSpaceTasksTypes(spaceKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/task-types`, {
      params,
    })
    return response.data
  },

  async addSpaceTasksTypes(spaceKey, payload) {
    const response = await this.base().post(`/v1/space/${spaceKey}/task-types`, payload)
    return response.data
  },

  async updateSpaceTasksType(spaceKey, typeKey, payload) {
    const response = await this.base().put(`/v1/space/${spaceKey}/task-types/${typeKey}`, payload)
    return response.data
  },

  async deleteSpaceTasksType(spaceKey, items) {
    const response = await this.base().delete(`/v1/space/${spaceKey}/task-types`, { data: items })
    return response.data
  },

  async getSpaceQueueTasksTypes(spaceKey, queueKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/queue/${queueKey}/task-types`, {
      params,
    })
    return response.data
  },

  //subscribe WS
  //code: subscribe_queue, unsubscribe_queue, subscribe_task, unsubscribe_task
  async subscribeWS(code, connectionId, payload) {
    const response = await this.base().post(`/v1/ws`, {
      code: code,
      connectionId: connectionId,
      body: payload,
    })
    return response.data
  },

  /**
   * Queues
   */

  async getQueues(spaceKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/queues`, { params })
    return response.data
  },

  async createQueue(spaceKey, payload) {
    const response = await this.base().post(`/v1/space/${spaceKey}/queues`, payload)
    return response.data
  },

  async getQueue(spaceKey, queueKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/queue/${queueKey}`, { params })
    return response.data
  },

  async updateQueue(spaceKey, queueKey, payload) {
    const response = await this.base().post(`/v1/space/${spaceKey}/queue/${queueKey}`, payload)
    return response.data
  },

  async deleteQueue(spaceKey, queueKey) {
    const response = await this.base().delete(`/v1/space/${spaceKey}/queue/${queueKey}`)
    return response.data
  },

  async getQueueActivity(spaceKey, queueKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/queue/${queueKey}/activity`, { params })
    return response.data
  },

  async getQueueNotifications(spaceKey, queueKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/queue/${queueKey}/notifications`, { params })
    return response.data
  },

  async getQueueNotification(spaceKey, queueKey, notificationkey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/queue/${queueKey}/notifications/${notificationkey}`, {
      params,
    })
    return response.data
  },

  async queueUsersSearch(spaceKey, queueKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/queue/${queueKey}/search`, { params })
    return response.data
  },

  /**
   * Space data schemas
   */
  async getDataSchemas(spaceKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/schemas`, { params })
    return response.data
  },

  async createDataSchemas(spaceKey, payload) {
    const response = await this.base().post(`/v1/space/${spaceKey}/schemas`, payload)
    return response.data
  },

  async getDataSchema(spaceKey, schemaKey) {
    const response = await this.base().get(`/v1/space/${spaceKey}/schemas/${schemaKey}`)
    return response.data
  },

  async updateDataSchemas(spaceKey, schemaKey, payload) {
    const response = await this.base().post(`/v1/space/${spaceKey}/schemas/${schemaKey}`, payload)
    return response.data
  },

  async deleteDataSchemas(spaceKey, schemaKey) {
    const response = await this.base().delete(`/v1/space/${spaceKey}/schemas/${schemaKey}`)
    return response.data
  },

  /**
   * Users Roles
   */
  async getManagers(spaceKey) {
    const response = await this.base().get(`/v1/space/${spaceKey}/managers`)
    return response.data
  },

  async createManagers(spaceKey, usersAndRoles) {
    const response = await this.base().post(`/v1/space/${spaceKey}/managers`, usersAndRoles)
    return response.data
  },

  async deleteManagers(spaceKey, usersAndRoles) {
    const response = await this.base().delete(`/v1/space/${spaceKey}/managers`, { data: usersAndRoles })
    return response.data
  },

  /**
   * Tasks
   */
  async getUserTasks(params) {
    const response = await this.base().get(`/v1/tasks`, { params })
    return response.data
  },

  async getTasks(spaceKey, queueKey, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/queue/${queueKey}/tasks`, { params })
    return response.data
  },

  async createTask(spaceKey, queueKey, payload) {
    const response = await this.base().post(`/v1/space/${spaceKey}/queue/${queueKey}/tasks`, payload)
    return response.data
  },

  async getTask(spaceKey, queueKey, taskId, params) {
    const response = await this.base().get(`/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}`, {
      params,
    })
    return response.data
  },

  async updateTask(spaceKey, queueKey, taskId, payload) {
    const response = await this.base().post(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}`,
      payload
    )
    return response.data
  },

  async getTaskActivity(spaceKey, queueKey, taskId, params) {
    const response = await this.base().get(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/activity`,
      {
        params,
      }
    )
    return response.data
  },

  async changeTaskStatus(spaceKey, queueKey, taskId, status) {
    const response = await this.base().post(`/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}`, {
      status,
    })
    return response.data
  },

  async moveTaskToQueue(spaceKey, queueKey, taskId, newQueueKey) {
    const response = await this.base().post(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/move`,
      {
        queue_key: newQueueKey,
      }
    )
    return response.data
  },

  async moveTaskToSpace(spaceKey, queueKey, taskId, newSpaceKey, message = null) {
    const response = await this.base().post(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/move`,
      {
        space_key: newSpaceKey,
        message,
      }
    )
    return response.data
  },

  async taskUsersSearch(spaceKey, queueKey, taskId, params) {
    const response = await this.base().get(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/search`,
      { params }
    )
    return response.data
  },

  /**
   * Tasks messages
   */
  async getTaskMessages(spaceKey, queueKey, taskId, params) {
    const response = await this.base().get(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/messages`,
      {
        params: params,
      }
    )
    return response.data
  },

  async addTaskMessages(spaceKey, queueKey, taskId, payload) {
    const response = await this.base().post(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/messages`,
      payload
    )
    return response.data
  },

  async updateTaskMessages(spaceKey, queueKey, taskId, messageKey, payload) {
    const response = await this.base().post(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/message/${messageKey}`,
      payload
    )
    return response.data
  },

  async deleteTaskMessages(spaceKey, queueKey, taskId, messageKey, payload) {
    const response = await this.base().delete(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/message/${messageKey}`,
      payload
    )
    return response.data
  },

  /**
   * Tasks managers
   */
  async getTaskManagers(spaceKey, queueKey, taskId, params) {
    const response = await this.base().get(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/managers`,
      {
        params,
      }
    )
    return response.data
  },

  async createTaskManagers(spaceKey, queueKey, taskId, userKeys) {
    const response = await this.base().post(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/managers`,
      Object.fromEntries(userKeys.map(userKey => [userKey, ['manager']]))
    )
    return response.data
  },

  async deleteTaskManagers(spaceKey, queueKey, taskId, userKeys) {
    const response = await this.base().delete(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/managers`,
      {
        data: {
          user_keys: userKeys,
        },
      }
    )
    return response.data
  },

  async createTaskParticipants(spaceKey, queueKey, taskId, userKeys) {
    const response = await this.base().post(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/managers`,
      Object.fromEntries(userKeys.map(userKey => [userKey, ['participant']]))
    )
    return response.data
  },

  async createTaskWatchers(spaceKey, queueKey, taskId, userKeys) {
    const response = await this.base().post(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/managers`,
      Object.fromEntries(userKeys.map(userKey => [userKey, ['watcher']]))
    )
    return response.data
  },

  async createTaskGuestManagers(spaceKey, queueKey, taskId, userKeys, permissions) {
    const response = await this.base().post(
      `/v1/space/${spaceKey}/queue/${queueKey}/task/${taskId.toLowerCase()}/managers`,

      Object.fromEntries(userKeys.map(userKey => [userKey, ['guest_manager', permissions]]))
    )
    return response.data
  },
}
