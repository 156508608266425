<template>
  <div class="inner is-flex-grow">
    <div
      class="inner-panel is-flex-grow"
      :class="{ 'has-background-light': isLiveSession }"
      :style="isStudent ? 'width: 40%' : ''"
    >
      <div class="has-margin-medium">
        <div class="has-margin-bottom is-flex">
          <div class="is-flex-grow has-margin-right">
            <div class="label is-marginless">Exame</div>
            <h1 class="is-size-1">{{ exam.titles.pt || 'Exame sem título' }}</h1>
          </div>
          <div v-if="isLiveSession">
            <div class="label is-marginless">ID do exame</div>
            <div class="is-size-1 is-lowercase is-family-code">
              {{ exam.key }}
            </div>
          </div>
        </div>
        <div v-if="canEdit" class="panel has-margin-top-large has-margin-bottom-medium">
          <form @submit.prevent="doSaveExam">
            <div class="label">Nome da unidade curricular / exame</div>
            <b-tabs :animated="false" class="is-small is-marginless is-paddingless">
              <b-tab-item label="Português" class="has-margin-top">
                <b-input v-model="exam.titles.pt" size="is-medium"></b-input>
              </b-tab-item>
              <b-tab-item label="English" class="has-margin-top">
                <b-input v-model="exam.titles.en" size="is-medium"></b-input>
              </b-tab-item>
            </b-tabs>
            <b-field class="has-margin-top">
              <b-button type="is-light" @click="doSaveExam">Guardar</b-button>
            </b-field>
          </form>
        </div>
        <div v-if="exam.is_manager || isRunning" class="panel">
          <div class="panel-content has-margin-top">
            <div class="label">
              Ficheiros do exame
              <span v-if="exam.files && exam.files.length" class="is-meta">{{ exam.files.length }}</span>
            </div>

            <div v-if="exam.files && exam.files.length" class="files has-margin-top">
              <RecordFile
                v-for="file in exam.files"
                :key="file.id"
                :can-edit="canEdit"
                :file="file"
                :rooms="exam.rooms"
                @update="updateFile"
                @delete="deleteFile"
              />
            </div>

            <div v-if="exam.files && !exam.files.length && !canEdit" class="is-meta has-margin-top">
              Não foram associados ficheiros.
            </div>

            <div v-if="canEdit" class="files-uploader has-margin-top-medium has-margin-bottom">
              <Uploader
                :files.sync="filesToUpload"
                :is-docked="true"
                layout="is-minimal"
                reference-id="upload"
                allowed="any"
                :size="0"
                :clear-after="true"
                bucket-code="files"
                bucket-type="file"
                input-id="upload"
                @upload="addFiles"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="isStudent && isClosed && !isRunning"
        class="is-height-full is-flex is-flex-align-center is-flex-justify-center"
      >
        <div class="has-text-centered has-text-muted">
          <div class="has-margin-bottom"><faicon icon="clock" size="2x"></faicon></div>
          <div class="is-size-3">O exame ainda não foi iniciado.</div>
          <div class="has-margin-top">Por favor, aguarde.</div>
        </div>
      </div>
      <div v-if="isStudent && isEnded" class="is-height-full is-flex is-flex-align-center is-flex-justify-center">
        <div class="has-text-centered has-text-muted">
          <div class="is-size-3">O exame está fechado.</div>
        </div>
      </div>
    </div>
    <div class="inner-panel sidebar" :style="isStudent ? 'width: 60%' : ''">
      <div v-if="isManager" class="has-margin-medium">
        <div class="has-margin-bottom">
          <div class="label is-marginless">Estado</div>
          <div>
            <div class="is-size-1 has-text-weight-bold has-margin-bottom">
              {{ statusTitle }}
            </div>
          </div>
        </div>
        <div v-if="$router.currentRoute.name == 'exam' && (canStart || canStop)" class="has-margin-bottom-medium">
          <div>
            Por favor, aceda ao hall do exame ou diretamente a uma das salas virtuais para iniciar e disponibilizar o
            exame.
          </div>
          <div class="has-text-small has-margin-top">
            Aos estudantes, divulgue apenas ID do exame (<span
              class="is-lowercase is-family-code has-background-light has-text-weight-bold"
              >{{ exam.key }}</span
            >), para que no hall de entrada a plataforma possa realizar a alocação às salas virtuais (ver definições das
            salas).
          </div>
        </div>
        <div v-if="canClose || canReOpen || canDelete || canStart || canStop" class="has-margin-top-medium">
          <b-field>
            <b-button v-if="canClose" type="is-dark" expanded @click="updateExamStatus('close')">
              Terminar edição
            </b-button>
            <b-button
              v-if="canStart && isLiveSession"
              size="is-large"
              expanded
              type="is-primary"
              @click="updateExamStatus('start')"
            >
              Iniciar exame
            </b-button>
            <b-button
              v-if="(canStart || canStop || isRunning) && !isLiveSession"
              expanded
              size="is-large"
              type="is-primary"
              @click="$router.push({ name: 'exams-v1-join', params: { key: exam.key } })"
            >
              Aceder ao hall
            </b-button>
            <b-button
              v-if="canStop && isLiveSession"
              size="is-large"
              expanded
              type="is-danger"
              @click="updateExamStatus('stop')"
            >
              Terminar exame
            </b-button>
          </b-field>
          <b-field v-if="canReOpen || canDelete">
            <b-button
              v-if="canReOpen"
              class="has-margin-right-small"
              type="is-dark"
              size="is-small"
              expanded
              outlined
              @click="updateExamStatus('reopen')"
              >Editar</b-button
            >
            <b-button v-if="canDelete" expanded size="is-small" type="is-danger" outlined @click="doDelete"
              >Eliminar</b-button
            >
          </b-field>
        </div>
      </div>
      <div v-else-if="isStudent" class="has-margin-medium">
        <div class="panel">
          <div class="panel-content">
            <h2>
              Respostas
              <span v-if="exam.answers && exam.answers.length" class="is-meta">{{ exam.answers.length }}</span>
            </h2>
            <div v-if="isRunning && canEditAnswer" class="label has-margin-top-small">
              Faça o upload do(s) ficheiro(s) contendo as respostas.
            </div>

            <div v-if="exam.answers && exam.answers.length" class="files has-margin-top">
              <RecordFile
                v-for="file in exam.answers"
                :key="file.id"
                :can-edit="canEditAnswer"
                :file="file"
                @update="updateAnswer"
                @delete="deleteAnswer"
              />
            </div>

            <div v-if="exam.answers && !exam.answers.length && !canEditAnswer" class="is-meta has-margin-top">
              Não foram associados ficheiros.
            </div>

            <div v-if="canEditAnswer" class="files-uploader has-margin-top-medium has-margin-bottom">
              <Uploader
                is-custom
                :files.sync="answersToUpload"
                :is-docked="false"
                layout="for-file"
                reference-id="upload"
                allowed="any"
                :size="0"
                :clear-after="true"
                bucket-code="answers"
                bucket-type="file"
                input-id="upload"
                @upload="addAnswers"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceExams from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import RecordFile from '@/fw-modules/fw-core-vue/exams/components/blocks/RecordFile'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader.vue'

export default {
  components: {
    RecordFile,
    Uploader,
  },

  props: {
    exam: {
      type: Object,
      deep: true,
    },
  },

  data() {
    return {
      loading: false,
      filesToUpload: [],
      answersToUpload: [],
    }
  },

  computed: {
    isManager() {
      return this.exam.is_manager
    },
    isLiveSession() {
      return this.$router.currentRoute.name == 'live'
    },
    canEdit() {
      return this.exam.permissions && this.exam.permissions.edit
    },
    canClose() {
      return this.exam.permissions && this.exam.permissions.close
    },
    canReOpen() {
      return this.exam.permissions && this.exam.permissions.reopen
    },
    canStart() {
      return this.exam.permissions && this.exam.permissions.start
    },
    canStop() {
      return this.exam.permissions && this.exam.permissions.stop
    },
    canDelete() {
      return this.exam.permissions && this.exam.permissions.delete && this.$router.currentRoute.name == 'exam'
    },
    canEditAnswer() {
      return this.exam.status == 'running'
    },
    isClosed() {
      return this.exam.status == 'closed'
    },
    isEnded() {
      return this.exam.status == 'ended'
    },
    isReadyToStart() {
      return this.exam.status == 'published'
    },
    isRunning() {
      return this.exam.status == 'running'
    },
    isStudent() {
      return !this.exam.is_manager
    },
    statusTitle() {
      return ServiceExams.getStatusTitle(this.exam.status)
    },
  },

  methods: {
    async addFiles(files) {
      const keys = []
      for (const file of files) {
        if (file.response.data) keys.push(file.response.data.file.key)
      }
      if (keys.length) {
        const data = await ServiceExams.addFilesToExam(this.exam.key, keys)
        this.exam.files = data.files
      }
    },
    async updateFile(key, data) {
      this.exam.files = await ServiceExams.updateExamFile(this.exam.key, key, data)
    },
    async deleteFile(key) {
      this.exam.files = await ServiceExams.deleteFileFromExam(this.exam.key, key)
    },

    async addAnswers(files) {
      const keys = []
      for (const file of files) {
        if (file.response.data) keys.push(file.response.data.file.key)
      }
      if (keys.length) {
        const data = await ServiceExams.addAnswersToExam(this.exam.key, keys)
        this.exam.answers = data.answers
      }
    },
    async updateAnswer(key, title) {
      this.exam.answers = await ServiceExams.updateExamAnswer(this.exam.key, key, title)
    },
    async deleteAnswer(key) {
      this.exam.answers = await ServiceExams.deleteAnswerFromExam(this.exam.key, key)
    },

    async doSaveExam() {
      this.loading = true
      const data = {
        titles: this.exam.titles,
      }

      if (!this.exam.titles.pt) this.exam.titles.pt = 'Exame sem título'

      try {
        const exam = await ServiceExams.updateExam(this.exam.key, data)
        Object.assign(this.exam, exam)
      } finally {
        this.loading = false
      }
    },
    async updateExamStatus(status) {
      const exam = await ServiceExams.updateExamStatus(this.exam.key, status)
      Object.assign(this.exam, exam)
    },
    doDelete() {
      this.$buefy.dialog.confirm({
        confirmText: 'Eliminar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        title: 'Eliminar exame',
        message: `<div class="has-margin-bottom-small">Tem a certeza que deseja eliminar este exame?</div>
        <div class="has-text-small has-text-weight-bold">Todos os dados serão eliminados,
        incluíndo as respostas dos estudantes (caso existam).</div>`,
        onConfirm: () => {
          try {
            if (this.exam.status === 'ended') {
              this.$buefy.dialog.prompt({
                confirmText: 'Confirmar',
                cancelText: 'Cancelar',
                message: `Para confirmar que deseja eliminar um exame fechado, por favor,
                escreva "confirmo" na caixa seguinte`,
                closeOnConfirm: false,
                inputAttrs: {
                  placeholder: 'Escreva "confirmo"',
                  maxlength: 8,
                },
                trapFocus: true,
                onConfirm: (value, { close }) => {
                  if (value.toLowerCase() == 'confirmo') {
                    this.doReallyDeleteExam()
                    close()
                  }
                },
              })
            } else {
              this.doReallyDeleteExam()
            }
          } catch (error) {
            const examKey = this.exam ? this.exam.key : null
            console.error(`Removing exam ${examKey}`, error)
            this.$buefy.snackbar.open({
              message: 'Ocorreu um erro a eliminar o exame.',
              type: 'is-warning',
              position: 'is-top-right',
              indefinite: true,
              duration: 2000,
              queue: false,
            })
          }
        },
      })
    },
    async doReallyDeleteExam() {
      this.loading = true
      try {
        await ServiceExams.deleteExam(this.exam.key)
        this.$buefy.snackbar.open({
          message: 'O exame foi eliminado.',
          position: 'is-top-right',
          indefinite: false,
          duration: 4000,
          queue: false,
        })
        this.$router.push({ name: 'exams' })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
