<template>
  <div class="inner is-flex-grow">
    <div class="inner-panel is-flex-grow sidebar">
      <div class="has-margin-medium">
        <div class="is-flex is-flex-align-center">
          <div class="is-flex-grow is-flex is-flex-align-center">
            <div>
              <h1>Respostas ao exame</h1>
            </div>
            <div v-if="answers.length" class="has-margin-left">
              <b-button
                size="is-small"
                :type="viewDataMode == 'as-gallery' ? 'is-light' : 'is-text'"
                icon-left="th"
                class="has-margin-right-small"
                aria-label="Ver em galeria"
                @click="viewDataMode = 'as-gallery'"
              ></b-button>
              <b-button
                size="is-small"
                :type="viewDataMode == 'as-list' ? 'is-light' : 'is-text'"
                icon-left="list-ul"
                aria-label="Ver em lista"
                @click="viewDataMode = 'as-list'"
              ></b-button>
            </div>
          </div>

          <b-dropdown v-if="zip_file || csv_file" aria-role="list" class="has-margin-right" position="is-bottom-left">
            <button slot="trigger" slot-scope="{ active }" class="button is-light is-flex is-flex-align-center">
              <span class="has-margin-right-small">Descarregar</span>
              <b-icon size="is-small" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
            </button>
            <b-dropdown-item v-if="zip_file" aria-role="listitem">
              <a target="_blank" :href="getFileUrl(zip_file)">Todas as respostas (.zip)</a>
            </b-dropdown-item>
            <b-dropdown-item v-if="csv_file" aria-role="listitem">
              <a target="_blank" :href="getFileUrl(csv_file)">
                Listagem de participantes (.csv)
              </a>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="has-margin-left-medium has-margin-bottom-medium has-margin-right-medium is-height-full">
        <div>
          <div v-if="!answers.length" class=" has-text-centered has-margin-top-large has-text-muted has-text-small">
            <div class="has-margin-medium"><faicon icon="box-open" size="3x"></faicon></div>
            <div>Não existem respostas registadas.</div>
          </div>
          <div v-else class="is-height-auto">
            <div v-if="viewDataMode == 'as-gallery'" class="columns is-multiline is-marginless">
              <div
                v-for="answer in answers"
                :key="answer.key"
                class="column is-half-mobile has-padding-small"
                style="min-width: 180px; max-width: 200px"
              >
                <div
                  class="has-padding-medium has-background-light is-rounded is-height-full is-flex is-flex-direction-column"
                >
                  <div class="is-flex is-flex-direction-column is-flex-align-center has-margin-bottom">
                    <div>
                      <UserAvatar size="md" :user="answer.user" />
                    </div>
                    <div class="is-flex">
                      <div class="has-text-centered">
                        <div class="has-margin-top">
                          {{ answer.user.full_name }}
                        </div>
                        <div class="has-text-muted has-text-tiny">{{ answer.user.number }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="has-text-centered">
                    <div class="has-text-small">
                      <div class="has-text-muted has-text-tiny">1º ficheiro enviado</div>
                      <div class="has-text-muted">{{ answer.created_date | formatDateTime }}</div>
                    </div>

                    <div v-if="answer.answers.length" class="has-margin-top has-text-centered has-text-small">
                      <div class="has-text-muted has-text-tiny">Ficheiros</div>
                      <div
                        v-for="file in answer.answers"
                        :key="file.key"
                        class="has-text-muted has-text-small has-margin-small is-flex is-flex-space-between"
                      >
                        <v-clamp autoresize :max-lines="1">{{ file.title || file.filename }}</v-clamp>
                        <a target="_blank" class="has-margin-left-small has-text-weight-bold" :href="getFileUrl(file)"
                          >Download</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="is-height-full">
              <div v-for="item in answers" :key="item.key">
                <div class="record has-padding-small has-margin-left-small has-margin-right-small">
                  <div class="record-icon">
                    <UserAvatar :user="item.user" />
                  </div>
                  <div class="record-content">
                    <v-clamp autoresize :max-lines="1">{{ item.user.full_name }}</v-clamp>
                    <div class="has-text-tiny has-text-muted">{{ item.user.number || item.user.email }}</div>
                    <div class="is-flex is-flex-align-top has-margin-top">
                      <div>
                        <div class="has-text-tiny has-text-muted">Data do 1º ficheiro enviado</div>
                        <div class="has-text-muted ">
                          {{ item.created_date | formatDateTime }}
                        </div>
                      </div>
                      <div v-if="item.answers.length" class="has-margin-left-large">
                        <div class="has-text-tiny has-text-muted">Ficheiros</div>
                        <div
                          v-for="file in item.answers"
                          :key="file.key"
                          class="has-text-muted is-flex is-flex-start has-margin-bottom-small"
                        >
                          <a target="_blank" class="has-margin-right is-flex-grow" :href="getFileUrl(file)">
                            <v-clamp autoresize :max-lines="1">{{ file.title || file.filename }}</v-clamp>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="has-margin-small" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceExams from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'

export default {
  components: {
    UserAvatar,
  },

  props: {
    exam: {
      type: Object,
      deep: true,
    },
    examSubscription: {
      type: Object,
      default: null,
    },
  },

  data() {
    this.getAnswers()
    return {
      viewDataMode: localStorage.getItem('settings.viewdata.mode') || 'as-gallery',
      zip_file: null,
      csv_file: null,
      answers: [],
    }
  },

  beforeDestroy() {
    if (this.summaryEditor) this.summaryEditor.destroy()
    if (this.examSubscription) this.examSubscription.unsubscribeAnswers()
  },

  methods: {
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file)
    },

    async getAnswers() {
      const response = await ServiceExams.getExamAnswers(this.exam.key)
      this.zip_file = response.zip_file
      this.csv_file = response.csv_file
      this.answers = response.answers
      if (this.examSubscription) this.examSubscription.subscribeAnswers(this.answers)
    },
  },
}
</script>
