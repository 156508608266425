import CoreActions from '@/fw-modules/fw-core-vue/store/actions'

export default Object.assign(CoreActions, {
  loadUserPermissions(context) {
    context.state.api.base.getUserInfo().then(result => {
      context.commit('setUserPermissions', result)
      context.commit('setUserStats', result)
      context.commit('setPermissionsLoaded', true)
    })
  },
  setPermissionsLoaded(context, loaded) {
    context.commit('setPermissionsLoaded', loaded)
  },
  setRecentOpened(context, { objType, item }) {
    if (item) {
      item['objType'] = objType
      let recent = context.state.recentOpened
      if (!recent.find(el => item.key == el.key && el.objType == item.objType)) {
        context.commit('setRecentOpened', [item].concat(recent))
      }
    }
  },
  setSeeAsPublic(context, data) {
    context.commit('setSeeAsPublic', data)
  },
})
