<template>
  <div class="min-w-0 px-3 py-2 my-3 text-white bg-primary rounded-xl">
    <div class="flex">
      <div class="flex-1 flex items-center pl-3">
        <fw-dot-active />
        <fw-heading size="h4">Título do quiz que está em curso</fw-heading>
      </div>
      <div class="flex-none flex">
        <fw-button @click.native="openChart = !openChart">
          <svg
            v-if="!openChart"
            class="fill-current mr-1 w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm1 2v14h16V5H4zm3 8h2v4H7v-4zm4-6h2v10h-2V7zm4 3h2v7h-2v-7z"
            />
          </svg>
          <svg
            v-else
            class="fill-current mr-1 w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
            />
          </svg>
          Gráfico
        </fw-button>
        <fw-button class="ml-4" label="Abrir">
          Abrir
          <svg
            class="fill-current w-5 h-5 ml-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
          </svg>
        </fw-button>
      </div>
    </div>
    <div v-if="openChart" class="flex items-center mt-3 border-t border-white p-5">
      <div class="flex-1 flex items-center">
        <div class="mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="fill-current mr-2">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M5.763 17H20V5H4v13.385L5.763 17zm.692 2L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM11 14h2v2h-2v-2zM8.567 8.813A3.501 3.501 0 1 1 12 13h-1v-2h1a1.5 1.5 0 1 0-1.471-1.794l-1.962-.393z"
            />
          </svg>
        </div>
        <div>
          <p class="font-medium text-xs">
            Questão
          </p>
          <p class="text-xl font-bold">
            O título da questão em causa
          </p>
        </div>
      </div>
      <div class="flex-none flex items-center">
        <div class="mr-4">
          <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z"
            />
          </svg>
        </div>
        <div>
          <p class="font-medium text-xs">
            Respostas
          </p>
          <p class="text-xl font-bold">
            6389
          </p>
        </div>
      </div>
    </div>
    <div v-if="openChart" class="mt-8">
      <ChartHorizontalBar :chartdata="chartdata" class="m-8" />
    </div>
  </div>
</template>

<script>
import ChartHorizontalBar from '@/fw-modules/fw-core-vue/ui/components/charts/ChartHorizontalBar'

export default {
  components: {
    ChartHorizontalBar,
  },

  props: {
    openChart: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chartdata: {
        labels: ['Opção 1', 'Opção 2', 'Opção 3', 'Opção 4'],
        datasets: [
          {
            label: 'Data 1',
            backgroundColor: ['rgba(255,255,255,.75)', 'rgba(255,255,255,.75)'],
            data: [40, 20, 12, 3],
          },
        ],
      },
    }
  },
}
</script>
