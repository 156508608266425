<template>
  <div
    class="fw-panel flex flex-col relative"
    :class="[
      {
        'border-b border-opacity-20 border-gray-800 mb-5 pb-5': section,
        'sticky top-0 z-2 backdrop-blur pb-4 bg-opacity-80 border-b': sticky,
        'h-full': fullHeight,
      },
      sticky ? stickyBgColor : null,
    ]"
  >
    <div class="flex gap-5 items-center flex-shrink-0">
      <div class="flex-1">
        <div v-if="title" class="flex items-center gap-2" :class="customTitleClass">
          <component
            :is="'fw-icon-' + icon"
            v-if="icon"
            class="text-gray-500 w-5 h-5"
            :class="{ 'opacity-50': !featured && muted, 'mt-5': section }"
          ></component>
          <fw-heading
            class="relative"
            :size="featured ? 'h2' : titleSize"
            :counter="counter"
            :counter-total="counterTotal"
            :muted="!featured && muted"
            :class="{ 'mt-5': section }"
            >{{ title || 'Bloco' }}</fw-heading
          >
          <div v-if="loading"><fw-icon-loading class="w-5 h-5" /></div>
          <div v-if="checkboxActive"><fw-icon-checkbox-circle class="w-5 h-5 text-primary" /></div>
          <slot name="after-heading"></slot>
        </div>
        <div v-if="subtitle" class="text-gray-500 font-medium">{{ subtitle }}</div>
      </div>
      <div v-if="seeMorePath">
        <fw-button type="link" @click.native="$router.push(seeMorePath)">{{ seeMoreTitle }}</fw-button>
      </div>
      <div v-if="$slots.toolbar">
        <slot name="toolbar" />
      </div>
    </div>
    <div
      v-if="$slots.default"
      class="rounded-xl flex-1"
      :class="[
        {
          'shadow-md hover:shadow-xl': shadow,
          'p-1 bg-white': boxed === 'xs',
          'p-4 bg-white': boxed === 'sm',
          'p-8 bg-white': boxed === 'md',
          'p-8 bg-white': boxed === 'lg',
          'p-10 bg-white': boxed === 'xl',
          'mt-3': !marginless,
        },
        customClass,
      ]"
    >
      <slot />
    </div>
    <div v-if="$slots.footer" class="mt-2">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FwPanel',
  props: {
    title: {
      type: String,
      default: null,
    },
    titleSize: {
      type: String,
      default: 'h3',
    },
    icon: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    seeMoreTitle: {
      type: String,
      default: function() {
        return this.$t('seeMoreTitle')
      },
    },
    seeMorePath: {
      type: String,
      default: null,
    },
    boxed: {
      type: [Boolean, String],
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    featured: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: [String, Number],
      default: null,
    },
    counterTotal: {
      type: [String, Number],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingError: {
      type: Boolean,
      default: false,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    stickyBgColor: {
      type: String,
      default: 'bg-gray-50',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: true,
    },
    afterLoadingChecked: {
      type: Boolean,
      default: false,
    },
    customClass: String,
    customTitleClass: String,
    marginless: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkboxActive: this.checked,
    }
  },
  watch: {
    loading(value, previous) {
      this.checkboxActive = false
      if (previous && !value && this.afterLoadingChecked && !this.loadingError) {
        this.checkboxActive = true
        setTimeout(() => {
          this.checkboxActive = false
        }, 3000)
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "seeMoreTitle": "Ver todos"
  },
  "en": {
    "seeMoreTitle": "See all"
  }
}
</i18n>
