<template>
  <div class="is-flex-grow has-background-light has-text-light is-height-auto">
    <div class="is-height-full">
      <div class="has-text-muted is-size-3 is-flex is-flex-align-center is-flex-justify-center is-height-full">
        <div class="has-text-centered">
          <div class="has-margin-bottom-large has-text-dark">
            <faicon icon="plug" size="4x"></faicon>
          </div>
          <div class="is-width-small is-size-2">
            Room plugins
          </div>
          <div class="is-width-small has-text-small has-margin-top">
            <div v-for="attendee in roomAttendeesList" :key="attendee.key">
              <p>{{ attendee.name }}</p>
              <div v-if="selfAttendee.withRole('can_promote')">
                <button class="button is-light" @click="toogleSubscriberVisibility(attendee)">
                  <span v-if="!withStreams(attendee)">Allow streaming</span>
                  <span v-else>Block streaming</span>
                </button>
              </div>
              <div v-else>
                Sem permissões para activar audio/video no subscriber
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'

export default {
  props: {
    meeting: Object,
    selfAttendee: Object,
    attendees: Object,
  },

  computed: {
    roomAttendeesList() {
      return Object.entries(this.attendees)
        .filter(i => i[1].withRole('is_room_plugin'))
        .map(i => i[1])
    },
  },

  methods: {
    withStreams(attendee) {
      return (
        attendee.withRole('show_to_everyone') ||
        attendee.withRole('audio_allowed') ||
        attendee.withRole('camera_allowed') ||
        attendee.withRole('sharescreen_allowed')
      )
    },
    async toogleSubscriberVisibility(attendee) {
      const roles = {}
      const newRole = !this.withStreams(attendee)
      roles[ServiceMeetings.getRole('show_to_everyone')] = newRole
      roles[ServiceMeetings.getRole('audio_allowed')] = newRole
      roles[ServiceMeetings.getRole('camera_allowed')] = newRole
      roles[ServiceMeetings.getRole('sharescreen_allowed')] = newRole
      await ServiceMeetings.changeAttendeeRoles(this.meeting.key, attendee.key, roles, true)
    },
  },
}
</script>
