import Vue from 'vue'
import Vuex from 'vuex'

import messages from '@/i18n'

import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import modules from './modules'

import CoreStoreDefaults from '@/fw-modules/fw-core-vue/store/defaults'
import ServiceApply from '@/fw-modules/fw-core-vue/apply/services/ServiceApply'
import ServiceActivity from '@/fw-modules/fw-core-vue/activity/services/ServiceActivity'

Vue.use(Vuex)

const state = Object.assign(CoreStoreDefaults.state, {
  language: localStorage.getItem('settings.language') || 'pt',
  locales: messages['pt'],
  session: {
    error: false,
    errorType: false, // maxLoginAttemptsReached, InternalServerError
    errorDescription: '',
    token: localStorage.getItem('token') || '',
    appsLoaded: false,
    longLivedToken: false,
    user: {},
    apps: [],
    userPhoto: null,
    procedureStats: null,
    permissions: null,
    seeAsPublic: null,
    permissionsLoaded: false,
  },
  isDownloading: 0,
  isSaving: false,
  api: {
    base: ServiceApply,
    isActive: true,
  },
  activityApi: {
    base: ServiceActivity,
    isActive: true,
  },
  //Procedure types allowed to be created
  allowedProcedureTypes: ['scholarships'],
  recentOpened: [],
  showVirtualMeetings: false,
  tmbScrollPos: {},
  socket: {
    connectionId: null,
  },
  connectedUsers: { default: [] },
})

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules,
  getters,
})
