import axios from 'axios'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import querystring from 'querystring'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import store from '@/store'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

const MEETINGS_ROLES_MAP = {
  is_host: 'h', // is host (just a flag)
  can_start: 's', // can start the meeting (join too)
  can_join: 'j', // can join the meeting without waiting/be accepted
  can_accept: 'a', // can accept waiting attendees
  can_end: 'e', // can end the meeting
  can_kick: 'k', // can kick others
  can_record: 'r', // can start/stop recording
  can_promote: 'p', // can promote
  can_see_everyone: 'se', // can see everyone in the meeting
  show_to_everyone: 'ste', // show to everyone in the meeting (could not see everyone)
  can_lower_hands: 'lh', // can lower participants hands
  can_mute_others: 'mo', // can mute/unmute other participants
  audio_allowed: 'au', // can start audio
  camera_allowed: 'ca', // can start camera
  sharescreen_allowed: 'ss', // allowed to start screenshare
  plugin_allowed: 'pg', // allowed to add plugins to meeting
  form_allowed: 'fa', // allowed to start quiz
  can_start_room: 'csr',
  is_room_plugin: 'rp',
}
const MEETING_ROOM = 'meetings.room'
const MEETING_SUBSCRIBED_ROOM = 'meetings.subscribed.room'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlMeetings

    // Make meetings calls quiet
    // No loading bar on the top of the page
    api.defaults.quietly = true
    return api
  },

  getRole(key) {
    return MEETINGS_ROLES_MAP[key]
  },
  withRole(roles, key) {
    return roles.includes(this.getRole(key))
  },

  async create(title) {
    const data = { title: title }
    const response = await this.base().post('/v1/meetings', data)
    return response.data
  },
  async update(key, title) {
    const data = { title: title }
    const response = await this.base().post(`/v1/meeting/${key}`, data)
    return response.data
  },
  async delete(key) {
    const response = await this.base().delete(`/v1/meeting/${key}`)
    return response.data
  },
  async getPermissions(key) {
    const response = await this.base().get(`/v1/meeting/${key}/permissions`)
    response.data.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },
  async addPermission(key, data) {
    const response = await this.base().post(`/v1/meeting/${key}/permissions`, data)
    response.data.users.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },
  async updatePermission(key, userKey, data) {
    const response = await this.base().post(`/v1/meeting/${key}/permission/${userKey}`, data)
    response.data.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },
  async deletePermission(key, userKey) {
    const response = await this.base().delete(`/v1/meeting/${key}/permission/${userKey}`)
    response.data.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },

  async callOwner(key) {
    const response = await this.base().post(`/v1/meeting/${key}/call`)
    return response.data
  },

  subscribe(key) {
    store.commit('sendWSMessage', { application: 'meetings', code: 'subscribe', key: key })
    console.debug('Meeting subscribe', key)
  },
  unsubscribe(key) {
    store.commit('sendWSMessage', { application: 'meetings', code: 'unsubscribe', key: key })
    console.debug('Meeting unsubscribe', key)
  },

  async getStats() {
    const response = await this.base().get('/v1/stats')
    return response.data
  },

  async getMeeting(key) {
    const response = await this.base().get(`/v1/meeting/${key}`)
    return response.data
  },
  async setMeetingAlias(key, alias) {
    await this.base().post(`/v1/meeting/${key}`, { alias: [alias] })
  },
  async updateMeetingAnonymous(key, allow, code = null) {
    const data = { allow_anonymous: allow }
    if (allow) data.anonymous_code = code

    const response = await this.base().post(`/v1/meeting/${key}`, data)
    return response.data
  },

  async joinMeeting(config) {
    const orgArgs = Array.from(arguments)
    const callback = this.joinMeeting

    const data = { connection_id: config.connectionId }
    if (config.rejoining) data.rejoining = true
    if (config.withHD) data.with_hd = true
    if (config.asExternal) {
      data.as_external = true
      if (config.externalDescription) data.external_description = config.externalDescription
      if (config.externalAutoAccept) data.external_auto_accept = config.externalAutoAccept
    }
    const room = this.getLocalRoom()
    if (room) {
      data.roomKey = room.key
      data.roomAdminToken = room.adminToken
    }
    if (process.env.VUE_APP_KEY == 'ucdigitaldesk') {
      data.instanceRoles = {}
      data.instanceRoles[MEETINGS_ROLES_MAP.audio_allowed] = false
    }

    if (!config) {
      config = {}
    }
    config['ignoreInvalidConnectionID'] = true

    try {
      const response = await this.base(config).post(`/v1/meeting/${config.key}/join`, data)
      return response.data
    } catch (error) {
      //retry!
      if (utils.errors(error).exists('InvalidConnectionID')) {
        store.commit('checkWebsocket', {
          retryCallback: callback,
          retryCallbackArgs: orgArgs,
          retryCallbackWait: 100,
          retryCallbackExpectReturn: true,
        })
        return store.state.callbackReturnData
      } else {
        throw error
      }
    }
  },
  async endMeeting(key) {
    const response = await this.base().delete('/v1/meeting/' + key + '/end')
    return response.data
  },
  async leaveMeeting(key) {
    const response = await this.base().delete('/v1/meeting/' + key + '/leave')
    return response.data
  },

  async acceptWaitingParticipants(meetingKey, keys) {
    const data = { keys: keys }
    try {
      const response = await this.base().post(`/v1/meeting/${meetingKey}/accept`, data)
      console.info(`Accepted meeting ${meetingKey} attendees response`, response.data)
    } catch (error) {
      console.error('Failed to accept', keys, error)
    }
  },
  async rejectWaitingParticipants(meetingKey, data) {
    try {
      const response = await this.base().post(`/v1/meeting/${meetingKey}/reject`, data)
      console.info(`Rejected meeting ${meetingKey} attendees response`, response.data)
    } catch (error) {
      console.error('Failed to reject', data, error)
    }
  },

  async kickAttendee(key, attendeeKey, data) {
    await this.base().post(`/v1/meeting/${key}/kick/${attendeeKey}`, data)
  },

  async updateStreamId(key, type, streamId) {
    let path = `/v1/meeting/${key}/stream/${type}`

    let response = null
    if (streamId) response = await this.base().post(path, { id: streamId })
    else response = await this.base().delete(path)
    return response.data
  },

  async setAttendeeFlag(key, flag) {
    await this.base().post(`/v1/meeting/${key}/${flag}`)
  },
  async removeAttendeeFlag(key, flag) {
    await this.base().delete(`/v1/meeting/${key}/${flag}`)
  },
  async lowerAttendeeHand(meetingKey, key) {
    await this.base().delete(`/v1/meeting/${meetingKey}/hand/${key}`)
  },

  async muteAttendee(key, attendeeKey) {
    const response = await this.base().post(`/v1/meeting/${key}/mute/${attendeeKey}`)
    return response.data
  },
  async muteAll(key) {
    const response = await this.base().post(`/v1/meeting/${key}/mute/all`)
    return response.data
  },

  async changeRoles(key, roles, keepForInstance, keepForMeetings) {
    const data = { roles: roles, keep_for_instance: keepForInstance, keep_for_meetings: keepForMeetings }
    const response = await this.base().post(`/v1/meeting/${key}/roles`, data)
    return response.data
  },
  async changeAttendeeRoles(key, attendeeKey, roles, ignore_self_ws_updated = false) {
    const data = { roles: roles, ignore_self_ws_updated: ignore_self_ws_updated }
    const response = await this.base().post(`/v1/meeting/${key}/roles/${attendeeKey}`, data)
    return response.data
  },

  async startRecording(key) {
    const data = { app: process.env.VUE_APP_KEY }
    const response = await this.base().post(`/v1/meeting/${key}/recording`, data)
    return response.data
  },
  async pauseRecording(key) {
    const response = await this.base().delete(`/v1/meeting/${key}/recording?pause`)
    return response.data
  },
  async stopRecording(key) {
    const response = await this.base().delete(`/v1/meeting/${key}/recording`)
    return response.data
  },
  async getRecordings() {
    const response = await this.base().get('/v1/recordings')

    for (let recording of response.data) {
      recording.start_date_obj = Dates.build(recording.start_date)
    }

    response.data.sort((a, b) => {
      if (a.start_date_obj < b.start_date_obj) return 1
      else if (a.start_date_obj > b.start_date_obj) return -1
      else return a.title.localeCompare(b.title)
    })

    return response.data
  },
  async getRecording(key) {
    const response = await this.base().get(`/v1/recording/${key}`)
    return response.data
  },
  async getPublicRecordings(keys, short = false) {
    const config = { ignoreError: true }
    const data = {
      paramsSerializer: params => {
        return querystring.stringify(params)
      },
      params: { key: keys },
    }

    if (short) data.params.short = 'true'

    const response = await this.base(config).get(`/v1/public/recordings`, data)
    if (response && response.data) return response.data
    else return {}
  },
  async updateRecordings(key, data) {
    const response = await this.base().post(`/v1/recording/${key}`, data)
    return response.data
  },
  async deleteRecording(key) {
    await this.base().delete(`/v1/recording/${key}`)
  },

  // Plug-in
  async getPlugInNewCode(key) {
    const response = await this.base().post(`/v1/meeting/${key}/plugin/add`)
    return response.data
  },
  async joinPlugIn(code) {
    const response = await this.base().post('/v1/plugin/join', { code: code })
    return response.data
  },

  // Forms
  async startForm(key, formKey) {
    const data = { key: formKey }
    const response = await this.base().post(`/v1/meeting/${key}/forms`, data)
    return response.data
  },
  async stopForm(key, formInstanceKey) {
    const response = await this.base().delete(`/v1/meeting/${key}/form/${formInstanceKey}`)
    return response.data
  },

  // Rooms
  async createRoom(token) {
    const data = { token: token }
    const response = await this.base().post('/v1/rooms', data)
    localStorage.setItem(MEETING_ROOM, JSON.stringify(response.data))
    return response.data
  },
  getLocalRoom() {
    const roomStr = localStorage.getItem(MEETING_ROOM)
    return roomStr ? JSON.parse(roomStr) : null
  },
  async deleteRoom() {
    const room = this.getLocalRoom()
    if (room) {
      await this.base().delete(`/v1/room/${room.key}/${room.adminToken}`)
      localStorage.removeItem(MEETING_ROOM)
    }
  },
  // Rooms subscriber
  getSubscribedRoom() {
    const roomStr = localStorage.getItem(MEETING_SUBSCRIBED_ROOM)
    const room = roomStr ? JSON.parse(roomStr) : null
    if (room && room.key) return room
  },
  async subscribeRoom(key, roomToken) {
    store.commit('sendWSMessage', { application: 'meetings', code: 'subscribe_room', key: key, token: roomToken })
    console.debug('Room subscribe requested', key)
  },
  subscribeRoomRegister(key, roomToken, name) {
    const room = { key: key, token: roomToken, name: name }
    localStorage.setItem(MEETING_SUBSCRIBED_ROOM, JSON.stringify(room))
    console.debug('Room subscribed', key)
    return room
  },
  unsubscribeRoom() {
    const room = this.getSubscribedRoom()
    if (room) {
      store.commit('sendWSMessage', { application: 'meetings', code: 'unsubscribe_room', key: room.key })
      console.debug('Room unsubscribe', room.key)
    }
  },
  subscribeRoomDeregister() {
    localStorage.removeItem(MEETING_SUBSCRIBED_ROOM)
    console.debug('Room unsubscribed')
  },

  // Anonymous
  async authAnonymous(meetingKey, name, context) {
    const data = {}
    if (name) data.name = name
    if (context.code) {
      data.code = context.code
    }
    if (context.room) {
      data.roomKey = context.room.key
      data.roomToken = context.room.token
    }

    const httpResponse = await this.base().post(`/v1/anonymous/${meetingKey}`, data)
    const response = httpResponse.data

    if (!response.token) {
      return response
    } else {
      console.info('Authenticated as anonymous', response.user, context)

      localStorage.setItem('session.token', response.token)
      response.user.isAnonymous = true
      localStorage.setItem('session.user', JSON.stringify(response.user))

      axios.defaults.headers.common['Authorization'] = response.token
      store.commit('checkWebsocket', { token: response.token })

      store.commit('login', response.token)
      store.commit('setUser', response.user)
      localStorage.setItem('session.isPlugin', true)

      return
    }
  },
  async logoutAnonymous() {
    try {
      await this.base().delete('/v1/anonymous-logout')
    } catch (error) {
      console.error('Failed to logout', error)
    }
    return true
  },
  //Users search
  async searchUsers(meetingKey, params) {
    return await this.base().get('/v1/meeting/' + meetingKey + '/permissions/search-users', {
      params: params,
    })
  },
  async bulkSearchUsers(meetingKey, data = []) {
    return await this.base().post('/v1/meeting/' + meetingKey + '/permissions/search-bulk', data)
  },
}
