<template>
  <div class="meetings-handsup-sidebar">
    <div v-for="form in forms" :key="form.key" class="record">
      <div class="record-content is-flex is-flex-space-between">
        <div class="has-padding-left-small has-padding-right is-size-4">
          <v-clamp autoresize :max-lines="2">{{ form.title }}</v-clamp>
          <button @click="toggleForm(form.key)">Abrir</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    forms: Array,
  },

  methods: {
    toggleForm(key) {
      this.$emit('toggle-form', key)
    },
  },
}
</script>
