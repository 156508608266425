import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
//import querystring from 'querystring'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
//import store from '@/store'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlForms
    api.defaults.quietly = true
    return api
  },

  /**
   * Forms
   */
  async getAllForms(page = null, limit = null) {
    let params = {}
    if (page !== null) {
      params['page'] = page
    }
    if (limit !== null) {
      params['limit'] = limit
    }
    const response = await this.base().get(`/v1/forms`, {
      params: params,
    })
    return response.data
  },
  async getForms() {
    const response = await this.base().get('/v1/forms')

    for (let form of response.data) {
      form.created_date_obj = Dates.build(form.created_date)
    }

    response.data.sort((a, b) => {
      if (a.created_date_obj < b.created_date_obj) return 1
      else return -1
    })

    return response.data
  },
  async getForm(key) {
    const response = await this.base().get(`/v1/form/${key}`)
    return response.data
  },
  async saveForm(data) {
    console.log('THIS ONE!')
    if (data['key'] && data['key'] !== null && typeof data['key'] !== 'undefined') {
      const response = await this.base().post(`/v1/form/${data['key']}`, data)
      return response.data
    } else {
      const response = await this.base().post('/v1/forms', data)
      return response.data
    }
  },
  async deleteForm(key) {
    await this.base().delete(`/v1/form/${key}`)
  },
  async updateFormStatus(key, status) {
    const response = await this.base().post(`/v1/form/${key}`, {
      status: status,
    })
    return response.data
  },

  async addFormPage(formKey, title, schema = [], scores = {}, nextTo = null) {
    const response = await this.base().post(`/v1/form/${formKey}/pages`, {
      title: title,
      nextTo: nextTo,
      schema: schema,
      scores: scores,
    })
    return response.data
  },

  async updateFormPage(pageKey, data) {
    const response = await this.base().post(`/v1/page/${pageKey}`, data)
    return response.data
  },

  async getPage(pageKey) {
    const response = await this.base().get(`/v1/page/${pageKey}`)
    return response.data
  },

  async deleteFormPage(pageKey) {
    const response = await this.base().delete(`/v1/page/${pageKey}`)
    return response.data
  },

  /**
   * Instances
   */
  async getAllInstances({ filterStatus = null, sortBy = null, direction = null, dashboard = false, term = null }) {
    let query = ''
    if (dashboard) {
      query = '?section=dashboard'
    } else {
      if (filterStatus !== null) {
        query += `status=${filterStatus}`
      }
      if (sortBy !== null) {
        query += (query.length > 0 ? '&' : '') + `sortBy=${sortBy}`
      }

      if (direction !== null) {
        query += (query.length > 0 ? '&' : '') + `direction=${direction}`
      }

      if (term != null) {
        query += (query.length > 0 ? '&' : '') + `query=${term}`
      }
      query = query.length > 0 ? '?' + query : ''
    }

    const response = await this.base().get(`/v1/instances${query}`)
    return response.data
  },
  async getInstance(key) {
    const response = await this.base().get(`/v1/instance/${key}`)
    return response.data
  },
  async createInstance(formKeys = [], formTitle = null, formOptions = null, startDate = null, duration = 60) {
    let options = {
      logInteractions: formOptions.logInteractions ? formOptions.logInteractions : false,
      disableRightClick: formOptions.disableRightClick ? formOptions.disableRightClick : false,
      anonymous: formOptions.anonymous ? formOptions.anonymous : false,
    }
    let title =
      formTitle !== null
        ? formTitle
        : {
            pt: '',
            en: '',
          }
    const response = await this.base().post(`/v1/instances`, {
      duration: duration,
      formKeys: formKeys,
      title: title,
      startDate: startDate,
      options: options,
      location: '',
      mode: 'remote',
    })
    return response.data
  },
  async updateInstance(
    instanceKey,
    formKeys = [],
    examTitle = null,
    instanceOptions = null,
    startDate = null,
    duration = 60,
    mode = 'remote',
    location = '',
    organicUnit = null
  ) {
    let options =
      instanceOptions !== null
        ? instanceOptions
        : {
            logInteractions: false,
            disableRightClick: false,
            anonymous: false,
            autoEnd: false,
            autoStart: false,
            autoStartEnd: false,
            isPublic: false,
          }

    let title =
      examTitle !== null
        ? examTitle
        : {
            pt: '',
            en: '',
          }
    const response = await this.base().post(`/v1/instance/${instanceKey}`, {
      formKeys: formKeys,
      title: title,
      startDate: startDate,
      options: options,
      duration: duration,
      mode: mode,
      location: location,
      organic_unit_key: organicUnit,
    })
    return response.data
  },
  async updateOwner(instanceKey, owenerKey) {
    const response = await this.base().post(`/v1/instance/${instanceKey}`, {
      user_key: owenerKey,
    })
    return response.data
  },
  async publishInstance(instanceKey, setStatus = 'publish') {
    const response = await this.base().post(`/v1/instance/${instanceKey}`, {
      status: setStatus,
    })
    return response.data
  },
  async startInstance(instanceKey, setStatus = 'start') {
    const response = await this.base().post(`/v1/instance/${instanceKey}`, {
      status: setStatus,
    })
    return response.data
  },
  async deleteInstance(instanceKey) {
    const response = await this.base().delete(`/v1/instance/${instanceKey}`)
    return response.data
  },
  async bulkSearch(instanceKey, query) {
    const response = await this.base().get(`/v1/instance/${instanceKey}/users/bulk-search?q=${query}`)
    return response.data
  },

  /*
   * Exam buckets
   */
  async getAllExamBuckets() {
    const response = await this.base().get(`/v1/buckets`)
    return response.data
  },
  async getBucket(id) {
    const response = await this.base().get(`/v1/bucket/${id}`)
    return response.data
  },
  async createBucket(name) {
    const response = await this.base().post(`/v1/buckets`, {
      title: {
        pt: name,
      },
    })
    return response.data
  },
  async deleteBucket(id) {
    const response = await this.base().delete(`/v1/bucket/${id}`)
    return response.data
  },
  async updateBucket(id, data) {
    const response = await this.base().post(`/v1/bucket/${id}`, data)
    return response.data
  },
  // ActivityAPI handler
  async getActivityLogs(payload) {
    // PAYLOAD ===
    // "service": "apply",
    // "page": 1,
    // "start_date": "2022-01-01",
    // "end_date": "2022-12-31",
    // "reference_keys": {
    // },
    // "code": "",
    // "context": "",
    // "context_key": "",
    // "by_user_key": "",
    // "by_token_key": ""
    let instanceID = payload['instanceKey']
    payload['context_key'] = instanceID
    delete payload['instanceKey']
    delete payload['service']
    //delete payload['service']
    const response = await this.base().get(
      `/v1/instance/${instanceID}/activity?service=forms&service=${decodeURIComponent(
        'ucstudent-app'
      )}&service=${decodeURIComponent('ucteacher-app')}`,
      { params: payload }
    )
    return response.data
  },

  async getNotifications(instanceKey, limit = 50) {
    let payload = { limit: limit }
    const response = await this.base().get(`/v1/instance/${instanceKey}/notifications`, { params: payload })
    return response.data
  },

  /**
   * Instances Permissions
   */
  async getInstancePermissions(instanceKey) {
    const response = await this.base().get(`/v1/instance/${instanceKey}/permissions`)
    return response.data
  },
  async addInstancePermissions(instanceKey, email = '', permissions = {}) {
    let permissionData = { ...permissions }
    permissionData['email'] = email
    const response = await this.base().post(`/v1/instance/${instanceKey}/permissions`, permissionData)
    return response.data
  },
  async addInstancePermissionsByUserkey(instanceKey, userKeys = [], permissions = {}) {
    let permissionData = { ...permissions }
    permissionData['user_keys'] = userKeys
    const response = await this.base().post(`/v1/instance/${instanceKey}/permissions`, permissionData)
    return response.data
  },
  async updateInstancePermissions(instanceKey, userKey = '', permissions = {}) {
    const response = await this.base().post(`/v1/instance/${instanceKey}/permissions/${userKey}`, permissions)
    return response.data
  },
  async deleteInstancePermissions(instanceKey, userKey = '') {
    await this.base().delete(`/v1/instance/${instanceKey}/permissions/${userKey}`)
  },

  /**
   * Answers Endpoint
   */
  async getExamineeAnswers(instanceKey, userKey) {
    let response
    try {
      response = await this.base().get(`/v1/instance/${instanceKey}/answer/${userKey}`)
      return response.data
    } catch (err) {
      throw err.response.data
    }
  },
  async getExamineeInstance(instanceKey) {
    /*this.base()
      .get(`/v1/live/instance/${instanceKey}`)
      .then(result => {
        console.log('result', result)
      })
      .catch(err => {
        console.log('err', err.response)
      })*/
    let response
    try {
      response = await this.base().get(`/v1/live/instance/${instanceKey}`)
      return response.data
    } catch (err) {
      throw err.response.data
    }
    /*try {
      const response = await this.base().get(`/v1/live/instance/${instanceKey}`)
      return response.data
    } catch (e) {
      console.log('error inside,', e)
    }*/
  },
  async getExamineePage(instanceKey, pageKey) {
    const response = await this.base().get(`/v1/live/instance/${instanceKey}/page/${pageKey}`)
    return response.data
  },
  async saveAnswer(instanceKey, pageKey, answers) {
    const response = await this.base().post(`/v1/live/instance/${instanceKey}/page/${pageKey}`, answers)
    return response.data
  },
  async getAnswers(instanceKey, includeUserInfo = false, sort = null, direction = 'asc', keyword = null, filters = []) {
    let query = ''
    if (includeUserInfo) {
      query += 'users=True'
    }
    if (sort != null) {
      query += (query.length > 0 ? '&' : '') + 'sort=' + sort
    }

    if (direction != null) {
      query += (query.length > 0 ? '&' : '') + 'direction=' + direction
    }

    console.log('KEYWORD: ' + keyword)

    if (keyword != null) {
      query += (query.length > 0 ? '&' : '') + 'query=' + keyword
    }

    if (filters.length > 0) {
      for (let i = 0; i < filters.length; i++) {
        const filterParts = filters[i].split(':')
        if (filterParts.length == 2) {
          query += (query.length > 0 ? '&' : '') + filterParts[0] + '=' + filterParts[1]
        }
      }
    }

    if (query.length > 0) {
      query = '?' + query
    }

    const response = await this.base().get(`/v1/instance/${instanceKey}/answers${query}`)
    return response.data
  },
  async submitAnswers(instanceKey) {
    const response = await this.base().post(`/v1/live/instance/${instanceKey}`, {
      status: 'close',
    })
    return response.data
  },
  async giveup(instanceKey) {
    const response = await this.base().post(`/v1/live/instance/${instanceKey}`, {
      status: 'withdraw',
    })
    return response.data
  },
  async savePhotoAndTerms(instanceKey, photoKey, acceptTerms) {
    const response = await this.base().post(`/v1/live/instance/${instanceKey}`, {
      photo_key: photoKey,
      accept_terms: acceptTerms,
    })
    return response.data
  },
  async reopenExam(instanceKey) {
    const response = await this.base().post(`/v1/live/instance/${instanceKey}`, {
      status: 'reopen',
    })
    return response.data
  },

  /**
   * Save manual correction
   */
  async saveManualCorrection(instanceKey, answerId, data) {
    let keys = Object.keys(data)
    for (let index = 0; index < keys.length; index++) {
      let pageKey = keys[index]
      let questionKeys = Object.keys(data[pageKey])
      for (let index = 0; index < questionKeys.length; index++) {
        let points =
          typeof data[pageKey][questionKeys[index]].points == 'string'
            ? parseFloat(data[pageKey][questionKeys[index]].points)
            : data[pageKey][questionKeys[index]].points
        data[pageKey][questionKeys[index]].points = points
      }
    }
    const response = await this.base().post(`/v1/instance/${instanceKey}/answer/${answerId}`, data)
    return response.data
  },

  /**
   * Add student to instance
   */
  async addStudentToInstance(instanceKey, users = []) {
    const response = await this.base().post(
      `/v1/instance/${instanceKey}/users`,
      users
    ) /*
{
      users: users,
    }
    */
    return response.data
  },
  async removeStudentToInstance(instanceKey, users = []) {
    const response = await this.base().delete(`/v1/instance/${instanceKey}/users`, {
      data: {
        users: users,
      },
    })
    return response.data
  },

  async removeManagerToInstance(instanceKey, users = []) {
    const response = await this.base().delete(`/v1/instance/${instanceKey}/permissions`, {
      data: users,
    })
    return response.data
  },

  /**
   * Subscribe to instance start
   */
  async subscribeInstance(connectionId, instanceKey) {
    const response = await this.base().post(`/v1/ws`, {
      code: 'subscribe_instance',
      connectionId: connectionId,
      body: {
        instance_key: instanceKey,
      },
    })
    return response.data
  },
  async unsubscribeInstance(connectionId, instanceKey) {
    const response = await this.base().post(`/v1/ws`, {
      code: 'unsubscribe_instance',
      connectionId: connectionId,
      body: {
        instance_key: instanceKey,
      },
    })
    return response.data
  },

  async getUsersInfo(instanceKey, users) {
    console.log(users)
    const response = await this.base().get(`/v1/instance/${instanceKey}/users`)
    return response.data
  },
  async searchUsers(instanceKey, data) {
    const response = await this.base().get(`/v1/instance/${instanceKey}/users/search`, { params: data })
    return response.data
  },
  async getUsersFromEndpoint(endpoint, data) {
    const response = await this.base().get(endpoint, { params: data })
    return response.data
  },

  /**
   * Get dahsboard data
   */

  async getDashboardData(instanceKey) {
    const response = await this.base().get(`/v1/live/instance/${instanceKey}/dashboard`)
    return response.data
  },
  /**
   * Send WS messsages to exam managers
   * */
  async sendWSMessageToManagers(instanceKey, code, value = '') {
    const response = await this.base().post(`/v1/live/instance/${instanceKey}/activity`, {
      code: code,
      value: value.toString(),
    })
    return response.data
  },
  /**
   * Forms Activity Messages
   */
  activityMessages() {
    return {
      frontend: {
        'answer-question': {
          pt: 'Respondeu a uma questão',
        },
        'exam-selfie-taken': {
          pt: 'Tirou fotografia no início do exame',
        },
        'exam-use-terms-accepted': {
          pt: 'Aceitou os termos de utilização do exame',
        },
        'leave-window': {
          pt: 'Saiu da janela do exame',
        },
        copy: {
          pt: 'Usou funcionalidade de cópia',
        },
        paste: {
          pt: 'Usou funcionalidade de colar',
        },
        'leaving-full-screen': {
          pt: 'Desativou ecrã inteiro',
        },
        'enter-full-screen': {
          pt: 'Ativou ecrã inteiro',
        },
        'enter-window': {
          pt: 'Voltou à janela do exame',
        },
        'timeover-warning-ack': {
          pt: 'Reconheceu o aviso de que o tempo está a esgotar-se',
        },
        'exit-exam': {
          pt: 'Saiu do exame',
        },
      },
      instance: {
        new: {
          pt: 'Gestão: Criou o exame',
        },
        update: {
          pt: 'Gestão: Atualizou o exame',
        },
        publish: {
          pt: 'Gestão: Publicou o exame',
        },
        unpublish: {
          pt: 'Gestão: Despublicou o exame',
        },
        'auto-start': {
          pt: 'Gestão: Iniciou o exame automaticamente',
        },
        start: {
          pt: 'Gestão: Iniciou o exame',
        },
        stop: {
          pt: 'Gestão: Terminou o exame',
        },
        'auto-stop': {
          pt: 'Gestão: Terminou o exame automaticamente',
        },
        lock_answers: {
          pt: 'Gestão: Lacrou as respostas do exame',
        },
        unlock_answers: {
          pt: 'Gestão: Deslacrou as respostas do exame',
        },
        delete: {
          pt: 'Gestão: Eliminou o exame',
        },
        archive: {
          pt: 'Arquivou o exame',
        },
      },
      form: {
        new: {
          pt: 'Gestão: Adicionou uma versão ao exame',
        },
        close: {
          pt: 'Gestão: Fechou a edição da versão',
        },
        reopen: {
          pt: 'Gestão: Reabriu a edição da versão',
        },
        update: {
          pt: 'Gestão: Atualizou a versão',
        },
        delete: {
          pt: 'Gestão: Eliminou uma versão',
        },
      },
      page: {
        new: {
          pt: 'Adicionou uma página ao exame',
        },
        update: {
          pt: 'Atualizou uma página do exame',
        },
        delete: {
          pt: 'Eliminou uma página do exame',
        },
      },
      answer: {
        new: {
          pt: 'Registou-se no exame',
        },
        close: {
          pt: 'Entregou o exame',
        },
        'auto-close': {
          pt: 'O exame foi entregue automaticamente',
        },
        reopen: {
          pt: 'Reabriu o exame',
        },
        withdraw: {
          pt: 'Desistiu do exame',
        },
      },
      'page-answer': {
        new: {
          pt: 'Adicionou uma resposta ao exame',
        },
        update: {
          pt: 'Atualizou uma resposta do exame',
        },
      },
    }
  },
  // Activity codes that show an alarm
  activityAlarmCodes() {
    return ['leave-window', 'paste', 'leaving-full-screen']
  },
  frontendActivityCodes() {
    return [
      'leave-window',
      'copy',
      'paste',
      'leaving-full-screen',
      'enter-full-screen',
      'enter-window',
      'timeover-warning-ack',
      'exit-exam',
      'exam-selfie-taken',
      'exam-use-terms-accepted',
      'answer-question',
    ]
  },
  //Activity codes that should be seen in the Main Activity section of the live dashboard
  activityMainCodes() {
    return [
      'reopen',
      'close',
      'enter-exam',
      'withdraw',
      'leave-window',
      'copy',
      'paste',
      'enter-window',
      'leaving-full-screen',
      'enter-full-screen',
      'exam-selfie-taken',
    ]
  },
  //Exams dashboard statistics values
  dashboardStatisticsMessages(language) {
    if (language === 'en') {
      return {
        archived: 'Archived',
        deleted: 'Deleted',
        draft: 'Draft',
        ended: 'Ended',
        running: 'Running',
        scheduled: 'Scheduled',
        total: 'Total',
      }
    } else {
      return {
        archived: 'Arquivados',
        deleted: 'Eliminados',
        draft: 'Rascunhos',
        ended: 'Terminados',
        running: 'Em curso',
        scheduled: 'Agendados',
        total: 'Total',
      }
    }
  },
  //Revoke tablet used by user
  async revokeDevice(instanceKey, userKey) {
    const response = await this.base().post(`/v1/manage/live/instance/${instanceKey}`, {
      action: 'remove_device',
      user_key: userKey,
    })
    return response.data
  },
  async generateAccessCode(instanceKey) {
    const response = await this.base().post(`/v1/instance/${instanceKey}/small-key`, {})
    return response.data
  },
  //update files
  async duplicateFiles(files) {
    const response = await this.base().post(`/v1/files/duplicate`, files)
    return response.data
  },

  async getDevices(instanceKey) {
    const response = await this.base().get(`/v1/live/instance/${instanceKey}/dashboard/devices`)
    return response.data
  },
  async checkoutTablet(code) {
    const response = await this.base().post(`/v1/devices/checkout`, {
      checkout_code: code,
    })
    return response.data
  },
  async refreshDevicesCode(instanceKey) {
    const response = await this.base().post(`/v1/instance/${instanceKey}/checkout-code`, {})
    return response.data
  },
  async getPasswordlessAccesses(instanceKey) {
    const response = await this.base().get(`/v1/instance/${instanceKey}/passwordless`)
    return response.data
  },
  async createPinAccess(instanceKey, studentNumber) {
    const response = await this.base().post(`/v1/instance/${instanceKey}/passwordless`, {
      student_number: studentNumber,
    })
    return response.data
  },
}
