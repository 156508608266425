export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/ViewHome'),
    meta: { requiresAuth: true },
  },
  {
    path: '/procedure/:code',
    name: 'procedure',
    component: () => import('@/views/ViewProcedure'),
    meta: { requiresAuth: true },
  },
  {
    path: '/explore',
    name: 'explore',
    component: () => import('@/views/ViewExplore'),
    meta: { requiresAuth: true },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/fw-modules/fw-core-vue/notifications/views/SimpleNotifications'),
    meta: { requiresAuth: true },
  },
  // {
  //   path: '/support',
  //   name: 'support',
  //   component: () => import('@/views/ViewSupport'),
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: '/support/:taskId',
  //   name: 'support-issue',
  //   component: () => import('@/views/ViewSupportIssue'),
  //   meta: { requiresAuth: true },
  // },
  {
    path: '/application/:procedureKey/:view',
    name: 'application',
    component: () => import('@/views/ViewApplication'),
    meta: { requiresAuth: true },
  },
]
