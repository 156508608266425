<template>
  <div class="w-full hover:bg-gray-200 hover:bg-opacity-30 rounded-xl" :class="{ record: !paddingless }">
    <div class="flex gap-3 items-center w-full">
      <div class="bg-gray-100 rounded-lg h-9 w-9 flex items-center justify-center text-gray-500">
        <faicon :icon="getIcon()" />
      </div>

      <div v-if="!inputOpened" class="flex-1 min-w-0">
        <v-clamp
          class="truncate text-base font-medium text-gray-800"
          :class="{ 'cursor-pointer hover:text-primary': canDownload }"
          autoresize
          :max-lines="1"
          @click.native="
            canDownload && downloadFromTitle ? $emit('download', file) : openModal ? $emit('open', file) : null
          "
          >{{ fileTitle }}</v-clamp
        >
        <slot name="secondline"></slot>
      </div>

      <div v-else class="flex-1 flex gap-5">
        <input :id="'input-title-' + file.key" v-model="file.title" autofocus class="flex-1 px-3" />
        <fw-button type="xlight" class="py-1.5" @click.native="saveFile(file)">
          {{ $t('actions.save') }}
        </fw-button>
        <fw-button type="xlight" class="py-1.5" @click.native="cancelEdit">
          {{ $t('actions.cancel') }}
        </fw-button>
      </div>

      <DownloadButton
        v-if="canDownload && !inputOpened"
        :no-margin="true"
        :download-total-size="file.size"
        :size="minimal ? 'sm' : 'md'"
        @download="$emit('download', file)"
      />

      <fw-button v-if="canEdit && !inputOpened" type="xlight" class="py-1.5" @click.native="openEdit">
        <fw-icon-edit class="w-5 h-5 text-gray-500" />
      </fw-button>

      <fw-button v-if="canRemove && !inputOpened" type="xlight" class="py-1.5" @click.native="$emit('remove', file)">
        <fw-icon-trash class="w-5 h-5 text-gray-500" />
      </fw-button>

      <slot name="suffix"></slot>
    </div>
  </div>
</template>

<script>
import DownloadButton from '@/fw-modules/fw-core-vue/ui/components/buttons/DownloadButton'

export default {
  components: {
    DownloadButton,
  },
  props: {
    file: Object,
    canEdit: Boolean,
    canRemove: {
      type: Boolean,
      default: true,
    },
    allowClassified: {
      type: Boolean,
      default: true,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    canDownload: {
      type: Boolean,
      default: true,
    },
    downloadFromTitle: {
      type: Boolean,
      default: true,
    },
    openModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inputOpened: false,
      previousTitle: '',
    }
  },

  computed: {
    fileTitle() {
      return this.file.title && this.file.title !== null
        ? this.file.title
        : this.file.filename
        ? this.file.filename
        : ''
    },
  },

  methods: {
    saveFile(file) {
      this.$emit('save', file)
      this.inputOpened = false
    },
    showHelpFileReserve() {
      this.$buefy.dialog.alert({
        title: this.$t('classifiedDoc'),
        message: this.$t('classifiedInstructions'),
      })
    },
    openEdit() {
      if (this.canEdit) {
        this.inputOpened = true
        this.previousTitle = this.file.title
        this.$nextTick(() => {
          document.getElementById('input-title-' + this.file.key).focus()
        })
      }
    },
    cancelEdit() {
      this.file.title = this.previousTitle
      this.inputOpened = false
    },
    getIcon() {
      const extension = this.$options.filters.getExtension(this.file.filename)
      const map = {
        pdf: 'file-pdf',
        png: 'image',
        jpeg: 'image',
        jpg: 'image',
      }
      if (!(extension in map)) {
        return 'file-alt'
      }
      return map[extension]
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "download": "Descarregar",
    "knowMore": "Saber mais",
    "classifiedDoc": "Documento classificado",
    "classifiedInstructions": "Escolha esta opção caso o ficheiro seja documento classificado, que revele segredo comercial ou industrial, ou segredo relativo à propriedade literária, artística ou científica, sob pena do mesmo ser livremente acedido por qualquer um dos demais candidatos, em sede de consulta de processo.",
    "classified": "Classificado",
    "actions": {
      "save": "Guardar",
      "cancel": "Cancelar",
      "delete": "Apagar"
    }
  },
  "en": {
    "download": "Download",
    "knowMore": "Know more",
    "classifiedDoc": "Classified document",
    "classifiedInstructions": "Choose this option if the file is a classified document that reveals a commercial or industrial or industrial secret, or secret relating to literary, artistic or scientific property, under penalty of it be freely accessed by any of the other candidates, when consulting the file.",
    "classified": "Classified",
    "actions": {
      "save": "Save",
      "cancel": "Cancel",
      "delete": "Delete"
    }
  }
}
</i18n>
