import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import store from '@/store'

const STATUS_DESCRIPTIONS = {
  draft: 'Rascunho',
  closed: 'Pronto a iniciar',
  running: 'A decorrer',
  ended: 'Fechado',
}

const sortRoomByTitle = (a, b) => {
  return Number(a.title.match(/(\d+)/g)[0]) - Number(b.title.match(/(\d+)/g)[0])
}

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlExams
    return api
  },

  getStatusTitle(key) {
    return STATUS_DESCRIPTIONS[key]
  },

  async getExams() {
    const response = await this.base().get('/v1/exams')

    for (let exam of response.data) {
      exam.created_date_obj = Dates.build(exam.created_date)
    }
    response.data.sort((a, b) => {
      if (a.created_date_obj < b.created_date_obj) return 1
      else if (a.created_date_obj > b.created_date_obj) return -1
      else return 0
    })

    return response.data
  },
  async addExam(title) {
    const response = await this.base().post('/v1/exams', { title: title })
    return response.data.key
  },
  async getExam(key, withChats = false, withChatUsers = false, withRoomChats = false) {
    let query = []
    if (withChats) query.push('with_chats=true')
    if (withChatUsers) query.push('with_chat_users=true')
    if (withRoomChats) query.push('with_room_chats=true')

    let path = `/v1/exam/${key}`
    if (query.length) path += `?${query.join('&')}`

    const response = await this.base().get(path)

    if (response.data.rooms) {
      response.data.rooms.sort(sortRoomByTitle)
    }

    return response.data
  },
  async updateExam(key, data) {
    const response = await this.base().post(`/v1/exam/${key}`, data)
    return response.data
  },
  async updateExamStatus(key, status) {
    const response = await this.base().post(`/v1/exam/${key}`, { status: status })
    return response.data
  },
  async kickUserFromExam(key, userKey, ban = false) {
    await this.base().post(`/v1/exam/${key}/kick`, { user_key: userKey, ban: ban })
  },
  async addFilesToExam(key, fileKeys) {
    const response = await this.base().post(`/v1/exam/${key}/files`, { files: fileKeys })
    return response.data
  },
  async updateExamFile(key, fileKey, data) {
    const response = await this.base().post(`/v1/exam/${key}/file/${fileKey}`, data)
    return response.data
  },
  async deleteFileFromExam(key, fileKey) {
    const response = await this.base().delete(`/v1/exam/${key}/file/${fileKey}`)
    return response.data
  },
  async addRoomToExam(key) {
    const response = await this.base().post(`/v1/exam/${key}/rooms`)
    response.data.rooms.sort(sortRoomByTitle)
    return response.data
  },
  async getExamRooms(key) {
    const response = await this.base().get(`/v1/exam/${key}/rooms`)
    response.data.sort(sortRoomByTitle)
    return response.data
  },
  async getRoom(key) {
    const response = await this.base().get(`/v1/room/${key}`)
    return response.data
  },
  async getExamAnswers(key) {
    const response = await this.base().get(`/v1/exam/${key}/answers`)
    return response.data
  },
  async deleteRoom(key) {
    const response = await this.base().delete(`/v1/room/${key}`)
    response.data.sort(sortRoomByTitle)
    return response.data
  },
  async deleteExam(key) {
    await this.base().delete(`/v1/exam/${key}`)
  },
  async getExamPermissions(key) {
    const response = await this.base().get(`/v1/exam/${key}/permissions`)
    response.data.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },
  async addExamPermission(key, data) {
    const response = await this.base().post(`/v1/exam/${key}/permissions`, data)
    response.data.users.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },
  async updateExamPermission(key, userKey, data) {
    const response = await this.base().post(`/v1/exam/${key}/permissions/${userKey}`, data)
    response.data.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },
  async deleteExamPermission(key, userKey) {
    const response = await this.base().delete(`/v1/exam/${key}/permissions/${userKey}`)
    response.data.sort((a, b) => a.name.localeCompare(b.name))
    return response.data
  },

  async addAnswersToExam(key, fileKeys) {
    const response = await this.base().post(`/v1/exam/${key}/answers`, { files: fileKeys })
    return response.data
  },
  async updateExamAnswer(key, fileKey, title) {
    const response = await this.base().post(`/v1/exam/${key}/answer/${fileKey}`, { title: title })
    return response.data
  },
  async deleteAnswerFromExam(key, fileKey) {
    const response = await this.base().delete(`/v1/exam/${key}/answer/${fileKey}`)
    return response.data
  },

  createExamSubscription(exam, runningCallback = null) {
    const subscriptionName = `Exam-${exam.key}`
    const cls = {
      exam: exam,
      rooms: null,
      answers: null,
      runningCallback: runningCallback,

      async subscribeExam() {
        const data = {
          application: 'exams',
          code: 'subscribe',
          key: cls.exam.key,
          as_manager: cls.exam.is_manager,
        }
        store.commit('sendWSMessage', data)
        console.debug(`Exam ${cls.exam.key} subscribe manager:${cls.exam.is_manager}`)
      },
      async unsubscribe() {
        const data = {
          application: 'exams',
          code: 'unsubscribe',
          key: cls.exam.key,
          as_manager: cls.exam.is_manager,
        }
        store.commit('sendWSMessage', data)
        console.debug(`Exam ${cls.exam.key} unsubscribe`)
      },
      async subscribeRooms(rooms) {
        cls.rooms = rooms
        const data = {
          application: 'exams',
          code: 'subscribe_rooms',
          key: cls.exam.key,
        }
        store.commit('sendWSMessage', data)
        console.debug(`Exam ${cls.exam.key} rooms subscribe`)
      },
      async unsubscribeRooms() {
        cls.rooms = null
        const data = {
          application: 'exams',
          code: 'unsubscribe_rooms',
          key: cls.exam.key,
        }
        store.commit('sendWSMessage', data)
        console.debug(`Exam ${cls.exam.key} rooms unsubscribe`)
      },
      async subscribeAnswers(answers) {
        cls.answers = answers
        const data = {
          application: 'exams',
          code: 'subscribe_answers',
          key: cls.exam.key,
        }
        store.commit('sendWSMessage', data)
        console.debug(`Exam ${cls.exam.key} answers subscribe`)
      },
      async unsubscribeAnswers() {
        cls.answers = null
        const data = {
          application: 'exams',
          code: 'unsubscribe_answers',
          key: cls.exam.key,
        }
        store.commit('sendWSMessage', data)
        console.debug(`Exam ${cls.exam.key} answers unsubscribe`)
      },
      async reconnect() {
        cls.subscribeExam()
        if (cls.rooms !== null) cls.subscribeRooms(cls.rooms)
        if (cls.answers !== null) cls.subscribeAnswers()
      },

      async WSMessages(messages) {
        if (messages.examDelta) {
          for (let message of messages.examDelta) {
            if (message.key === cls.exam.key) {
              if (message.delta.files && message.delta.files.length) {
                await ServiceStorage.setFilesMetadata(message.delta.files)
              }

              Object.assign(cls.exam, message.delta)
              if (cls.runningCallback && message.delta.status == 'running') cls.runningCallback()
            }
          }
        }

        if (cls.rooms !== null) {
          if (messages.roomAdd) {
            const existingKeys = new Set()
            for (let room of cls.rooms) existingKeys.add(room.key)

            for (let room of messages.roomAdd) {
              if (!existingKeys.has(room.key)) {
                cls.rooms.push(room)
                existingKeys.add(room.key)
              }
            }
          }

          if (messages.roomDelete) {
            for (let room of messages.roomDelete) {
              for (let idx = 0; idx < cls.rooms.length; idx++) {
                if (cls.rooms[idx].key == room.key) {
                  cls.rooms.splice(idx, 1)
                  break
                }
              }
            }
          }
        }

        if (cls.answers !== null) {
          if (messages.answerAdd) {
            const existingKeys = new Set()
            for (let answer of cls.answers) existingKeys.add(answer.key)

            for (let answer of messages.answerAdd) {
              if (!existingKeys.has(answer.key)) {
                await ServiceStorage.setFilesMetadata(answer.answers)
                cls.answers.push(answer)
                existingKeys.add(answer.key)
              }
            }
          }
          if (messages.answerDelete) {
            for (let wsAnswer of messages.answerDelete) {
              const answerKey = wsAnswer.key
              for (let answerIdx in cls.answers) {
                if (cls.answers[answerIdx].key === answerKey) {
                  cls.answers.splice(answerIdx, 1)
                  break
                }
              }
            }
          }

          if (messages.answerFileAdd) {
            for (let message of messages.answerFileAdd) {
              for (let idx = 0; idx < cls.answers.length; idx++) {
                const answer = cls.answers[idx]
                if (answer.key == message.key) {
                  await ServiceStorage.setFilesMetadata(message.files)
                  answer.answers.push(...message.files)
                  break
                }
              }
            }
          }

          if (messages.answerFileDelta) {
            for (let message of messages.answerFileDelta) {
              for (let idx = 0; idx < cls.answers.length; idx++) {
                const answer = cls.answers[idx]
                if (answer.key == message.key) {
                  for (let fileIdx in answer.answers) {
                    const file = answer.answers[fileIdx]
                    if (file.key == message.file_key) {
                      Object.assign(file, message.delta)
                      break
                    }
                  }
                  break
                }
              }
            }
          }

          if (messages.answerFileDelete) {
            for (let message of messages.answerFileDelete) {
              for (let idx = 0; idx < cls.answers.length; idx++) {
                const answer = cls.answers[idx]
                if (answer.key == message.key) {
                  for (let fileIdx in answer.answers) {
                    const file = answer.answers[fileIdx]
                    if (file.key == message.file_key) {
                      answer.answers.splice(fileIdx, 1)
                      break
                    }
                  }
                  break
                }
              }
            }
          }
        }
      },

      destroy() {
        cls.unsubscribe()
        if (cls.rooms !== null) cls.unsubscribeRooms()
        if (cls.answers !== null) cls.unsubscribeAnswers()

        store.commit('unsubscribeWS', { code: 'ws-reconnect', name: subscriptionName })
        store.commit('unsubscribeWS', { code: 'exams', name: subscriptionName })
      },
    }

    store.commit('subscribeWS', { code: 'ws-reconnect', name: subscriptionName, callback: cls.reconnect })
    store.commit('subscribeWS', { code: 'exams', name: subscriptionName, callback: cls.WSMessages })
    cls.subscribeExam()
    return cls
  },
}
