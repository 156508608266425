import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import Api from '@/fw-modules/fw-core-vue/api/Api'

export default {
  base() {
    let api = Api()
    api.defaults.baseURL = FwEnvConfig.apiUrlPages + '/api'
    api.defaults.quietly = true
    return api
  },

  async getNewsFeatured() {
    const response = await this.base().get(`/v1-get-news`, {
      headers: {
        Authorization: '8af1b8e830f1b5a892fe0b37832d412eb2a7e9a990',
      },
    })
    return response.data
  },
}
