<template>
  <b-dropdown v-if="isDebug && janus && !isMobile" aria-role="list" :triggers="['click']">
    <fw-button-dropdown slot="trigger" role="button" type="transparent" class="text-red-500">
      DEBUG
    </fw-button-dropdown>
    <b-dropdown-item
      v-if="instance && instance.janus"
      key="server-key"
      aria-role="listitem"
      class="has-text-muted has-text-tiny"
    >
      <div class="has-text-weight-bold">Server</div>
      <div>{{ instance.janus.key }}</div>
    </b-dropdown-item>
    <b-dropdown-item v-if="audio.active" key="audio-bitrate" aria-role="listitem" class="has-text-muted has-text-tiny">
      <div class="has-text-weight-bold">Audio</div>
      <div>{{ audio.bitrate.value }}</div>
      <div v-if="audio.bitrate.qualityLimitationReason">(issue: {{ audio.bitrate.qualityLimitationReason }})</div>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="camera.active"
      key="camera-bitrate"
      aria-role="listitem"
      class="has-text-muted has-text-tiny"
    >
      <div class="has-text-weight-bold">Camera</div>
      <div>{{ camera.bitrateLevel }} - {{ camera.bitratePercentage }}%</div>

      <div
        v-for="(bitrateInfo, ssrc) in camera.bitrate"
        :key="'camera-ssrc-' + ssrc"
        aria-role="listitem"
        class="has-text-muted has-text-tiny"
      >
        <div class="has-text-weight-bold">{{ ssrc }}</div>
        <div>{{ bitrateInfo.value }}</div>
        <div>{{ bitrateInfo.width }}x{{ bitrateInfo.height }}</div>
        <div v-if="bitrateInfo.qualityLimitationReason">(issue: {{ bitrateInfo.qualityLimitationReason }})</div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="screenShare.active"
      key="screenShare-bitrate"
      aria-role="listitem"
      class="has-text-muted has-text-tiny"
    >
      <div class="has-text-weight-bold">Screen share</div>
      <div>{{ screenShare.bitrate.value }}</div>
      <div>{{ screenShare.bitrate.width }}x{{ screenShare.bitrate.height }}</div>
      <div>{{ screenShare.bitrateLevel }} - {{ screenShare.bitratePercentage }}%</div>
      <div v-if="screenShare.bitrate.qualityLimitationReason">
        (issue: {{ screenShare.bitrate.qualityLimitationReason }})
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    janus: Object,
    instance: Object,
    audio: Object,
    camera: Object,
    screenShare: Object,
  },

  computed: {
    isDebug() {
      return localStorage.getItem('fw-debug')
    },
    isMobile() {
      return window.innerWidth < 640
    },
  },
}
</script>
