<template>
  <b-modal
    :active="true"
    :width="600"
    :on-cancel="closeModal"
    has-modal-card
    aria-role="dialog"
    aria-modal
    class="meetings-modal-pods-enter-queue"
  >
    <div class="modal-card is-width-auto has-text-centered">
      <section class="modal-card-body">
        <h1>{{ form.title }}</h1>
        <b v-if="form.anonymous">Este Quiz é anónimo</b>

        <form @submit.prevent="saveForm">
          <div>{{ firstItem.title }}</div>

          <b-field label="Opções" :type="{ 'is-danger': !!errorMessage }" :message="errorMessage" class="is-marginless">
            <b-checkbox
              v-for="option in firstItem.options"
              :key="option.id"
              v-model="selected"
              :native-value="option.id"
              :disabled="shouldDisable(option.id)"
            >
              {{ option.title }}
            </b-checkbox>
          </b-field>
          <div>Escolha de {{ firstItem.min }} a {{ firstItem.max }} opções</div>

          <div>
            <b-button type="is-light" :loading="loading" @click="saveForm(true)">Não responder</b-button>
            <b-button type="is-light" :loading="loading" @click="saveForm(false)">Guardar</b-button>
          </div>
        </form>
      </section>
    </div>
  </b-modal>
</template>

<script>
import ServiceForms from '@/fw-modules/fw-core-vue/forms/services/ServiceForms'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  props: {
    form: Object,
  },

  data() {
    return {
      loading: false,
      selected: [],
      errorMessage: null,
    }
  },

  computed: {
    firstItem() {
      return this.form.schema[0]
    },
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    shouldDisable(optionId) {
      return this.selected.length == this.firstItem.max && !this.selected.includes(optionId)
    },

    async saveForm(ignore = false) {
      this.loading = true

      try {
        if (ignore) {
          await ServiceForms.setNoAnswer(this.form.key)
        } else {
          const data = { 0: this.selected }
          await ServiceForms.setAnswer(this.form.key, data)
        }

        this.$emit('remove-form', this.form.key)
      } catch (error) {
        this.errorMessage = utils.errors(error).getTranslated()
        if (!this.errorMessage) {
          throw error
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
