<template>
  <fw-panel :title="'Nova página'">
    <div class="flex flex-col">
      <b-field label="Título">
        <b-input v-model="title"></b-input>
      </b-field>
      <b-field label="Idioma principal" class="mt-1">
        <div>
          <b-radio v-model="language" name="language" native-value="pt"> Português </b-radio>
          <b-radio v-model="language" name="language" native-value="en"> Inglês </b-radio>
        </div>
      </b-field>
      <div class="text-sm">Poderá acrescentar mais idiomas após criação do documento.</div>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="cancel()">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button
        :type="!allowCreate || loading ? 'disabled' : 'primary'"
        :disable="!allowCreate || loading"
        class="w-32"
        @click.native="create()"
      >
        {{ 'Criar' }}
      </fw-button>
    </div>
  </fw-panel>
</template>

<script>
import ServicePages from '../../services/ServicePages'
export default {
  name: 'ModalNewPage',
  props: {
    context: {
      type: String,
      required: true,
    },
    contextKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      language: 'pt',
      loading: false,
    }
  },
  computed: {
    allowCreate() {
      return this.title.length > 0
    },
  },
  methods: {
    async create() {
      //verify name
      if (this.title.length == 0) {
        return
      }
      this.loading = true
      try {
        let result = await ServicePages.createPage({
          language: this.language,
          title: this.title,
          context: this.context,
          context_key: this.contextKey,
        })
        //console.log(result)
        this.$router.push({ name: 'content-pages-editor', params: { key: result.page.key } })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    cancel() {
      if (!this.loading) {
        this.$emit('close')
      }
    },
  },
}
</script>
