var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fw-button',{staticClass:"flex items-center gap-1 text-primary",class:{
    'rounded-full': _vm.type === 'rounded',
    'w-full': _vm.expanded,
    'rounded-lg': _vm.type !== 'rounded',
    'py-3': _vm.size === 'lg',
    'py-1.5': _vm.size !== 'lg',
    'mr-2': !_vm.noMargin,
  },attrs:{"label":"Transferir","type":"transparent"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.download.apply(null, arguments)}}},[_c('fw-icon-download',{staticClass:"w-5 h-5 mr-0.5"}),(_vm.customLabel)?_c('span',[_vm._v(_vm._s(_vm.customLabel))]):(_vm.expanded && _vm.withLabel)?_c('span',[_vm._v(_vm._s(_vm.$t('download')))]):(_vm.size !== 'sm' && !_vm.expanded && _vm.withLabel)?_c('span',{staticClass:"hidden lg:flex"},[_vm._v(_vm._s(_vm.$t('download')))]):_vm._e(),(_vm.downloadTotalSize > 0 && _vm.size !== 'sm')?_c('span',{staticClass:"font-semibold ml-0.5 text-xs mt-0.5"},[_vm._v(_vm._s(_vm._f("bytesToString")(_vm.downloadTotalSize)))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }