<template>
  <div>
    <b>{{ title || stats.title }}:</b> {{ stats.count }}
    <div v-if="stats.keys && stats.keys.length">
      <div v-for="userKey in stats.keys" :key="userKey">
        <UserAvatar size="md" :user="users[userKey]" />
        {{ users[userKey].name }}
      </div>
    </div>
    <br /><br />
  </div>
</template>

<script>
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'

export default {
  components: {
    UserAvatar,
  },

  props: {
    title: String,
    stats: Object,
    users: {
      type: Object,
      default: null,
    },
  },
}
</script>
