import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import querystring from 'querystring'
import Api from '@/fw-modules/fw-core-vue/api/Api'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import store from '@/store'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlForms
    api.defaults.quietly = true
    return api
  },

  async getForms() {
    const response = await this.base().get('/v1/forms')

    for (let form of response.data) {
      form.created_date_obj = Dates.build(form.created_date)
    }

    response.data.sort((a, b) => {
      if (a.created_date_obj < b.created_date_obj) return 1
      else return -1
    })

    return response.data
  },
  async getForm(key) {
    const response = await this.base().get(`/v1/form/${key}`)
    return response.data
  },
  async addForm(data) {
    const response = await this.base().post('/v1/forms', data)
    return response.data
  },
  async updateForm(key, data) {
    const response = await this.base().post(`/v1/form/${key}`, data)
    return response.data
  },
  async deleteForm(key) {
    await this.base().delete(`/v1/form/${key}`)
  },

  async allAnswerInstances(keys) {
    const data = {
      paramsSerializer: params => {
        return querystring.stringify(params)
      },
      params: {
        key: keys,
      },
    }

    const response = await this.base().get('/v1/instances', data)
    return response.data
  },
  async setNoAnswer(key) {
    const response = await this.base().post(`/v1/instance/${key}/answer`, { ignore: true })
    return response.data
  },
  async setAnswer(key, data) {
    const response = await this.base().post(`/v1/instance/${key}/answer`, data)
    return response.data
  },

  async getInstance(key) {
    const response = await this.base().get(`/v1/instance/${key}`)
    return response.data
  },

  async getInstanceByShortCodeKey(key) {
    const response = await this.base().get(`/v1/live/digitalpad/instance/${key}`)
    return response.data
  },

  async updateInstance(key, data) {
    const response = await this.base().post(`/v1/instance/${key}`, data)
    return response.data
  },
  async deleteInstance(key) {
    await this.base().delete(`/v1/instance/${key}`)
  },

  async getPublicInstances(keys) {
    const config = { ignoreError: true }
    const data = {
      paramsSerializer: params => {
        return querystring.stringify(params)
      },
      params: { key: keys },
    }

    const response = await this.base(config).get(`/v1/public/instances`, data)
    if (response && response.data) return response.data
    else return {}
  },

  createSubscription(key, instance) {
    const subscriptionName = `Form-${key}`
    const cls = {
      key: key,
      instance: instance,

      async subscribe() {
        const data = {
          application: 'forms',
          code: 'subscribe_instance',
          key: cls.key,
        }

        store.commit('sendWSMessage', data)
        console.debug(`Form instance ${key} subscribed`)
      },
      async WSMessages(messages) {
        if (messages.formAnswer) {
          for (let message of messages.formAnswer) {
            if (message.instance_key === cls.key) {
              const delta = message.delta
              const user = delta.user

              cls.instance.users_count += 1
              if (user) {
                cls.instance.users[user.key] = user
              }

              if (delta.no_answer) {
                cls.instance.no_answer.count += delta.no_answer
                if (user) {
                  cls.instance.no_answer.keys.push(user.key)
                }
              }

              if (delta.answers) {
                const instanceRef = cls.instance.answers[0]
                const firstAnswer = delta.answers[0]
                if (firstAnswer.none) {
                  instanceRef.none.count += firstAnswer.none
                  if (user) {
                    instanceRef.none.keys.push(user.key)
                  }
                }

                if (firstAnswer.options) {
                  const instanceOptionsRef = instanceRef.options
                  for (let [optionId, inc] of Object.entries(firstAnswer.options)) {
                    instanceOptionsRef[optionId].count += inc
                    if (user) {
                      instanceOptionsRef[optionId].keys.push(user.key)
                    }
                  }
                }
              }
            }
          }
        }
      },
      destroy() {
        const data = {
          application: 'forms',
          code: 'unsubscribe_instance',
          key: key,
        }

        store.commit('sendWSMessage', data)
        console.debug(`Form instance ${key} unsubscribed`)

        store.commit('unsubscribeWS', { code: 'ws-reconnect', name: subscriptionName })
        store.commit('unsubscribeWS', { code: 'forms', name: subscriptionName })
      },
    }

    store.commit('subscribeWS', { code: 'ws-reconnect', name: subscriptionName, callback: cls.subscribe })
    store.commit('subscribeWS', { code: 'forms', name: subscriptionName, callback: cls.WSMessages })
    cls.subscribe()
    return cls
  },
}
