import { render, staticRenderFns } from "./PanelBucket.vue?vue&type=template&id=564fb8b6&scoped=true&"
import script from "./PanelBucket.vue?vue&type=script&lang=js&"
export * from "./PanelBucket.vue?vue&type=script&lang=js&"
import style0 from "./PanelBucket.vue?vue&type=style&index=0&id=564fb8b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "564fb8b6",
  null
  
)

export default component.exports