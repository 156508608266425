<template>
  <div class="flex h-full w-full overflow-y-scroll bg-gray-50">
    <div v-if="view == 'list'" class="flex-1 p-3 md:p-4 lg:p-8 xl:p-12 lg:max-w-screen-lg mx-auto w-full">
      <div v-if="!forms.length" class="p-4 flex flex-col items-center justify-center">
        <fw-heading size="h1" class="mb-8 font-black flex items-center">
          <svg
            class="w-10 h-10 inline-flex fill-current mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0L24 0 24 24 0 24z" />
            <path
              d="M17 2v2h3.007c.548 0 .993.445.993.993v16.014c0 .548-.445.993-.993.993H3.993C3.445 22 3 21.555 3 21.007V4.993C3 4.445 3.445 4 3.993 4H7V2h10zM7 6H5v14h14V6h-2v2H7V6zm2 10v2H7v-2h2zm0-3v2H7v-2h2zm0-3v2H7v-2h2zm6-6H9v2h6V4z"
            />
          </svg>
          Quizzes
        </fw-heading>
        <div class="text-center">
          <fw-button v-if="forms.length" type="regular" size="md" @click.native="loadForm()">
            Criar novo
          </fw-button>
          <div class="text-xl font-bold">Precisa de ajuda a criar o seu primeiro quiz?</div>
          <div class="text-sm">Para ajudar a começar, consulte o nosso tutorial vídeo.</div>
          <button
            class="mt-8 inline-flex w-40 h-24 border-2 rounded-xl border-black flex flex-col font-bold text-sm items-center justify-center"
            aria-label="Ver tutorial vídeo"
          >
            <span class="leading-8">Tutorial Quiz</span>
            <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM10.622 8.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div v-else>
        <fw-block-panel-header title="Quizzes">
          <template #toolbar>
            <fw-button v-if="forms.length" type="link" size="lg" @click.native="loadForm()">
              Criar novo
            </fw-button>
          </template>
        </fw-block-panel-header>

        <div>
          <fw-heading size="h3" class="flex items-center mb-4 text-gray-500">
            Recentes <span class="text-xs ml-2">{{ forms.length }}</span>
          </fw-heading>

          <DashboardQuizInstance class="p-4 shadow-md" />
          <DashboardQuizInstance class="p-4 shadow-md" />
        </div>

        <fw-heading size="h3" class="text-gray-500 mb-2 mt-10">Números</fw-heading>
        <div class="grid gap-6 mb-8 grid-cols-2 lg:grid-cols-4">
          <!-- Card -->
          <div class="flex items-center p-4 bg-white rounded-xl shadow-md">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100 dark:bg-orange-500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0L24 0 24 24 0 24z" />
                <path
                  d="M17 2v2h3.007c.548 0 .993.445.993.993v16.014c0 .548-.445.993-.993.993H3.993C3.445 22 3 21.555 3 21.007V4.993C3 4.445 3.445 4 3.993 4H7V2h10zM7 6H5v14h14V6h-2v2H7V6zm2 10v2H7v-2h2zm0-3v2H7v-2h2zm0-3v2H7v-2h2zm6-6H9v2h6V4z"
                />
              </svg>
            </div>
            <div>
              <fw-label>Quizzes</fw-label>
              <p class="text-lg font-semibold">
                2
              </p>
            </div>
          </div>
          <!-- Card -->
          <div class="flex items-center p-4 bg-white rounded-xl shadow-md">
            <div class="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M5 12.5c0 .313.461.858 1.53 1.393C7.914 14.585 9.877 15 12 15c2.123 0 4.086-.415 5.47-1.107 1.069-.535 1.53-1.08 1.53-1.393v-2.171C17.35 11.349 14.827 12 12 12s-5.35-.652-7-1.671V12.5zm14 2.829C17.35 16.349 14.827 17 12 17s-5.35-.652-7-1.671V17.5c0 .313.461.858 1.53 1.393C7.914 19.585 9.877 20 12 20c2.123 0 4.086-.415 5.47-1.107 1.069-.535 1.53-1.08 1.53-1.393v-2.171zM3 17.5v-10C3 5.015 7.03 3 12 3s9 2.015 9 4.5v10c0 2.485-4.03 4.5-9 4.5s-9-2.015-9-4.5zm9-7.5c2.123 0 4.086-.415 5.47-1.107C18.539 8.358 19 7.813 19 7.5c0-.313-.461-.858-1.53-1.393C16.086 5.415 14.123 5 12 5c-2.123 0-4.086.415-5.47 1.107C5.461 6.642 5 7.187 5 7.5c0 .313.461.858 1.53 1.393C7.914 9.585 9.877 10 12 10z"
                />
              </svg>
            </div>
            <div>
              <fw-label>Instâncias</fw-label>
              <p class="text-lg font-semibold">
                23
              </p>
            </div>
          </div>
          <!-- Card -->
          <div class="flex items-center p-4 bg-white rounded-xl shadow-md">
            <div class="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M5.455 15L1 18.5V3a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v12H5.455zm-.692-2H16V4H3v10.385L4.763 13zM8 17h10.237L20 18.385V8h1a1 1 0 0 1 1 1v13.5L17.545 19H9a1 1 0 0 1-1-1v-1z"
                />
              </svg>
            </div>
            <div>
              <fw-label>Respostas</fw-label>
              <p class="text-lg font-semibold">
                376
              </p>
            </div>
          </div>
          <!-- Card -->
          <div class="flex items-center p-4 bg-white rounded-xl shadow-md">
            <div class="p-3 mr-4 text-teal-500 bg-teal-100 rounded-full dark:text-teal-100 dark:bg-teal-500">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H2zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm8.284 3.703A8.002 8.002 0 0 1 23 22h-2a6.001 6.001 0 0 0-3.537-5.473l.82-1.824zm-.688-11.29A5.5 5.5 0 0 1 21 8.5a5.499 5.499 0 0 1-5 5.478v-2.013a3.5 3.5 0 0 0 1.041-6.609l.555-1.943z"
                />
              </svg>
            </div>
            <div>
              <fw-label>Participantes únicos</fw-label>
              <p class="text-lg font-semibold">
                35
              </p>
            </div>
          </div>
        </div>

        <fw-heading size="h3" class="text-gray-500 mb-2 flex items-center mt-10">
          Quizzes
          <span class="text-xs ml-2">{{ forms.length }}</span>
        </fw-heading>
        <div class="p-4 bg-white rounded-xl shadow-md hover:shadow-xl">
          <div class="pb-4 grid grid-flow-col auto-cols-min gap-4">
            <fw-button type="link" size="xs">
              Todos
            </fw-button>
            <fw-button type="link" size="xs">
              Abertos
            </fw-button>
            <fw-button type="link" size="xs">
              Fechados
            </fw-button>
          </div>
          <div
            v-for="form in forms"
            :key="form.key"
            class="p-3 rounded-xl flex items-center mb-2 bg-white cursor-pointer hover:bg-gray-100"
            @click="loadForm(form.key)"
          >
            <div class="text-gray-500 mr-4">
              <svg
                class="w-6 h-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0L24 0 24 24 0 24z" />
                <path
                  d="M17 2v2h3.007c.548 0 .993.445.993.993v16.014c0 .548-.445.993-.993.993H3.993C3.445 22 3 21.555 3 21.007V4.993C3 4.445 3.445 4 3.993 4H7V2h10zM7 6H5v14h14V6h-2v2H7V6zm2 10v2H7v-2h2zm0-3v2H7v-2h2zm0-3v2H7v-2h2zm6-6H9v2h6V4z"
                />
              </svg>
            </div>
            <div class="flex-1">
              <div class="flex items-center leading-6">
                <div class="font-semibold mr-2">{{ form.title }}</div>
                <div class="text-sm text-gray-500">{{ form.created_date | formatDate }}</div>
              </div>
              <div class="text-sm">
                <div>{{ getFormStatus(form.status) }}</div>
                <div v-if="form.is_owner" class="flex-none hidden">Dono</div>
              </div>
            </div>
            <fw-button label="Abrir" type="link">
              <svg
                class="fill-current w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
              </svg>
            </fw-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex-1 p-3 md:px-4 lg:px-8 xl:px-12">
      <span @click="view = 'list'">Ir para a listagem</span>
      <div class="px-6 py-4 flex-1 overflow-y-scroll">
        <div class="inner-panel is-flex-grow has-background-white is-height-auto">
          <div v-if="formData.status == 'draft'">
            <h3 v-if="!formData.key">Adicionar</h3>

            <form @submit.prevent="saveForm">
              <b-field
                label="Título"
                :type="{ 'is-danger': formErrors.title }"
                :message="formErrors.title"
                class="is-marginless"
              >
                <b-input v-model="formData.title" expanded></b-input>
              </b-field>
              <b-field
                label="Pergunta"
                :type="{ 'is-danger': formErrors.item_title }"
                :message="formErrors.item_title"
                class="is-marginless"
              >
                <b-input v-model="formData.item_title" expanded></b-input>
              </b-field>

              <br /><br />
              <b-field
                label="Opções"
                :type="{ 'is-danger': formErrors.item_options }"
                :message="formErrors.item_options"
                class="is-marginless"
              >
                <div v-for="(option, index) in formData.item_options" :key="index">
                  <b-field
                    :type="{ 'is-danger': optionFormError(index, 'title') }"
                    :message="optionFormError(index, 'title')"
                    class="is-marginless"
                  >
                    <b-input v-model="option.title" size="is-medium"></b-input>
                  </b-field>

                  <b-button type="is-light" @click="changeOptionPosition(index, -1)">Up</b-button>
                  <b-button type="is-light" @click="changeOptionPosition(index, 1)">Down</b-button>
                  <b-button type="is-light" @click="formData.item_options.splice(index, 1)">Remover</b-button>
                </div>
                <b-button type="is-light" @click="addOption">Adicionar opção</b-button>
              </b-field>

              <br /><br /><br />
              <b-field
                label="Número de opções"
                :type="{ 'is-danger': formErrors.item_min_max }"
                :message="formErrors.item_min_max"
              >
                <b-select v-model="formData.item_min_max">
                  <option v-for="option in Object.entries(MIN_MAX_OPTIONS)" :key="option[0]" :value="option[0]">
                    {{ option[1] }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                v-if="formData.item_min_max == 'custom'"
                label="Número mínimo"
                :type="{ 'is-danger': formErrors.item_min }"
                :message="formErrors.item_min"
              >
                <b-numberinput v-model="formData.item_min" min="0" :max="formData.item_max"></b-numberinput>
              </b-field>
              <b-field
                v-if="formData.item_min_max == 'custom'"
                label="Número máximo"
                :type="{ 'is-danger': formErrors.item_max }"
                :message="formErrors.item_max"
              >
                <b-numberinput
                  v-model="formData.item_max"
                  :min="formData.item_min"
                  :max="formData.item_options.length || 1"
                ></b-numberinput>
              </b-field>

              <br />
              <b-field label="Anónimo" :type="{ 'is-danger': formErrors.anonymous }" :message="formErrors.anonymous">
                <b-checkbox v-model="formData.anonymous"></b-checkbox>
              </b-field>

              <b-field class="has-margin-top">
                <b-button v-if="formData.key" type="is-light" :loading="loading" @click="saveForm">Guardar</b-button>
                <b-button v-else type="is-light" :loading="loading" @click="saveForm">Adicionar</b-button>
              </b-field>
            </form>
          </div>
          <div v-else>
            <b>Título:</b> {{ formData.title }} <br /><b>Pergunta:</b> {{ formData.item_title }}

            <br /><br /><b>Opções:</b>
            <div v-for="option in formData.item_options" :key="option.id">{{ option.title }}</div>

            <br /><br />
            <b>Número de opções:</b> {{ MIN_MAX_OPTIONS[formData.item_min_max] }}
            <span v-if="formData.item_min_max == 'custom'"
              >min:{{ formData.item_min }} max:{{ formData.item_max }}</span
            >

            <br /><b>Anónimo?</b> <span v-if="formData.anonymous">Sim</span><span v-else>Não</span>
          </div>

          <div v-if="formData.key">
            <br /><br />
            <b-button v-if="formData.permissions.delete" type="is-light" :loading="loading" @click="deleteForm">
              Remover
            </b-button>
            <b-button
              v-if="formData.permissions.close"
              type="is-light"
              :loading="loading"
              @click="setFormStatus('close')"
            >
              Close
            </b-button>
            <b-button
              v-if="formData.permissions.reopen"
              type="is-light"
              :loading="loading"
              @click="setFormStatus('reopen')"
            >
              Re-open
            </b-button>
          </div>

          <div v-if="allowedToStart">
            <br /><br />
            <b-button type="is-light" :loading="loading" :disabled="!meetingKey" @click="startForm">Start</b-button>
            <div v-if="!meetingKey">Para iniciar o Quiz é necessário estar numa meeting</div>
          </div>

          <div v-if="formData.instances">
            <div v-for="instance in formData.instances" :key="instance.key">
              <div><br />{{ instance.relation.title }} ({{ instance.status }})</div>
              <br /><small>{{ instance.start_date }}</small>

              <br />
              <b-button
                v-if="instance.status == 'active' && instance.relation.key == instanceKey"
                type="is-light"
                :loading="loading"
                @click="stopForm(instance)"
              >
                Stop
              </b-button>
              <div v-if="instanceDetails && instance.key == instanceDetails.key && instance.status != 'active'">
                <b-button type="is-light" :loading="loading" @click="deleteInstance(instanceDetails.key)">
                  Remover
                </b-button>
                <b-button type="is-light" :loading="loading" @click="closeInstanceDetails()">
                  Fechar
                </b-button>
              </div>
              <b-button v-else type="is-light" :loading="loading" @click="openInstanceDetails(instance.key)">
                Abrir
              </b-button>

              <div v-if="instanceDetails && instanceDetails.key == instance.key">
                <div v-if="instanceDetails.status == 'ended'">
                  <div class="has-margin-top">
                    <div class="label">Permissões</div>
                    <div class="is-flex is-flex-align-center">
                      <b-select
                        v-model="instancePermission"
                        :loading="loading"
                        placeholder="Escolha"
                        :icon="instancePermission == 'public' ? 'eye' : 'lock'"
                      >
                        <option value="private">Privado</option>
                        <option value="public">Público</option>
                      </b-select>
                      <div class="has-text-small has-text-muted has-margin-left">
                        <span v-if="instancePermission == 'public'"
                          >O Quiz fica acessível a qualquer pessoa com quem partilhe o link.</span
                        >
                      </div>
                    </div>
                  </div>
                  <div v-if="instancePermission == 'public'" class="has-margin-top-medium">
                    <div class="label is-marginless">Link do Quiz</div>
                    <div class="is-flex is-flex-align-center">
                      <a target="_blank" :href="instanceDetails.url" class="has-margin-right-small">
                        <v-clamp autoresize :max-lines="1">{{ instanceDetails.url }}</v-clamp>
                      </a>
                      <b-button type="is-light is-small" @click="toClipboard(instanceDetails.url)"
                        >Copiar link</b-button
                      >
                    </div>
                  </div>
                </div>

                <h1>Stats</h1>
                <b>Users:</b> {{ instanceDetails.users_count }}
                <ItemStats :title="'Não respondeu'" :stats="instanceDetails.no_answer" :users="instanceDetails.users" />
                <ItemStats
                  :title="'Sem resposta'"
                  :stats="instanceDetails.answers[0].none"
                  :users="instanceDetails.users"
                />
                <div v-for="option in formData.item_options" :key="option.id">
                  <ItemStats
                    :title="option.title"
                    :stats="instanceDetails.answers[0].options[option.id]"
                    :users="instanceDetails.users"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceForms from '@/fw-modules/fw-core-vue/forms/services/ServiceForms'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import ItemStats from '@/fw-modules/fw-core-vue/forms/components/ItemStats'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import DashboardQuizInstance from '@/fw-modules/fw-core-vue/forms/components/dashboards/DashboardQuizInstance'

const MIN_MAX_OPTIONS = {
  one: 'Apenas uma',
  all: 'Livre',
  custom: 'Personalizado',
}

export default {
  components: {
    ItemStats,
    DashboardQuizInstance,
  },

  props: {
    meetingKey: {
      type: String,
      default: null,
    },
    instanceKey: {
      type: String,
      default: null,
    },
  },

  data() {
    this.getForms()
    return {
      loading: false,
      forms: [],
      formData: null,
      formErrors: {},
      instanceDetails: null,
      instanceSubscription: null,
      instancePermission: 'private',
      MIN_MAX_OPTIONS: MIN_MAX_OPTIONS,
      view: 'list',
    }
  },

  computed: {
    allowedToStart() {
      if (!this.formData || !this.formData.key || !this.formData.permissions.start) {
        return false
      }

      if (this.formData.status == 'active') {
        for (let instance of this.formData.instances) {
          if (
            instance.status == 'active' &&
            instance.relation.type == 'meeting' &&
            instance.relation.key == this.instanceKey
          ) {
            return false
          }
        }
      }

      return true
    },
  },

  watch: {
    instancePermission(value) {
      if (this.instanceDetails && value !== this.getPermissionsKey(this.instanceDetails)) {
        this.setInstancePermissions(this.instanceDetails, value)
      }
    },
  },

  beforeDestroy() {
    this.destroyInstanceSubscription()
  },

  methods: {
    async getForms() {
      this.setEmptyForm()
      this.forms = await ServiceForms.getForms()
    },
    optionFormError(index, key) {
      return this.formErrors[`item_options.${index}.${key}`]
    },
    loadFirst() {
      if (!this.forms.length) {
        this.setEmptyForm()
      } else {
        for (let form of this.forms) {
          if (form.status === 'active') {
            this.loadForm(form.key)
            return
          }
        }

        this.loadForm(this.forms[0].key)
      }
    },
    setEmptyForm() {
      this.setForm({
        key: null,
        status: 'draft',
        title: '',
        anonymous: true,
        schema: [{ title: '', options: [], min: 0, max: 1 }],
        permissions: {},
        instances: [],
      })
    },
    setForm(form) {
      if (this.formData && this.formData.key !== form.key) {
        this.closeInstanceDetails()
      }

      const firstItem = form.schema[0]

      let item_min_max = 'custom'
      if (!form.key || (firstItem.min == 1 && firstItem.max == 1)) {
        item_min_max = 'one'
      } else if (firstItem.min == 0 && firstItem.max == firstItem.options.length) {
        item_min_max = 'all'
      }

      this.formData = {
        key: form.key,
        status: form.status,
        title: form.title,
        anonymous: form.anonymous,
        item_title: firstItem.title,
        item_options: firstItem.options,
        item_min: firstItem.min,
        item_max: firstItem.max,
        item_min_max: item_min_max,

        permissions: form.permissions,
        instances: form.instances || [],
      }

      if (form.key) {
        for (let item of this.forms) {
          if (item.key === form.key) {
            Object.assign(item, form)
            break
          }
        }
      }
    },
    async loadForm(key = null) {
      if (!key) {
        this.setEmptyForm()
      } else {
        const form = await ServiceForms.getForm(key)
        this.setForm(form)

        for (let instance of form.instances) {
          if (instance.status === 'active') {
            this.openInstanceDetails(instance.key)
            break
          }
        }
      }
      this.formErrors = {}
      this.view = 'details'
    },

    getFormStatus(status) {
      if (status === 'draft') {
        return 'Draft'
      } else if (status === 'active') {
        return 'Activo'
      } else if (status === 'closed') {
        return 'Fechado'
      }
    },

    addOption() {
      this.formData.item_options.push({ title: '' })
    },
    changeOptionPosition(index, increment) {
      const maxIndex = this.formData.item_options.length - 1
      if (maxIndex > 0) {
        const option = this.formData.item_options[index]

        let newIndex = index + increment
        if (newIndex < 0) {
          this.formData.item_options.splice(index, 1)
          this.formData.item_options.push(option)
        } else if (newIndex > maxIndex) {
          this.formData.item_options.splice(index, 1)
          this.formData.item_options.unshift(option)
        } else {
          this.formData.item_options.splice(index, 1)
          this.formData.item_options.splice(newIndex, 0, option)
        }
      }
    },
    async saveForm() {
      this.loading = true

      if (this.formData.item_min_max === 'one') {
        this.formData.item_min = 1
        this.formData.item_max = 1
      } else if (this.formData.item_min_max === 'all') {
        this.formData.item_min = 0
        this.formData.item_max = this.formData.item_options.length
      }

      const data = {
        title: this.formData.title,
        anonymous: this.formData.anonymous,
        item_title: this.formData.item_title,
        item_options: this.formData.item_options,
        item_min: this.formData.item_min,
        item_max: this.formData.item_max,
      }

      try {
        let response
        if (this.formData.key) {
          response = await ServiceForms.updateForm(this.formData.key, data)
        } else {
          response = await ServiceForms.addForm(data)
        }

        this.formErrors = utils.errors(response).extract()
        if (!Object.keys(this.formErrors).length) {
          if (!this.formData.key) {
            this.forms.unshift(response)
          }
          this.setForm(response)
        }
      } catch (error) {
        this.formErrors = utils.errors(error).extract()
      } finally {
        this.loading = false
      }

      if (this.formErrors.item_min) {
        this.formErrors.item_min_max = this.formErrors.item_min
      } else if (this.formErrors.item_max) {
        this.formErrors.item_min_max = this.formErrors.item_max
      }
    },
    async deleteForm() {
      this.loading = true
      try {
        await ServiceForms.deleteForm(this.formData.key)
      } catch (error) {
        if (utils.errors(error).getKey() !== 'ItemNotFound') {
          throw error
        }
      } finally {
        this.loading = false
      }

      for (let index in this.forms) {
        if (this.forms[index].key === this.formData.key) {
          this.forms.splice(index, 1)
          break
        }
      }
      this.loadFirst()
    },

    async setFormStatus(status) {
      this.loading = true
      try {
        const response = await ServiceForms.updateForm(this.formData.key, { status: status })
        this.formErrors = utils.errors(response).extract()
        this.setForm(response)
      } catch (error) {
        this.formErrors = utils.errors(error).extract()
      } finally {
        this.loading = false
      }
    },

    async startForm() {
      this.loading = true
      try {
        const form = await ServiceMeetings.startForm(this.meetingKey, this.formData.key)
        const instance = form.instance
        delete form.instance

        form.instances = this.formData.instances
        form.instances.unshift(instance)
        this.setForm(form)
        this.openInstanceDetails(instance.key)
      } finally {
        this.loading = false
      }
    },
    async stopForm(instance) {
      this.loading = true
      try {
        const form = await ServiceMeetings.stopForm(this.meetingKey, instance.key)
        Object.assign(instance, form.instance)
        delete form.instance

        form.instances = this.formData.instances
        this.setForm(form)

        if (this.instanceDetails && this.instanceDetails.key == instance.key) {
          this.instanceDetails.status = 'ended'
        }
      } finally {
        this.loading = false
      }
    },

    async openInstanceDetails(key) {
      this.loading = true
      try {
        this.instanceDetails = await ServiceForms.getInstance(key)
        this.instancePermission = this.getPermissionsKey(this.instanceDetails)
        if (this.instanceDetails.status == 'active') {
          this.instanceSubscription = ServiceForms.createSubscription(key, this.instanceDetails)
        } else {
          this.destroyInstanceSubscription()
        }
      } finally {
        this.loading = false
      }
    },
    async closeInstanceDetails() {
      this.instanceDetails = null
      this.destroyInstanceSubscription()
    },
    destroyInstanceSubscription() {
      if (this.instanceSubscription) {
        this.instanceSubscription.destroy()
        this.instanceSubscription = null
      }
    },
    async deleteInstance(key) {
      this.loading = true
      try {
        await ServiceForms.deleteInstance(key)

        if (this.instanceDetails && this.instanceDetails.key == key) {
          this.closeInstanceDetails()
        }

        for (let i in this.formData.instances) {
          if (this.formData.instances[i].key == key) {
            this.formData.instances.splice(i, 1)
          }
        }
      } finally {
        this.loading = false
      }
    },

    getPermissionsKey(instance) {
      return instance.is_public ? 'public' : 'private'
    },
    async setInstancePermissions(instance, value) {
      const data = { is_public: value == 'public' }

      this.loading = true
      try {
        await ServiceForms.updateInstance(instance.key, data)
        instance.is_public = data.is_public
      } finally {
        this.loading = false
      }
    },
    toClipboard(url) {
      this.$copyText(url).then(() => {
        this.$buefy.toast.open({
          duration: 3000,
          message: `Endereço copiado`,
          position: 'is-top',
        })
      })
    },
  },
}
</script>
