import CoreMutations from '@/fw-modules/fw-core-vue/store/mutations'
import Vue from 'vue'

export default Object.assign(CoreMutations, {
  setSavingData(state, data) {
    state.isSaving = data
  },
  setUserPermissions(state, data) {
    state.session.permissions = data.permissions
  },
  setUserPermissionsLoaded(state, loaded = false) {
    state.session.permissionsLoaded = loaded
  },
  setUserStats(state, data) {
    Vue.set(state.session, 'procedureStats', data)
  },
  setRecentOpened(state, data) {
    if (data.length > 15) {
      data.pop()
    }

    localStorage.setItem('session.recent', JSON.stringify(data))

    state.recentOpened = data
  },
  setSeeAsPublic(state, data) {
    state.session.seeAsPublic = !!data
  },
  saveCurrentScrollPos(state, data) {
    state.tmbScrollPos[data.key] = data.pos
  },
  deleteCurrentScrollPos(state, data) {
    delete state.tmbScrollPos[data]
  },
})
