import CoreGetters from '@/fw-modules/fw-core-vue/store/getters'
import Vue from 'vue'
import messages from '@/i18n/index.js'

export default Object.assign(CoreGetters, {
  userPermissions(state) {
    return {
      isProcedureManager: Array.isArray(state.session.permissions)
        ? state.session.permissions.includes('procedure-manager')
        : false,
      isAdmin: Array.isArray(state.session.permissions) ? state.session.permissions.includes('admin') : false,
      isManager: Array.isArray(state.session.permissions) ? state.session.permissions.includes('manager') : false,
      isJury: Array.isArray(state.session.permissions) ? state.session.permissions.includes('jury') : false,
    }
  },
  getProcedureTitle: state => (procedure, short = false) => {
    if (!procedure) {
      return ''
    }
    if (!procedure.number_of_seats) {
      return procedure.professional_category_title || ''
    }

    let curr_locale = messages[state.language]

    const seats = procedure.number_of_seats_text
      ? Vue.filter('capitalize')(procedure.number_of_seats_text)
      : procedure.number_of_seats
      ? procedure.number_of_seats
      : ''

    let message = `${seats} `
    if (procedure.type === 'scholarships') {
      message +=
        procedure.number_of_seats > 1 ? curr_locale.vacancies.split(' | ')[1] : curr_locale.vacancies.split(' | ')[0]
    } else {
      message +=
        procedure.number_of_seats > 1 ? curr_locale.position.split(' | ')[1] : curr_locale.position.split(' | ')[0]
    }

    if (!short && procedure.type !== 'scholarships') message += ' ' + curr_locale.procedure.ofWork

    if (procedure.type == 'researchers' && procedure.category_type_title) {
      message += ` ${curr_locale.to} ${Vue.filter('capitalize')(curr_locale.researcher)} ${
        procedure.category_type_title
      }`
    } else if (procedure.professional_category_title) {
      message += ` ${curr_locale.to} ${procedure.professional_category_title}`
    }

    return message
  },
  getUserStats(state) {
    return state.session.procedureStats
  },
  getUserPermissions(state) {
    return state.session.permissions
  },
  isPermissionsLoaded(state) {
    return state.session.permissionsLoaded
  },
  getRecentOpened(state) {
    let data = state.recentOpened
    if (!data || !data.length) {
      data = JSON.parse(localStorage.getItem('session.recent'))
      if (!data || !data.length) return []
    }
    return data
  },
  getSeeAsPublic(state) {
    return state.session.seeAsPublic
  },
})
