<template>
  <BlockUserDetails v-if="userDetails" :user="user" :loading="loading || loadingPermissions">
    <template #more-meta>
      <div class="flex flex-col gap-3">
        <div>
          <fw-label marginless>{{ $t('status') }}</fw-label>
          <div :class="{ 'text-primary font-semibold': user.active }">
            {{ user.active ? $t('active') : $t('inactive') }}
          </div>
        </div>
        <div v-if="user.permissions">
          <fw-label marginless>{{ $t('permissions') }}</fw-label>
          <div :class="{ 'text-sm text-gray-500': !user.permissions.length }">
            {{ user.permissions.length ? user.permissions.join(', ') : $t('noPermissions') }}
          </div>
        </div>
        <div>
          <fw-label marginless>{{ $t('organizations') }}</fw-label>
          <div class="uppercase">
            {{
              user.organizations && user.organizations.length ? user.organizations.join(', ') : $t('noOrganizations')
            }}
          </div>
        </div>
        <div>
          <fw-label marginless>{{ $t('firstAuth') }}</fw-label>
          <div v-if="user.first_login">{{ user.first_login | formatDateTime }}</div>
          <div v-else class="text-gray-500">{{ $t('noAuth') }}.</div>
        </div>
        <div>
          <fw-label marginless>{{ $t('invitesData') }}</fw-label>
          <div v-if="user.status === 'accepted'">{{ $t('inviteAccepted') }}.</div>
          <div v-else>
            <div v-if="user.invite_sent">{{ $t('inviteSentTo') }} {{ user.invited_date | formatDateTime }}.</div>
            <div v-else class="text-gray-500">{{ $t('inviteNotSent') }}</div>
          </div>
        </div>
      </div>
    </template>

    <div class="flex flex-col">
      <fw-panel
        v-if="showProcedures && !loadingProcedures && !detailsError"
        :loading="loadingProcedures"
        :title="$t('procedures')"
        :counter="procedures.data.length"
        :counter-total="procedures.total"
        boxed
        class="my-3"
        custom-class="bg-gray-100 p-1.5"
        :see-more-path="
          procedures.data.length && procedures.data.length > 15 ? `/manage/procedures?user=${user.key}` : null
        "
        :see-more-title="procedures.data.length && procedures.data.length > 15 ? $t('seeMore') : null"
      >
        <div v-if="procedures.data.length">
          <RecordManageProcedure
            v-for="procedure in procedures.data"
            :key="procedure.key"
            :item="procedure"
            basic
            @open-procedure="openProcedure(procedure.key)"
          ></RecordManageProcedure>
        </div>
        <fw-panel-info v-else type="basic" class="opacity-70 p-2">{{ $t('noProcedures') }}.</fw-panel-info>
      </fw-panel>

      <fw-panel
        v-if="showActivity"
        :loading="loadingActivity"
        :title="$t('activity')"
        boxed
        class="my-3"
        custom-class="bg-gray-100 p-1.5"
        :see-more-path="activity.logs.length && activity.logs.length > 10 ? `/manage/activity?user=${user.key}` : null"
        :see-more-title="activity.logs.length && activity.logs.length > 10 ? $t('seeMore') : null"
        :subtitle="$t('lastRecords', { limit: activity.limit })"
      >
        <div v-if="!loadingActivity && activity.logs.length">
          <CardActivityLog
            v-for="log in activity.logs"
            :key="log.key"
            basic
            :row="false"
            :expandable="true"
            :log="log"
            :users="activity.users"
          ></CardActivityLog>
        </div>
        <fw-panel-info v-else type="basic" class="opacity-70 p-1">{{ $t('noActivity') }}.</fw-panel-info>
        <template #footer>
          <fw-tip v-if="activity.logs.length" class="opacity-80 p-1" marginless>
            {{ $t('checkRecordsAt') }}
            <a class="text-primary" href="/manage/activity">{{ $t('activity') }}.</a>
          </fw-tip>
        </template>
      </fw-panel>

      <fw-panel
        v-if="userDetails['checks']['can_edit_permissions']"
        :title="$t('security')"
        boxed="sm"
        custom-class="bg-gray-100"
        class="my-3"
        :loading="loadingPermissions"
        after-loading-checked
      >
        <div class="flex flex-col gap-1 text-sm">
          <fw-label marginless class="mb-2">{{ $t('applyPermissions') }}</fw-label>
          <b-checkbox
            v-for="(role, key) in roles"
            :key="key"
            v-model="selectedRoles"
            :disabled="loggedUser.key === user.key"
            :native-value="key"
            class="p-1"
            @input="savePermissions"
            >{{ role }}</b-checkbox
          >
        </div>
        <fw-tip v-if="loggedUser.key === user.key" marginless>{{ $t('cantEditPermissions') }}.</fw-tip>
      </fw-panel>

      <fw-panel :title="$t('operations')" class="my-3">
        <fw-panel-info v-if="loggedUser.key === user.key" clean size="sm">{{ $t('noOperations') }}.</fw-panel-info>
        <div v-else class="flex flex-col gap-2">
          <!-- Delete account -->
          <div class="flex flex-col gap-2 text-sm pb-2 border-b">
            <fw-label>{{ $t('deleteAccount') }}</fw-label>
            <div class="flex gap-5 justify-between items-center">
              <div v-if="userDetails['checks']['can_be_deleted']">{{ $t('cantDeleteAccount') }}.</div>
              <div v-else class="text-gray-500">
                <div class="text-red-400">{{ $t('accountCantBeDeleted') }}.</div>
                <fw-tip marginless class="mb-1"> {{ $t('toDeleteInfo') }}. </fw-tip>
              </div>
              <fw-button
                v-if="userDetails['checks']['can_be_deleted']"
                type="border-danger"
                :disabled="!userDetails['checks']['can_be_deleted']"
                class="flex items-center gap-2 font-semibold flex-shrink-0"
                label="Apagar"
                @click.native="deleteUser()"
              >
                <fw-icon-trash class="w-5 h-5" /> {{ $t('deleteThisAccount') }}
              </fw-button>
            </div>
          </div>
          <!-- Edit Email -->
          <div class="flex flex-col gap-2 text-sm pb-2 border-b">
            <fw-label marginless :loading="loadingUpdateUser" after-loading-checked>{{
              $t('editEmailAddress')
            }}</fw-label>
            <div v-if="canEditEmail" class="flex gap-5 justify-between items-center">
              <div class="flex-1">
                <b-input v-model="newUserEmail" expanded type="email" :has-counter="false" maxlength="150" />
              </div>
              <div>
                <fw-button
                  type="border-primary"
                  :disabled="!canEditEmail || !readyToEditEmail || loadingUpdateUser"
                  class="flex items-center gap-2 font-semibold flex-shrink-0"
                  label="Alterar"
                  @click.native="editEmail()"
                >
                  {{ $t('editEmail') }}
                </fw-button>
              </div>
            </div>
            <fw-panel-info v-else clean size="sm"> {{ $t('cantEditEmail') }}. </fw-panel-info>
          </div>
          <!-- Resend invitation -->
          <div class="flex flex-col gap-2 text-sm pb-2 border-b">
            <fw-label>{{ $t('invitation') }}</fw-label>
            <div v-if="!user.active" class="flex gap-5 justify-between items-center">
              <div v-if="canInviteAgain" class="flex-1">
                {{ $t('inviteAgain') }}.
                <fw-tip v-if="user.invite_sent && user.invite_email">
                  {{
                    $t('lastInvite', {
                      date: $options.filters.formatDateTime(user.invite_sent),
                      email: user.invite_email,
                    })
                  }}.
                </fw-tip>
              </div>
              <div v-else class="flex-1">
                <div class="text-red-400">{{ $t('cantSendInvite') }}.</div>
                <fw-tip>{{ $t('lastInviteWasSentIn') }}.</fw-tip>
              </div>
              <div class="flex-shrink-0">
                <fw-button
                  type="border-primary"
                  size="sm"
                  :disabled="loadingSendInvite || !canInviteAgain"
                  class="flex items-center gap-2 font-semibold flex-shrink-0"
                  label="Apagar"
                  @click.native="sendInvite()"
                >
                  {{ $t('resendinvite') }}
                </fw-button>
              </div>
            </div>
            <fw-panel-info v-else clean size="sm"> {{ $t('accountAlreadyActive') }}. </fw-panel-info>
          </div>
        </div>
      </fw-panel>
    </div>
  </BlockUserDetails>
</template>

<script>
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'
import CardActivityLog from '@/fw-modules/fw-core-vue/activity/components/cards/CardActivityLog'
import RecordManageProcedure from '@/components/records/RecordManageProcedure'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  name: 'UserDetails',
  components: { BlockUserDetails, CardActivityLog, RecordManageProcedure },
  props: {
    user: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data() {
    return {
      selectedRoles: [],
      roles: {
        manager: this.$t('roles.manager'),
        jury: this.$t('roles.jury'),
        'procedure-manager': this.$t('roles.procedure-manager'),
      },
      userDetails: null,
      newUserEmail: null,
      loading: true,
      loadingPermissions: false,
      loadingActivity: true,
      loadingProcedures: true,
      loadingUpdateUser: false,
      loadingSendInvite: false,
      savedPermissions: false,
      detailsError: false,
      activity: {
        logs: [],
        users: {},
        limit: 10,
      },
      procedures: {
        active_limit: 0,
        active_page: 1,
        data: [],
        total: 1,
        total_pages: 1,
      },
    }
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },
    loggedUser() {
      return this.$store.getters.getUser
    },
    canInviteAgain() {
      return (
        (this.user && !this.user.invite_sent) ||
        (this.user.invite_sent && Dates.nowDiff(this.user.invite_sent, 'hours') < 0)
      )
    },
    canEditEmail() {
      return this.userDetails && this.userDetails.checks.can_email_be_changed
    },
    readyToEditEmail() {
      // TODO: check backend to confirm that user can change this account email address
      return this.userDetails && this.newUserEmail && this.newUserEmail !== this.userDetails.email
    },

    userRoles() {
      return this.$store.getters.userPermissions
    },

    showActivity() {
      return this.userRoles.isManager || this.userRoles.isAdmin
    },

    showProcedures() {
      return this.userRoles.isManager || this.userRoles.isAdmin
    },
  },
  created() {
    this.getUserDetails()
    if (this.showActivity) this.getUserActivity()
    if (this.showProcedures) this.getUserProcedures()
    this.selectedRoles = this.user.permissions
  },
  methods: {
    async editEmail() {
      if (this.user.email === this.newUserEmail) return false

      const oldEmail = this.user.email
      this.loadingUpdateUser = true

      try {
        const result = await this.api.updateUser(this.user.key, { email: this.newUserEmail })
        const updatedEmail = result.email
        this.user.email = updatedEmail
        this.userDetails.email = updatedEmail
        this.userDetails.invite_email = updatedEmail
        this.newUserEmail = updatedEmail
        this.$emit('updated-user', this.user)

        if (oldEmail !== updatedEmail) {
          this.$buefy.dialog.confirm({
            title: this.$t('emailUpdated'),
            message: this.$t('emailUpdatedSendInvite'),
            confirmText: this.$t('sendInvite'),
            cancelText: this.$t('dontSend'),
            onConfirm: async () => {
              this.sendInvite()
            },
          })
        }
      } catch (error) {
        console.error(error)
      }
      setTimeout(() => {
        this.loadingUpdateUser = false
      }, 250)
    },
    async deleteUser() {
      try {
        await this.api.deleteInviteUser(this.user.key)
        this.$emit('close')

        setTimeout(() => {
          this.$buefy.snackbar.open({
            message: this.$t('accountDeleted', { user: this.user.full_name, email: this.user.email }),
            type: 'is-primary',
            position: 'is-top-right',
            duration: 2000,
            queue: true,
          })
        }, 250)
      } catch (error) {
        console.error(error)
      }
    },
    async sendInvite() {
      this.loadingSendInvite = true
      try {
        const result = await this.api.sendInviteEmail(this.user.key)
        this.user.invite_sent = result.invite_sent
        this.user.status = result.status
        this.userDetails.status = result.status
        this.userDetails.invite_sent = result.invite_sent
        this.$emit('updated-user', this.user)

        this.$buefy.dialog.alert({
          title: this.$t('inviteSent'),
          message: this.$t('inviteWasSentTo', { email: this.user.email }),
          type: 'is-primary',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      } catch (error) {
        console.error(error)
      }
      // Keep button locked for some time to avoid mistakes
      setTimeout(() => {
        this.loadingSendInvite = false
      }, 1500)
    },
    async savePermissions() {
      this.loadingPermissions = true
      try {
        const result = await this.api.updateUsersPermissions(this.user.key, this.selectedRoles)
        this.user.permissions = result.permissions
        this.$emit('updated-user', this.user)
      } catch (error) {
        console.error(error)
      }
      setTimeout(() => {
        this.loadingPermissions = false
        this.savedPermissions = true
        setTimeout(() => {
          this.savedPermissions = false
        }, 2000)
      }, 250)
    },
    async getUserDetails() {
      const to_check = ['can_be_deleted', 'can_email_be_changed', 'can_edit_permissions']

      try {
        this.loading = true
        this.userDetails = await this.api.getUserDetails(this.user.key, {
          checks: to_check.join(','),
        })
        this.newUserEmail = this.userDetails['email'] // keep v-model and check if newEmail is !== from user.email
      } catch (e) {
        this.detailsError = true
      }
      this.loading = false
      setTimeout(() => {
        this.loading = false
      }, 250)
    },
    async getUserProcedures() {
      this.loadingProcedures = true

      try {
        this.procedures = await this.api.getProceduresWithPermissions({
          user: this.user.key,
          limit: 15,
        })
      } catch (e) {
        console.log('Error :>> ', e)
      }

      this.loadingProcedures = false
    },
    async getUserActivity() {
      this.loadingActivity = true

      var searchPayload = {
        service: 'apply',
        limit: this.activity.limit,
        users: true,
        by_user_key: this.user.key,
      }

      try {
        const activity = await this.api.getActivityLogs(searchPayload)
        this.activity.users = activity.users
        this.activity.logs = activity.logs.map(v => {
          v.expanded = false
          return v
        })
      } catch (e) {
        console.log('Error :>> ', e)
      }

      this.loadingActivity = false
    },
    openProcedure(key) {
      this.$router.push(`/manage/procedure/${key}/metadata`)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "status": "Estado",
    "active": "Ativo",
    "inactive": "Não ativo",
    "permissions": "Permissões",
    "noPermissions": "Sem permissões atribuídas.",
    "organizations": "Organizações",
    "firstAuth": "Primeira autenticação",
    "noAuth": "Ainda não autenticou na Apply",
    "invitesData": "Dados de convite",
    "inviteAccepted": "Convite aceite",
    "inviteSentTo": "O convite para aceder à Appy foi enviado a",
    "inviteNotSent": "Ainda não foi enviado convite para aceder à Apply.",
    "procedures": "Procedimentos",
    "noProcedures": "Ainda não existem procedimentos associados a este utilizador",
    "seeMore": "Ver mais",
    "activity": "Atividade",
    "lastRecords": "Últimos {limit} registos.",
    "noActivity": "Ainda não existe atividade registada para este utilizador",
    "checkRecordsAt": "Consulte todos os registos na seção de",
    "applyPermissions": "Permissões na UC Apply",
    "security": "Segurança",
    "cantEditPermissions": "Não pode alterar as suas próprias permissões",
    "operations": "Operações",
    "noOperations": "Não existem ações disponíveis para realizar na sua própria conta",
    "deleteAccount": "Apagar conta",
    "deleteThisAccount": "Apagar esta conta",
    "cantDeleteAccount": "A conta não parece associada a nenhum recurso (apenas na Apply) e pode ser eliminada",
    "accountCantBeDeleted": "A conta não pode ser eliminada",
    "toDeleteInfo": "Para eliminar, por favor, remova o utilizador de um (ou mais) procedimento(s) ou de algum recurso a que esteja associado",
    "editEmailAddress": "Alterar endereço de email",
    "editEmail": "Alterar email",
    "cantEditEmail": "O endereço de email deste/a utilizador/a não pode ser alterado.",
    "invitation": "Convite de acesso",
    "inviteAgain": "Apesar da plataforma enviar um e-mail de convite de acesso após o utilizador/a ser adicionado, em caso de necessidade, o mesmo poderá ser reenviado",
    "lastInvite": "Último convite enviado em {date} to {email}",
    "cantSendInvite": "De momento, não é possível enviar um novo convite",
    "lastInviteWasSentIn": "O último pode ter sido enviado à menos de uma hora",
    "resendinvite": "Reenviar convite",
    "sendInvite": "Enviar convite",
    "dontSend": "Não enviar",
    "accountAlreadyActive": "A conta deste/a utilizador/a já se encontra ativa. Não é necessário enviar novo email de convite para a plataforma",
    "emailUpdated": "Endereço de email alterado!",
    "emailUpdatedSendInvite": "O endereço de email do utilizador/a foi alterado. Deseja enviar um convite de acesso à plataforma para o novo endereço?",
    "inviteSent": "Convite enviado!",
    "inviteWasSentTo": "O convite de acesso à plataforma foi reenviado para o endereço de email {email}.",
    "noOrganizations": "Sem organizações atribuídas.",
    "accountDeleted": "A conta de {user} ({email}) foi eliminada.",
    "roles": {
      "manager": "Gestor",
      "jury": "Júri",
      "procedure-manager": "Secretariado"
    }
  },
  "en": {
    "status": "Status",
    "active": "Active",
    "inactive": "Not active",
    "permissions": "Permissions",
    "noPermissions": "No permissions assigned.",
    "organizations": "Organizations",
    "firstAuth": "First authentication",
    "noAuth": "Has not yet authenticatied in Apply",
    "invitesData": "Invitation date",
    "inviteAccepted": "Acceptance",
    "inviteSentTo": "The invitation to access the Appy was sent to",
    "inviteNotSent": "Has not yet been sent invitation to access apply.",
    "procedures": "Procedures",
    "noProcedures": "There are no procedures associated with this user",
    "seeMore": "View more",
    "activity": "Activity",
    "lastRecords": "Last {limit} records.",
    "noActivity": "There is no logged activity for this user yet",
    "checkRecordsAt": "See all records in the",
    "applyPermissions": "UC Apply permissions",
    "security": "Security",
    "cantEditPermissions": "Cannot change your own permissions",
    "operations": "Operations",
    "noOperations": "There are no actions available to perform in your own account",
    "deleteAccount": "Delete",
    "deleteThisAccount": "Delete this account",
    "cantDeleteAccount": "The account does not seem associated with any appeal (only in APPLY) and can be eliminated",
    "accountCantBeDeleted": "The account cannot be eliminated",
    "toDeleteInfo": "To delete, please remove the user from one (or more) procedure(s) or from any resource they are associated with",
    "editEmailAddress": "Change email address",
    "editEmail": "Change e-mail",
    "cantEditEmail": "This user's email address cannot be changed.",
    "invitation": "Access invitation",
    "inviteAgain": "Although the platform sends an access invitation e-mail after the user is added, if necessary, it can be resent",
    "lastInvite": "Last invite sent at {date} to {email}",
    "cantSendInvite": "At the moment, it is not possible to send a new invitation",
    "lastInviteWasSentIn": "The last invite may have been sent less than an hour ago",
    "resendinvite": "Resend invite",
    "accountAlreadyActive": "The account of this user is already active. You don't need to send a new invitation email to the platform",
    "emailUpdated": "Changed email address!",
    "emailUpdatedSendInvite": "The user's email address has changed. Want to send an access invitation to the platform to the new address?",
    "inviteSent": "Invitation sent!",
    "inviteWasSentTo": "The access invitation to the platform was resubmitted to the email address {email}.",
    "noOrganizations": "No organizations assigned.",
    "accountDeleted": "The account of {user} ({email}) was deleted.",
    "roles": {
      "manager": "Manager",
      "jury": "Jury",
      "procedure-manager": "Secretariat"
    }
  }
}
</i18n>
