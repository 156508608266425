<template>
  <b-modal
    :active.sync="isActive"
    full-screen
    class="min-h-full clear-bg fw-modal"
    scroll="keep"
    trap-focus
    aria-role="dialog"
    aria-modal
    :destroy-on-hide="true"
    :can-cancel="canCancel"
    @close="close()"
  >
    <div
      class="min-h-full flex flex-col bg-black bg-opacity-40 backdrop-blur-sm backdrop-filter justify-center overflow-y-auto"
    >
      <div
        class="bg-white shadow-lg mx-auto rounded-xl border border-gray-100 relative flex flex-col gap-5 z-50 min-w-xs"
        :class="[
          {
            'p-8': boxed === 'lg' && !paddingless,
            'p-1': boxed === 'xs' && !paddingless,
            'p-5': (!boxed || boxed === 'sm') && !paddingless,
            'max-h-screen': heightFitScreen,
          },
          !['full', 'min'].includes(size) ? `max-w-${size}` : size == 'full' ? 'w-full' : '',
        ]"
        :style="{ width: isMobile ? '100%' : width }"
      >
        <div v-if="title || $slots['toolbar']">
          <div class="flex gap-5">
            <div :class="`flex flex-1 justify-${titleAlign}`">
              <fw-heading muted>{{ title }}</fw-heading>
            </div>
            <div v-if="$slots['toolbar']" class="align-right">
              <slot name="toolbar"></slot>
            </div>
          </div>
          <div v-if="description" class="mt-2 text-gray-500 font-medium text-sm">{{ description }}</div>
        </div>
        <slot v-if="$slots['default']" name="default"></slot>
        <div v-if="$slots['footer']" class="flex justify-end">
          <slot name="footer"></slot>
        </div>
        <div
          v-if="loading"
          class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-10 backdrop-blur-sm backdrop-filter z-50"
        >
          <fw-icon-loading class="w-8 h-8" />
        </div>
      </div>
      <div v-if="canCancel" class="absolute w-full h-full z-0" @click="close()"></div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'FwModal',

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    canCancel: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    titleAlign: {
      type: String,
      default: 'start',
    },
    size: {
      type: String,
      default: 'lg',
    },
    width: {
      type: String,
      default: '36rem',
    },
    boxed: {
      type: String,
      default: 'sm',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    confirmClose: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    heightFitScreen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: this.active || false,
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
  },

  watch: {
    active(value) {
      this.isActive = value
    },
  },

  methods: {
    close() {
      if (this.canCancel) {
        this.isActive = false
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss">
.fw-modal {
  .max-w-xl {
    max-width: 36rem;
  }
  .max-w-2xl {
    max-width: 42rem;
  }
  .max-w-3xl {
    max-width: 48rem;
  }
  .max-w-4xl {
    max-width: 56rem;
  }
  .max-w-5xl {
    max-width: 64rem;
  }
  .max-w-6xl {
    max-width: 72rem;
  }
  .max-w-7xl {
    max-width: 80rem;
  }
}
</style>
