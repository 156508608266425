<template>
  <div class="record is-entry">
    <div class="is-flex is-flex-grow">
      <div class="record-icon">
        <faicon :icon="getIcon()" size="sm" />
      </div>
      <div class="record-content is-flex-grow">
        <div class="is-flex">
          <div class="is-flex-grow is-flex record-title">
            <div v-if="!inputOpened" class="is-flex filename">
              <span class="has-text-weight-bold" @click="openEdit">
                <v-clamp autoresize :max-lines="1">{{ file.title || file.filename }}</v-clamp>
              </span>
              <b-button
                v-if="canEdit"
                type="is-text"
                size="is-small"
                class="has-margin-left-small has-margin-right-small has-text-primary"
                @click="openEdit"
                >editar</b-button
              >
              <b-tooltip
                v-if="!inputOpened && !canEdit && file.room_key"
                size="is-small"
                label="O ficheiro é disponibilizado apenas na sala especificada."
                type="is-dark"
                position="is-top"
                multilined
                :delay="1000"
              >
                <span class="has-margin-left has-margin-right has-text-tiny has-background-light has-padding-tiny">
                  <faicon class="has-text-muted" icon="lock"></faicon
                  ><span class="has-text-weight-bold has-margin-left-small">{{ getRoomTitle(file.room_key) }}</span>
                </span>
              </b-tooltip>
            </div>
            <form v-else class="is-flex is-full" @submit.prevent="updateFileTitle" @keyup.enter="updateFileTitle">
              <b-input
                :id="'input-title-' + file.key"
                v-model="file.title"
                autofocus
                class="is-flex-grow has-margin-right-small"
              ></b-input>
              <b-button size="is-small" type="is-primary" class="has-margin-right-small" @click="updateFileTitle"
                >Guardar</b-button
              >
              <b-button class="has-text-black has-margin-right-small" size="is-small" @click="inputOpened = false"
                >Cancelar</b-button
              >
            </form>
          </div>
          <div
            v-if="!inputOpened && canEdit && rooms && rooms.length"
            class="has-margin-right has-margin-left has-text-small"
          >
            <b-tooltip
              size="is-small"
              label="Indique se o ficheiro deve ser disponibilizado apenas numa sala específica."
              type="is-dark"
              position="is-top"
              multilined
              :delay="1000"
            >
              <b-select v-model="fileRoomKey" size="is-small">
                <option v-for="option in roomsOptions" :key="option.key" :value="option.key">
                  {{ option.title }}
                </option>
              </b-select>
            </b-tooltip>
          </div>
          <div v-if="canEdit && !inputOpened">
            <b-button type="is-danger" outlined size="is-small" @click="$emit('delete', file.key)">Eliminar</b-button>
          </div>
        </div>
        <div v-if="!inputOpened">
          <div class="has-text-muted has-text-small is-flex is-flex-start">
            <a target="_blank" class="has-margin-right has-text-weight-bold" :href="getFileUrl(file)">Download</a>
            <span v-if="file.title" class="is-hidden-mobile has-margin-right is-flex-grow">
              <v-clamp autoresize :max-lines="1">{{ file.filename }}</v-clamp>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  props: {
    file: Object,
    canEdit: {
      type: Boolean,
      default: false,
    },
    rooms: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      inputOpened: false,
      fileRoomKey: this.file.room_key || 'all',
    }
  },

  computed: {
    roomsOptions() {
      const options = []
      if (this.rooms && this.rooms.length) {
        options.push({ key: 'all', title: 'Todas as salas' })
        for (let room of this.rooms) {
          options.push(room)
        }
      }
      return options
    },
  },

  watch: {
    fileRoomKey(value) {
      this.changeFileRoom(value)
    },
  },

  methods: {
    getFileUrl(file) {
      return ServiceStorage.getFileUrl(file)
    },
    getRoomTitle(key) {
      for (let room of this.rooms) {
        if (room.key == key) {
          return room.title
        }
      }
      return ''
    },

    openEdit() {
      if (this.canEdit) {
        this.inputOpened = true
        this.$nextTick(() => {
          document.getElementById('input-title-' + this.file.key).focus()
        })
      }
    },

    async changeFileRoom(value) {
      let new_room_key = value === 'all' ? null : value
      if (new_room_key !== this.file.room_key) {
        try {
          this.$emit('update', this.file.key, { room_key: new_room_key })
        } catch (error) {
          this.$buefy.snackbar.open({
            message: 'Ocorreu um erro a guardar os dados do ficheiro.',
            type: 'is-warning',
            position: 'is-top-right',
            indefinite: true,
            duration: 2000,
            queue: false,
          })
        }
      }
    },

    async updateFileTitle() {
      try {
        this.$emit('update', this.file.key, { title: this.file.title })
        this.inputOpened = false
      } catch (error) {
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro a guardar os dados do ficheiro.',
          type: 'is-warning',
          position: 'is-top-right',
          indefinite: true,
          duration: 2000,
          queue: false,
        })
      }
    },

    getIcon() {
      const extension = this.$options.filters.getExtension(this.file.filename)
      const map = {
        pdf: 'file-pdf',
        png: 'image',
        jpeg: 'image',
        jpg: 'image',
      }
      if (!(extension in map)) {
        return 'file-alt'
      }
      return map[extension]
    },
  },
}
</script>
