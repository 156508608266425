<template>
  <div class="fw-editor">
    <bubble-menu v-if="editor" class="bubble-menu" :tippy-options="{ duration: 100 }" :editor="editor">
      <button
        :class="{ 'is-active': editor.isActive('bold') }"
        @click="
          editor
            .chain()
            .focus()
            .toggleBold()
            .run()
        "
      >
        Negrito
      </button>
      <button
        :class="{ 'is-active': editor.isActive('italic') }"
        @click="
          editor
            .chain()
            .focus()
            .toggleItalic()
            .run()
        "
      >
        Itálico
      </button>
      <button
        :class="{ 'is-active': editor.isActive('strike') }"
        @click="
          editor
            .chain()
            .focus()
            .toggleStrike()
            .run()
        "
      >
        Riscado
      </button>
      <button
        v-if="code"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
        @click="
          editor
            .chain()
            .focus()
            .toggleCodeBlock()
            .run()
        "
      >
        Código
      </button>
    </bubble-menu>
    <editor-content :editor="editor" />
    <div v-if="showLimit" class="character-count text-xs text-gray-400 py-1">
      {{ editor.storage.characterCount.characters() }}/{{ maxCharacters }} caracteres ({{
        editor.storage.characterCount.words()
      }}
      palavras)
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import CharacterCount from '@tiptap/extension-character-count'
import CodeBlock from '@tiptap/extension-code-block'

export default {
  components: {
    EditorContent,
    BubbleMenu,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 0, // Backend limit is 65000 (can be higher, if needed)
    },
    code: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editor: null,
      defaultLimit: 35000, // Must be lower than backend limit and needs to include html tags
    }
  },

  computed: {
    showLimit() {
      return (
        this.editor &&
        (this.maxCharacters <= 2000 || this.editor.storage.characterCount.characters() > this.maxCharacters / 2)
      )
    },
    maxCharacters() {
      return this.limit ? this.limit : this.defaultLimit
    },
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: 'Escreva aqui...',
        }),
        CharacterCount.configure({
          limit: this.maxCharacters,
        }),
        CodeBlock,
      ],
      parseOptions: {
        preserveWhitespace: 'full',
      },
      editable: true,
      injectCSS: false,
      content: this.value,
      autofocus: true,
      onUpdate: () => {
        let message = this.editor.getHTML()
        // if (message) {
        //   if (
        //     message.match(/<p>/g).length == 1 &&
        //     message.match(/<\/p>/g).length == 1 &&
        //     message.startsWith('<p>') &&
        //     message.endsWith('</p>')
        //   ) {
        //     message = message.substr(3, message.length - 7)
        //   }

        //   message = message.trim()
        //   while (message.startsWith('<br>')) {
        //     message = message.substr(4).trim()
        //   }
        //   while (message.endsWith('<br>')) {
        //     message = message.substr(0, message.length - 4).trim()
        //   }
        // }
        // HTML
        this.$emit('input', message)
        this.$emit('changed')
        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
.fw-editor {
  .ProseMirror:focus {
    outline: solid 2px rgba(3, 164, 121, 0.7);
    outline-offset: 2px;
  }
  .ProseMirror {
    background: #f2f4f4;
    min-height: 125px;
    @apply focus:outline-none rounded-lg px-3 py-2.5;

    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      @apply py-0 pl-2 ml-5;

      li {
        @apply list-disc;
      }
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }
  }

  .ProseMirror * {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #999;
    pointer-events: none;
    height: 0;
  }

  .bubble-menu {
    display: flex;
    background-color: #0d0d0d;
    padding: 0.2rem;
    border-radius: 0.5rem;

    button {
      border: none;
      background: none;
      color: #fff;
      font-size: 0.85rem;
      font-weight: 500;
      padding: 0 0.2rem;
      opacity: 0.6;

      &:hover,
      &.is-active {
        opacity: 1;
      }
    }
  }

  .drop-cursor {
    border-radius: 3px;
    overflow: hidden;
  }

  .menubar button:first-child {
    margin: 0;
  }

  .h1 .content {
    font-size: 2em;
  }

  .ProseMirror .h2 .content {
    font-size: 1.5em;
  }

  .ProseMirror .h3 .content {
    font-size: 1.2em;
  }

  .ProseMirror.editor {
    background-color: transparent !important;
    @apply p-3;
  }

  .ProseMirror.editor p.is-editor-empty:first-child::before {
    content: attr(data-empty-text);
    @apply text-gray-600;
  }
}
</style>
