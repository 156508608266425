<template>
  <div class="text-center my-2">
    <b-pagination
      :total="total"
      :range-before="rangeBefore"
      :range-after="rangeAfter"
      :order="order"
      :current="current"
      :simple="isSimple"
      :rounded="isRounded"
      :per-page="perPage"
      :aria-next-label="$t('nextPage')"
      :aria-previous-label="$t('previousPage')"
      :aria-page-label="$t('page')"
      :aria-current-label="$t('currentPage')"
      :page-input="hasInput"
      :page-input-position="inputPosition"
      :debounce-page-input="inputDebounce"
    >
      <template #previous="props">
        <fw-button
          :id="`page${props.page.number}`"
          :class="{ 'order-1': order === 'is-centered' }"
          :disabled="!hasPrev"
          type="link-muted"
        >
          <fw-icon-chevron-left class="w-6 h-6" @click.native="pageChanged(current - 1)"></fw-icon-chevron-left>
        </fw-button>
      </template>

      <template v-if="!isSimple" #default="props">
        <fw-button
          :id="`page${props.page.number}`"
          :type="props.page.isCurrent ? 'link-primary' : 'link-muted'"
          :class="{ 'order-2': order === 'is-centered' }"
          @click.native="pageChanged(props.page.number)"
        >
          {{ props.page.number }}
        </fw-button>
      </template>

      <template #next="props">
        <fw-button
          :id="`page${props.page.number}`"
          type="link-muted"
          :class="{ 'order-3': order === 'is-centered' }"
          :disabled="!hasNext"
          @click.native="pageChanged(current + 1)"
        >
          <fw-icon-chevron-right class="w-6 h-6"></fw-icon-chevron-right>
        </fw-button>
      </template>
    </b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    current: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 25,
    },
    rangeBefore: {
      type: Number,
      default: 2,
    },
    rangeAfter: {
      type: Number,
      default: 2,
    },
    order: {
      type: String,
      default: 'is-centered',
    },
    isSimple: {
      type: Boolean,
      default: false,
    },
    isRounded: {
      type: Boolean,
      default: false,
    },
    hasInput: {
      type: Boolean,
      default: false,
    },
    inputPosition: {
      type: String,
      default: '',
    },
    inputDebounce: {
      type: String,
      default: '',
    },
  },

  computed: {
    hasNext() {
      return this.current < this.totalPages
    },

    hasPrev() {
      return this.current > 1
    },
  },

  methods: {
    pageChanged(page) {
      this.$emit('page-changed', page)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "page": "Página",
    "nextPage": "Próxima página",
    "previousPage": "Página anterior",
    "currentPage": "Página atual"
  },
  "en": {
    "page": "Page",
    "nextPage": "Next page",
    "previousPage": "Previous page",
    "currentPage": "Current page"
  }
}
</i18n>

<style>
.pagination {
  margin: 0 !important;
}
</style>
