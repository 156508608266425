<template>
  <div class="inner is-flex-grow">
    <div class="inner-panel sidebar">
      <h3 class="is-size-3 has-margin-top-medium has-margin-left-medium has-margin-right-medium">
        Salas virtuais <span class="is-meta">{{ rooms.length }}</span>
      </h3>
      <div class="is-height-auto">
        <div v-if="exam.is_owner" class="menu has-margin-medium has-margin-top-small">
          <ul class="menu-list has-margin-top">
            <li>
              <a :class="{ 'is-active': view == 'configuration' }" @click.prevent="goToConfiguration">
                <faicon icon="cog"></faicon> Definições
              </a>
            </li>
          </ul>
        </div>
        <div class="is-meta has-margin-medium has-margin-bottom is-flex is-flex-align-center is-flex-space-between">
          <div class="is-size-4">Disponíveis</div>
          <div v-if="exam.permissions.rooms">
            <b-button size="is-small" type="is-light" @click="addRoom"><faicon icon="plus"></faicon></b-button>
          </div>
        </div>
        <div class="menu has-margin-medium has-margin-top-small">
          <ul class="menu-list">
            <li v-for="room in rooms" :key="room.key">
              <a
                :class="{ 'is-active': activeRoom && activeRoom.key == room.key }"
                class="is-flex is-flex-align-center is-flex-space-between"
                @click.prevent="goToRoom(room)"
              >
                <span>
                  <faicon icon="video" size="sm"></faicon>
                  <span class="has-margin-right">{{ room.title }}</span>
                  <span class="is-lowercase is-family-code has-text-weight-bold">
                    {{ room.meeting_key }}
                  </span>
                </span>
                <span class="tag is-rounded is-light">
                  <faicon icon="users"></faicon>
                  <span>{{ room.stats.users }}</span>
                </span>
              </a>
            </li>
          </ul>
          <div v-if="exam.permissions.rooms" class="has-margin-top">
            <b-button size="is-small" type="is-text" icon-left="plus" @click="addRoom">
              Criar nova
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-panel is-flex-grow">
      <div v-if="view == 'configuration'" class="has-margin-medium">
        <h2>
          Definições
        </h2>
        <div class="has-margin-top-medium">
          <h3 class="label">Alocação de estudantes</h3>
          <div v-if="exam.permissions.rooms" class="has-margin-top has-margin-bottom">
            <div class="is-flex">
              <b-dropdown v-model="selectedAllocationKey" aria-role="list" class="has-margin-right">
                <button slot="trigger" slot-scope="{ active }" class="button is-light">
                  <span>{{ allocationOptionsRef[selectedAllocationKey] }}</span>
                  <b-icon size="is-small" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
                </button>
                <b-dropdown-item
                  v-for="allocationOption in allocationOptions"
                  :key="allocationOption.key"
                  :value="allocationOption.key"
                  aria-role="listitem"
                >
                  {{ allocationOption.title }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                type="is-text"
                aria-label="Mais info"
                icon-left="question-circle"
                @click="showInfo.allocation = !showInfo.allocation"
              ></b-button>
            </div>
            <div v-if="showInfo.allocation" class="has-padding-small has-text-small has-text-muted">
              <div class="has-background-light has-text-small content has-padding">
                <ul>
                  <li>
                    <strong>Escolha aleatória:</strong> será atribuída uma sala aleatória ao estudante, na página de
                    entrada (acessível via ID do exame).
                  </li>
                  <li>
                    <strong>Alocação contínua:</strong> os estudantes serão alocados a uma sala virtual até o limite da
                    mesma ser atingido. Nesta altura, a plataforma passa à sala seguinte.
                  </li>
                  <li>
                    <strong>Escolha livre:</strong> a plataforma irá apresentar a lista das salas disponíveis a todos os
                    estudantes, através da página de entrada do exame (acessível via ID).
                  </li>
                </ul>
                <div class="has-padding-left">
                  Em qualquer das opções, os estudantes podem aceder diretamente às salas virtuais, caso divulge o ID
                  das mesmas. A autorização de entrada é sempre decisão de um dos docentes que esteja presente na sala
                  virtual.
                </div>
              </div>
            </div>
          </div>
          <div v-else>{{ allocationOptionsRef[selectedAllocationKey] }}</div>
        </div>
        <div v-if="selectedAllocationKey == 'continuous'" class="has-margin-top-medium">
          <h3 class="label">Participantes por sala</h3>
          <div class="has-margin-top has-margin-bottom">
            <b-dropdown v-if="exam.permissions.rooms" v-model="selectedAllocationLimit" aria-role="list">
              <button slot="trigger" slot-scope="{ active }" class="button is-light">
                <span>{{ selectedAllocationLimit }}</span>
                <b-icon size="is-small" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
              </button>
              <b-dropdown-item
                v-for="allocationLimitOption in allocationLimitOptions"
                :key="allocationLimitOption"
                :value="allocationLimitOption"
                aria-role="listitem"
              >
                {{ allocationLimitOption }}
              </b-dropdown-item>
            </b-dropdown>
            <div v-else>{{ selectedAllocationLimit }}</div>
          </div>
        </div>
      </div>
      <div v-else-if="view == 'room'" class="has-margin-left-medium has-margin-right-medium has-margin-top-medium">
        <div class="is-flex is-flex-space-between is-flex-align-center">
          <div class="is-flex is-flex-align-center">
            <div class="has-margin-right"><faicon icon="video"></faicon></div>
            <div class="is-size-1">{{ activeRoom.title }}</div>
            <div class="has-margin-left is-flex is-flex-align-center">
              <div class="is-lowercase is-family-code is-size-2 has-text-weight-bold">
                {{ activeRoom.meeting_key }}
              </div>
              <b-button
                v-if="!meeting || meeting.key != activeRoom.meeting_key"
                type="is-primary"
                class="has-margin-left"
                outlined
                size="is-small"
                @click="$router.push({ name: 'live', params: { key: activeRoom.meeting_key } })"
                >Entrar</b-button
              >
              <span
                v-if="meeting && meeting.key == activeRoom.meeting_key"
                class="tag is-small is-primary has-margin-left"
                >Atual</span
              >
            </div>
          </div>
          <div v-if="exam.permissions.rooms && !activeRoom.stats.instances">
            <b-button type="is-danger" outlined size="is-small" @click="deleteRoom(activeRoom)">Eliminar sala</b-button>
          </div>
        </div>
        <div class="is-flex is-flex-align-center has-margin-top-medium has-margin-bottom">
          <div class="is-flex-grow is-flex is-flex-align-center">
            <h3>Participantes</h3>
            <div v-if="activeRoomParticipants.length" class="has-margin-left">
              <b-button
                size="is-small"
                :type="viewData == 'as-gallery' ? 'is-light' : 'is-text'"
                icon-left="th"
                class="has-margin-right-small"
                aria-label="Ver em lista"
                @click="viewData = 'as-gallery'"
              ></b-button>
              <b-button
                size="is-small"
                :type="viewData == 'as-list' ? 'is-light' : 'is-text'"
                icon-left="list-ul"
                aria-label="Ver em galeria"
                @click="viewData = 'as-list'"
              ></b-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="view == 'room'" class="has-margin-medium is-height-full">
        <div v-if="activeRoomParticipants.length">
          <div v-if="viewData == 'as-list'">
            <b-table :data="activeRoomParticipants">
              <template slot-scope="props">
                <b-table-column class="is-flex is-flex-align-center" field="name" label="Nome completo">
                  <span class="has-margin-right"><UserAvatar size="is-tiny" :user="props.row.user"/></span>
                  <span>{{ props.row.user.name }}</span>
                </b-table-column>
                <b-table-column field="number" label="Nº">
                  {{ props.row.user.number }}
                </b-table-column>
                <b-table-column field="joined_date" label="Entrada na sala">
                  {{ props.row.joined_date | formatDateTime }}
                </b-table-column>
              </template>
            </b-table>
          </div>
          <div v-else class="columns is-multiline">
            <div
              v-for="participant in activeRoomParticipants"
              :key="participant.key"
              class="column is-half-mobile has-padding-small"
              style="min-width: 180px; max-width: 200px"
            >
              <div class="has-padding has-background-light is-rounded is-height-full is-flex is-flex-direction-column">
                <div class="is-flex is-flex-grow is-flex-direction-column is-flex-align-center">
                  <div>
                    <UserAvatar size="is-medium" :user="participant.user" />
                  </div>
                  <div class="is-flex-grow is-flex">
                    <div class="is-flex-grow has-text-centered">
                      <div class="has-text-weight-bold has-margin-top has-margin-left-medium has-margin-right-medium">
                        {{ participant.user.name }}
                      </div>
                      <div class="has-text-muted has-text-small">{{ participant.user.number }}</div>
                    </div>
                  </div>

                  <div class="has-margin-top has-text-centered has-text-small">
                    <div class="label is-marginless has-text-tiny">Entrada na sala</div>
                    <div class="has-text-muted">{{ participant.joined_date | formatDateTime }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="has-text-small has-text-muted is-height-auto has-text-centered">
          <div class="has-margin-medium">
            <faicon icon="users" size="3x"></faicon>
          </div>
          <div>
            Ainda não existem participantes nesta sala.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceExams from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'

const ALLOCATION_OPTIONS = [
  { key: 'random', title: 'Aleatória' },
  { key: 'continuous', title: 'Contínua' },
  { key: 'open', title: 'Escolha Livre' },
]
const ALLOCATION_OPTIONS_REF = {}
for (let item of ALLOCATION_OPTIONS) ALLOCATION_OPTIONS_REF[item.key] = item.title

const ALLOCATION_LIMIT_OPTIONS = [10, 25, 50]

export default {
  components: {
    UserAvatar,
  },

  props: {
    exam: {
      type: Object,
      deep: true,
    },
    meeting: {
      type: Object,
      default: null,
    },
    examSubscription: {
      type: Object,
      default: null,
    },
  },

  data() {
    this.getRooms()
    return {
      view: 'configuration',
      viewData: 'as-gallery',
      rooms: [],
      activeRoom: null,
      activeRoomParticipants: [],
      selectedAllocationKey: this.exam.allocation,
      allocationOptionsRef: ALLOCATION_OPTIONS_REF,
      allocationOptions: ALLOCATION_OPTIONS,
      selectedAllocationLimit: this.exam.allocation_limit,
      allocationLimitOptions: ALLOCATION_LIMIT_OPTIONS,
      showInfo: {
        allocation: false,
      },
    }
  },

  watch: {
    exam: {
      deep: true,
      handler(value) {
        if (value.allocation !== this.selectedAllocationKey) this.selectedAllocationKey = value.allocation
        if (value.allocation_limit !== this.selectedAllocationLimit) {
          this.selectedAllocationLimit = value.allocation_limit
        }
      },
    },
    async selectedAllocationKey(value) {
      if (value !== this.exam.allocation) {
        const exam = await ServiceExams.updateExam(this.exam.key, { allocation: value })
        Object.assign(this.exam, exam)
        this.selectedAllocationKey = value
      }
    },
    async selectedAllocationLimit(value) {
      const allocation_limit = parseInt(value)
      if (allocation_limit !== this.exam.allocation_limit) {
        const exam = await ServiceExams.updateExam(this.exam.key, { allocation_limit: allocation_limit })
        Object.assign(this.exam, exam)
        this.selectedAllocationLimit = allocation_limit
      }
    },
  },

  beforeDestroy() {
    if (this.summaryEditor) this.summaryEditor.destroy()
    if (this.examSubscription) this.examSubscription.unsubscribeRooms()
  },

  methods: {
    sortRoom() {
      this.rooms.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: 'base' }))
    },
    async getRooms() {
      this.rooms = this.exam.rooms = await ServiceExams.getExamRooms(this.exam.key)
      if (this.examSubscription) this.examSubscription.subscribeRooms(this.rooms)
      this.sortRoom()
    },
    async addRoom() {
      const response = await ServiceExams.addRoomToExam(this.exam.key)
      this.rooms = this.exam.rooms = response.rooms
      this.sortRoom()

      for (let room of this.rooms) {
        if (room.key === response.new_room_key) {
          await this.goToRoom(room)
          break
        }
      }
    },
    async deleteRoom(room) {
      this.rooms = this.exam.rooms = await ServiceExams.deleteRoom(room.key)

      if (this.exam.files && this.exam.files.length) {
        for (let file of this.exam.files) {
          if (file.room_key == room.key) {
            file.room_key = null
          }
        }
      }

      if (this.activeRoom && this.activeRoom.key === room.key) this.goToConfiguration()
    },
    async goToRoom(room) {
      const response = await ServiceExams.getRoom(room.key)
      this.view = 'room'
      this.activeRoom = room
      Object.assign(this.activeRoom, response.room)
      this.activeRoomParticipants = response.participants
    },
    goToConfiguration() {
      this.view = 'configuration'
      this.activeRoom = null
      this.activeRoomParticipants = []
    },
  },
}
</script>
