export default [
  {
    path: '/manage',
    name: 'manage',
    component: () => import('@/views/ViewHome'),
    meta: { requiresAuth: true, requiresPermissions: true },
  },
  {
    path: '/manage/people',
    name: 'people',
    component: () => import('@/views/manage/ViewManagePeople'),
    meta: { requiresAuth: true, requiresPermissions: true },
  },
  {
    path: '/manage/procedures',
    name: 'procedures',
    component: () => import('@/views/manage/ViewManageProcedures'),
    meta: { requiresAuth: true, requiresPermissions: true },
  },
  {
    path: '/manage/activity',
    name: 'activity',
    component: () => import('@/views/manage/ViewManageActivity'),
    meta: { requiresAuth: true, requiresPermissions: true },
  },
  {
    path: '/manage/notifications',
    name: 'manage-notifications',
    component: () => import('@/views/manage/ViewManageNotifications'),
    meta: { requiresAuth: true, requiresPermissions: true },
  },
  {
    path: '/manage/procedure',
    name: 'manage-procedures',
    component: () => import('@/views/manage/ViewManageProcedure'),
    meta: { requiresAuth: true, requiresPermissions: true },
  },
  {
    path: '/manage/procedure/:key/:subpage',
    name: 'manage-procedure-subpage',
    component: () => import('@/views/manage/ViewManageProcedure'),
    meta: { requiresAuth: true, requiresPermissions: true },
  },
  {
    path: '/manage/procedure/:key',
    name: 'manage-procedure-page',
    component: () => import('@/views/manage/ViewManageProcedure'),
    meta: { requiresAuth: true, requiresPermissions: true },
  },
  {
    path: '/manage/procedure/:key/:subpage/:map',
    name: 'manage-procedure-evaluation-map',
    component: () => import('@/views/manage/ViewManageProcedure'),
    meta: { requiresAuth: true, requiresPermissions: true },
  },
]
