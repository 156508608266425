<template>
  <div class="m-4 flex gap-4 flex-col z-10">
    <div class="entry">
      <b-tooltip label="Participantes" :delay="1000" position="is-right" animated type="is-light">
        <fw-button
          :type="view == 'participants' ? 'glass' : 'glass-sm'"
          label="Galeria de participantes"
          :class="{ 'text-primary': view == 'participants' }"
          @click.native="$emit('set-view', 'participants')"
        >
          <svg class="fill-current h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M16 4a1 1 0 0 1 1 1v4.2l5.213-3.65a.5.5 0 0 1 .787.41v12.08a.5.5 0 0 1-.787.41L17 14.8V19a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h14zm-1 2H3v12h12V6zM7.4 8.829a.4.4 0 0 1 .215.062l4.355 2.772a.4.4 0 0 1 0 .674L7.615 15.11A.4.4 0 0 1 7 14.77V9.23c0-.221.18-.4.4-.4zM21 8.84l-4 2.8v.718l4 2.8V8.84z"
            />
          </svg>
          <fw-badge v-if="podsLength">{{ podsLength }}</fw-badge>
        </fw-button>
      </b-tooltip>
    </div>
    <div v-if="canSeeRoomPlugin" class="entry">
      <b-tooltip label="Room Plug-ins" :delay="1000" position="is-right" animated type="is-light">
        <fw-button
          :type="view == 'room-plugin' ? 'glass' : 'glass-sm'"
          label="Room Plugin"
          :class="{ 'text-primary': view == 'room-plugin' }"
          @click.native="$emit('set-view', 'room-plugin')"
        >
          <svg class="fill-current h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M13 18v2h6v2h-6a2 2 0 0 1-2-2v-2H8a4 4 0 0 1-4-4V7a1 1 0 0 1 1-1h3V2h2v4h4V2h2v4h3a1 1 0 0 1 1 1v7a4 4 0 0 1-4 4h-3zm-5-2h8a2 2 0 0 0 2-2v-3H6v3a2 2 0 0 0 2 2zm10-8H6v1h12V8zm-6 6.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
        </fw-button>
      </b-tooltip>
    </div>
    <div v-if="canSeeChat" class="entry">
      <b-tooltip label="Canais" :delay="1000" position="is-right" animated type="is-light">
        <fw-button
          label="Canais"
          :type="view == 'chat' ? 'glass' : 'glass-sm'"
          :class="{ 'text-primary': view == 'chat' }"
          @click.native="$emit('set-view', 'chat')"
        >
          <svg class="fill-current h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M14.45 19L12 22.5 9.55 19H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-6.55zm-1.041-2H20V5H4v12h6.591L12 19.012 13.409 17z"
            />
          </svg>
          <fw-badge>{{ chatAllUnread }}</fw-badge>
        </fw-button>
      </b-tooltip>
    </div>
    <div v-if="canAddPlugin" class="entry">
      <b-tooltip label="Dispositivos Plug-in" :delay="1000" position="is-right" animated type="is-light">
        <fw-button
          :type="view == 'plugin' ? 'glass' : 'glass-sm'"
          label="Códigos UC Plugin"
          :class="{ 'text-primary': view == 'plugin' }"
          @click.native="$emit('set-view', 'plugin')"
        >
          <svg class="fill-current h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M13 18v2h6v2h-6a2 2 0 0 1-2-2v-2H8a4 4 0 0 1-4-4V7a1 1 0 0 1 1-1h3V2h2v4h4V2h2v4h3a1 1 0 0 1 1 1v7a4 4 0 0 1-4 4h-3zm-5-2h8a2 2 0 0 0 2-2v-3H6v3a2 2 0 0 0 2 2zm10-8H6v1h12V8zm-6 6.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
        </fw-button>
      </b-tooltip>
    </div>
    <div v-if="isClassEditionTeacher" class="entry">
      <b-tooltip label="Aulas" :delay="1000" position="is-right" animated type="is-light">
        <fw-button
          :type="view == 'unit' ? 'glass' : 'glass-sm'"
          label="Detalhes da turma"
          :class="{ 'text-primary': view == 'unit' }"
          @click.native="$emit('set-view', 'unit')"
        >
          <fw-icon-team class="w-6 h-6" />
        </fw-button>
      </b-tooltip>
    </div>
    <div v-if="exam" class="entry">
      <b-tooltip label="Exame" :delay="1000" position="is-right" animated type="is-light">
        <fw-button
          label="Exame"
          class="rounded-xl flex items-center justify-center shadow-xl w-10 h-10 bg-gray-700 bg-opacity-50"
          :class="{ 'text-primary': view == 'exam-view' }"
          @click.native="$emit('set-view', 'exam-view')"
        >
          <svg class="fill-current h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M14.45 19L12 22.5 9.55 19H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-6.55zm-1.041-2H20V5H4v12h6.591L12 19.012 13.409 17z"
            />
          </svg>
          <span
            v-if="exam.status == 'running'"
            :class="{ 'text-primary': view != 'exam-view' }"
            class="absolute aspect-w-1 aspect-h-1 flex items-center justify-center -top-1 -right-3 py-0.5 px-2 text-xs font-semibold rounded-full bg-primary text-white"
            >unlock</span
          >
        </fw-button>
      </b-tooltip>
    </div>
    <div v-if="exam && exam.is_manager" class="entry">
      <b-tooltip label="Salas de exame" :delay="1000" position="is-right" animated type="is-light">
        <fw-button
          :type="view == 'exam-rooms' ? 'glass' : 'glass-sm'"
          label="Salas de exame"
          :class="{ 'text-primary': view == 'exam-rooms' }"
          @click.native="$emit('set-view', 'exam-rooms')"
        >
          <svg class="fill-current h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M14.45 19L12 22.5 9.55 19H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-6.55zm-1.041-2H20V5H4v12h6.591L12 19.012 13.409 17z"
            />
          </svg>
          <span
            :class="{ 'text-primary': view != 'exam-rooms' }"
            class="absolute aspect-w-1 aspect-h-1 flex items-center justify-center -top-1 -right-3 py-0.5 px-2 text-xs font-semibold rounded-full bg-primary text-white"
            >{{ exam.stats.rooms }}</span
          >
        </fw-button>
      </b-tooltip>
    </div>
    <div v-if="exam && exam.is_manager" class="entry">
      <b-tooltip label="Respostas" :delay="1000" position="is-right" animated type="is-light">
        <fw-button
          :type="view == 'exam-answers' ? 'glass' : 'glass-sm'"
          label="Detalhes da turma"
          @click.native="$emit('set-view', 'exam-answers')"
          >Respostas</fw-button
        >
        <span :class="{ 'is-active': view == 'exam-answers' }">{{ exam.stats.answers }}</span>
      </b-tooltip>
    </div>
    <div v-if="canSeeRecordings" class="entry">
      <b-tooltip label="Gravações" :delay="1000" position="is-right" animated type="is-light">
        <fw-button
          :type="view == 'recordings' ? 'glass' : 'glass-sm'"
          label="Gravações"
          @click.native="$emit('set-view', 'recordings')"
        >
          <fw-icon-rec class="h-5" />
        </fw-button>
      </b-tooltip>
    </div>
    <div v-if="false && canSeeForms" class="entry">
      <b-tooltip label="Quiz" :delay="1000" position="is-right" animated type="is-light">
        <fw-button :type="view == 'quiz' ? 'glass' : 'glass-sm'" label="Quiz" @click.native="$emit('set-view', 'quiz')">
          Quiz
        </fw-button>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    view: {
      type: String,
      default: 'participants',
    },
    podsLength: Number,
    chatAllUnread: {
      type: String,
      default: '',
    },
    isClassEditionTeacher: Boolean,
    exam: {
      type: Object,
      deep: true,
      default: null,
    },
    canSeeRoomPlugin: Boolean,
    canSeeChat: Boolean,
    canAddPlugin: Boolean,
    canSeeRecordings: Boolean,
    canSeeForms: Boolean,
  },
}
</script>
