<template>
  <button
    class="relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex flex-col text-left"
    @click="$emit('open-procedure')"
  >
    <div class="flex flex-1 items-center justify-start gap-2 w-full">
      <div class="w-2/5">
        <div class="flex-shrink-0 font-medium flex items-center gap-1">
          <fw-icon-arrow-right class="w-5 h-5 text-gray-500" />
          <span class="text-gray-500">{{ item.prefix }}</span>
          <span class="font-semibold truncate">{{ item.code }}</span>
        </div>
      </div>
      <div class="w-1/5 text-sm text-gray-500 font-medium capitalize flex gap-3">
        <div class="font-semibold">{{ $t(`procedureType.${item.type}`) }}</div>
        <div class="flex-shrink-0">{{ procedureTitle }}</div>
      </div>
      <div
        v-if="!basic"
        class="hidden sm:flex w-2/5 text-xs text-gray-500 font-normal gap-2 items-center justify-between"
      >
        <v-clamp class="text-sm hidden" autoresize :max-lines="1" title="Código PEP">
          {{ item.pep_code }}
        </v-clamp>
        <v-clamp class="text-sm hidden xl:block" autoresize :max-lines="1" title="LUGUS">
          {{ item.lugus }}
        </v-clamp>
        <span v-if="item.scholarship_project" class="truncate hidden" title="Referência do projeto">{{
          item.scholarship_project.reference
        }}</span>
        <span v-if="item.published_dr_date" class="flex-shrink-0" title="DR / Euraxess">{{
          item.published_dr_date | formatDate
        }}</span>
      </div>
      <div class="md:w-1/5">
        <div
          :class="[colors[item.status]]"
          class="inline-flex h-7 px-3 py-1 max-w-full rounded-lg bg-opacity-20 text-xs text-opacity-90 font-medium items-center text-center"
        >
          <span class="truncate">{{ $t(`procedureStatus.${item.status}`) }}</span>
          <fw-icon-check v-if="item.status === 'published'" class="w-4 h-4 ml-1" />
        </div>
      </div>
      <div v-if="isManagerOrAdmin" class="hidden md:flex w-1/6 gap-2 items-center" title="Gestor/a">
        <fw-avatar size="xs" :user="item.manager" class="flex-shrink-0" />
        <v-clamp class="font-semibold text-sm" autoresize :max-lines="1">
          {{ item.manager.display_name || item.manager.full_name }}
        </v-clamp>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'RecordManageProcedure',
  props: {
    item: Object,
    language: {
      type: String,
      default: 'pt',
    },
    basic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: {
        draft: 'bg-yellow-300',
        published: 'bg-teal-400',
        jury: 'bg-teal-400',
        running: 'bg-teal-300',
        ended: 'bg-gray-300',
        closed: 'bg-gray-100',
      },
    }
  },
  computed: {
    procedureTitle() {
      return this.$store.getters.getProcedureTitle(this.item)
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    isManagerOrAdmin() {
      return this.userPermissions.isManager || this.userPermissions.isProcedureManager || this.userPermissions.isAdmin
    },
  },
}
</script>

<i18n>
{
  "en": {
    "procedureStatus": {
      "label": "Procedure status",
      "draft": "Draft",
      "jury": "Jury",
      "running": "Running",
      "ended": "Hearing",
      "closed": "Ended",
      "published": "Published"
    },
    "procedureType": {
      "teachers": "Teachers",
      "researchers": "Researchers",
      "researchers-dl57": "Researchers DL 57",
      "scholarships": "Scholarships",
      "staff": "Staff"
    }
  },
  "pt": {
    "procedureStatus": {
      "label": "Estados do procedimento",
      "draft": "Rascunho",
      "jury": "Júri",
      "running": "Iniciado",
      "ended": "Audiência",
      "closed": "Encerrado",
      "published": "Publicado"
    },
    "procedureType": {
      "teachers": "Professores",
      "researchers": "Investigadores",
      "researchers-dl57": "Investigadores DL 57",
      "scholarships": "Bolsas",
      "staff": "Pessoal não docente"
    }
  }
}
</i18n>
