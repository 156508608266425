var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"group text-sm flex-shrink-0 rounded flex gap-1 p-2 items-center transition-colors duration-150 font-medium",class:{
    'text-gray-700': !_vm.active,
    'text-primary': _vm.active || (_vm.checkActualRoute && _vm.$router.currentRoute.path == _vm.to),
    'opacity-60 cursor-not-allowed': _vm.disabled,
    'hover:text-primary': !_vm.disabled,
    'w-full': _vm.fullWidth,
    'bg-gray-300 bg-opacity-30 rounded-lg': _vm.selected,
    'border-b pb-3 mb-2': _vm.bordered,
  },attrs:{"disabled":_vm.disabled},on:{"click":_vm.goToOrRun}},[(_vm.hasIcon)?_c('span',{staticClass:"rounded-xl w-7 flex-shrink-0",class:{
      'text-primary': _vm.active,
      'text-gray-500 opacity-60': !_vm.active,
      'group-hover:text-primary group-hover:opacity-90': !_vm.disabled,
    }},[_vm._t("svg",function(){return [_c('fw-icon-' + _vm.icon,{tag:"component",staticClass:"w-6 h-6"})]})],2):_vm._e(),_c('span',{staticClass:"group-opacity-100 text-left flex-1 flex-shrink-0",class:[`text-${_vm.size}`]},[_c('v-clamp',{attrs:{"autosize":"","max-lines":1}},[_vm._v(_vm._s(_vm.label))])],1),(_vm.counter)?_c('span',{staticClass:"text-xs px-1.5 py-0.5 rounded-full flex-shrink-0 min-w-7 text-center font-bold",class:{
      'bg-primary text-white': _vm.counterType === 'primary',
      'bg-gray-500 bg-opacity-20 text-gray-500': _vm.counterType === 'light',
      'bg-gray-500 bg-opacity-10 text-gray-500': _vm.counterType === 'xlight',
    }},[_vm._v(" "+_vm._s(_vm.counter)+" ")]):_vm._e(),(_vm.$slots.suffix)?_c('span',{staticClass:"flex-shrink-0"},[_vm._t("suffix")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }