import ViewLive from './views/ViewLive'
import ViewLiveOld from './views/ViewLiveOld'

export default [
  {
    path: '/live/:key',
    name: 'live',
    component: ViewLive,
    meta: {
      requiresRole: 'user',
      allowPlugin: true,
      redirect: async function(route, isLoggedIn) {
        if (!isLoggedIn) {
          if (process.env.VUE_APP_KEY === 'ucteacher') {
            return { name: 'login' }
          } else {
            return { name: 'anonymousJoin', params: route.params }
          }
        }
      },
    },
  },
  {
    path: '/live-v1/:key',
    name: 'live-v1',
    component: ViewLiveOld,
    meta: {
      requiresRole: 'user',
      allowPlugin: true,
      redirect: async function(route, isLoggedIn) {
        if (!isLoggedIn) {
          if (process.env.VUE_APP_KEY === 'ucteacher') {
            return { name: 'login' }
          } else {
            return { name: 'anonymousJoin', params: route.params }
          }
        }
      },
    },
  },
]
