<template>
  <div class="is-flex-grow has-background-light has-text-light is-height-auto">
    <div class="is-height-full">
      <div class="has-text-muted is-size-3 is-flex is-flex-align-center is-flex-justify-center is-height-full">
        <div v-if="!newPlugInCode" class="has-text-centered">
          <div class="has-margin-bottom-large has-text-dark">
            <faicon icon="plug" size="4x"></faicon>
          </div>
          <div class="is-width-small is-size-2">
            Expanda a sua criatividade com recurso a dispositivos UC Plugin.
          </div>
          <div class="is-width-small has-text-small has-margin-top">
            Para começar, aceda ao endereço
            <a class="has-text-weight-bold" target="_blank" :href="ucPluginAppUrl">{{ ucPluginAppUrl }}</a>
            no seu smartphone ou tablet, instale a aplicação e utilize o novo código gerado nesta página, para ativar e
            emparelhar o seu dispositivo nesta sessão.
          </div>
          <div class="has-margin-top-large">
            <b-button type="is-primary" @click="getPlugInNewCode">Gerar novo código</b-button>
          </div>
        </div>
        <div v-else class="has-text-centered">
          <div class="has-margin-bottom-large has-text-primary">
            <faicon icon="plug" size="3x"></faicon>
          </div>
          <div class="meetings-plugin-code" style="display: inline-flex">
            {{ newPlugInCode.code }}
          </div>
          <div class="is-width-small has-text-small has-text-weight-bold has-margin-top">
            Validade de 15 minutos para ativação.
          </div>
          <div class="is-width-small has-text-small has-margin-top-large">
            Para utilizar este código, instale a aplicação UC Plugin no seu smartphone ou tablet, disponível em
            <a class="has-text-weight-bold" target="_blank" :href="ucPluginAppUrl">{{ ucPluginAppUrl }}</a
            >.
          </div>
          <div class="has-margin-top-large">
            <b-button size="is-small" outlined type="is-primary" rounded @click="newPlugInCode = null">Fechar</b-button>
          </div>
          <div class="has-margin-top-medium">
            <b-button type="is-text" size="is-small" outlined @click="getPlugInNewCode">Registar novo código</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'

export default {
  props: {
    meetingKey: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      newPlugInCode: null,
      ucPluginAppUrl: FwEnvConfig.ucpluginUrl,
    }
  },

  methods: {
    async getPlugInNewCode() {
      this.newPlugInCode = await ServiceMeetings.getPlugInNewCode(this.meetingKey)
    },
  },
}
</script>
