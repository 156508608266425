import ChatRoutes from '@/fw-modules/fw-core-vue/chats/routes'
import AuthRoutes from '@/fw-modules/fw-core-vue/id/routes'
import MeetingsRoutes from '@/fw-modules/fw-meetings-vue/routes'
import BucketsRoutes from '@/fw-modules/fw-core-vue/buckets/routes'
import GroupsRoutes from '@/fw-modules/fw-core-vue/groups/routes'
import TasksRoutes from '@/fw-modules/fw-core-vue/tasks/routes'
import NotificationsRoutes from '@/fw-modules/fw-core-vue/notifications/routes'

var routes = [].concat(
  NotificationsRoutes,
  ChatRoutes,
  AuthRoutes,
  MeetingsRoutes,
  BucketsRoutes,
  GroupsRoutes,
  TasksRoutes
)

export default routes
